import React from "react";
import {  useTranslation } from "react-i18next";
import DropdownControl from "../DropdownControl";
import { getDriverSelect2 } from "functions/Driver";


function ChooseDriverDropdown(props) {
    const { disabled,  currentDriverID,  onSelectDriverID } = props;

    const { t } = useTranslation();
    const [driverChooseData, setDriverChooseData] = React.useState([]);

    // get the data for the choice
    React.useEffect(() => {
        const getDriver = async () => {
            let data = await getDriverSelect2()
            setDriverChooseData(data);
        };
        getDriver();
    }, []);


    return (
        <DropdownControl
            disabled={disabled}
            defaultColor={"primary"}
            dropdownName={t("Driver")}
            useTranslate={false}
            arrayIDTextFormat={driverChooseData}
            valueID={currentDriverID}
            onSelectValueID={async (valueID) => {
                onSelectDriverID(valueID);
            }}
        />

    );
}

export default ChooseDriverDropdown;

