import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory } from "react-router-dom";
import { getRights, isLoggedIn } from "../../../functions/Auth";
import {
  filterDeliveryRecords,
  getAllDeliveryRecords,
  getAllDrivers,
  getDeliveryRecordsFast,
  getAllDeliveryOrders,
  getDeliveryRecordsByDateRangeAndDeliveryStatus,
} from "../../../functions/Delivery";
import { getAllCars } from "../../../functions/Car";
// core components
import DeliveryRecordHeader from "components/Headers/DeliveryRecordHeader.js";
import DeliveryRecordListView from "../deliveryRecord/DeliveryRecordListView";
import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { disable } from "workbox-navigation-preload";
import { isAccessible } from "functions/SystemUserAccess";
import { lockScrolling } from "functions/Utility";
import moment from "moment";


function DeliveryRecordScreen(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const today = new Date();
  const lastMonth = new Date(
    new Date(today.setDate(1)).setMonth(today.getMonth() - 1)
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);

  //  Data
  const [deliveryData, setDeliveryData] = React.useState([]);
  const [displayData, setDisplayData] = React.useState([]);

  // data control
  // const dataIntervalInteger = 10;
  // const [currentFilterStartDate, setCurrentFilterStartDate] = React.useState(new Date());
  // const [currentFilterEndDate, setCurrentFilterEndDate] = React.useState(new Date());
  const [useFilterStartDate, setUseFilterStartDate] = React.useState(true);
  const [useFilterEndDate, setUseFilterEndDate] = React.useState(true);
  // const [dataRangeStartDate, setDataRangeStartDate] = React.useState(moment(new Date()).add(dataIntervalInteger * -1, 'days').toDate()); // default 10 day
  // const [dataRangeEndDate, setDataRangeEndDate] = React.useState(moment(new Date()).add(dataIntervalInteger, 'days').toDate()); // default 10 day
  const [dataRangeStartDate, setDataRangeStartDate] = React.useState(new Date());
  const [dataRangeEndDate, setDataRangeEndDate] = React.useState(new Date());

  // GUI
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);



  const initDeliveryOrderRecord = async () => {
    setIsLoading(true);

    let delilveryRecordFromServer = [];
    if (useFilterStartDate && useFilterEndDate) {
      // get data from server using the date option
      if (dataRangeStartDate && dataRangeEndDate) {
        delilveryRecordFromServer = await getDeliveryRecordsByDateRangeAndDeliveryStatus(dataRangeStartDate, dataRangeEndDate);
      }
    } else {
      // force to get all data from server
      delilveryRecordFromServer = await getAllDeliveryOrders();
    }
    setDeliveryData(delilveryRecordFromServer);
    setIsLoading(false);
  };

  //   React.useEffect(() => {
  //  //   initDeliveryOrderRecord();
  //   }, [refreshTable, dataRangeStartDate, dataRangeEndDate, useFilterStartDate, useFilterEndDate]);



  React.useEffect(() => {
    if (refreshTable > 0) {
     initDeliveryOrderRecord();
    }
  }, [refreshTable]);

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}
      <ScreenPullToRefresh
        onRefresh={async () => {
          refreshTableData();
        }}
        disabled={pullToRefreshDisable}>
        <DeliveryRecordHeader
          name={t("Delivery Record")}
          icon="fas fa-home"
          filteredData={displayData}
          goBackLastPage={() => { history.goBack() }}
        />
      </ScreenPullToRefresh>
      <Container style={{ marginTop: "-1rem" }} fluid>
        <Row >
          <DeliveryRecordListView
            initData={deliveryData}
            dataRangeStartDate={dataRangeStartDate}
            dataRangeEndDate={dataRangeEndDate}
            returnDeliveryRecordDatatForStat={(dataForStat) => { setDisplayData(dataForStat); }}
            refreshTableData={(dataRangeStartDateFromList, dataRangeEndDateFromList, useFilterStartDateFromList, useFilterEndDateFromList) => {
              if (moment(dataRangeStartDateFromList).diff(moment(dataRangeStartDate), 'seconds') == 0 &&
                moment(dataRangeEndDateFromList).diff(moment(dataRangeEndDate), 'seconds') == 0 &&
                useFilterStartDateFromList == useFilterStartDate &&
                useFilterEndDateFromList == useFilterEndDate
              ) {
                // values are the same 
                // prevent multiple fetch from the server
              } else {
                setUseFilterStartDate(useFilterStartDateFromList);
                setUseFilterEndDate(useFilterEndDateFromList);
                setDataRangeStartDate(dataRangeStartDateFromList);
                setDataRangeEndDate(dataRangeEndDateFromList);
                refreshTableData();
              }
            }}
            forceRefreshTableData={() => {
              refreshTableData();
            }}
            disablePullToRefresh={(disableTrueFalse) => {
              setPullToRefreshDisable(disableTrueFalse);
              lockScrolling(disableTrueFalse);
            }}
          />
        </Row>
      </Container>
    </>
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default DeliveryRecordScreen;
