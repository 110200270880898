import React from "react";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import TranslateItem from "./TranslateItem"


/**
 * @param {{type: 'deleteConfirmation/delete',elementID/dataToPass}} props
 * @returns {Swal}
 */


function Notifications(props) {
  const MySwal = withReactContent(Swal)

  if (props != undefined) {
    if (props.type == "deleteConfirmation") {
      const titleToShow = props.title ? props.title : "Are you Sure?";
      const messageToShow = props.message ? props.message : "Item will be deleted";
      MySwal.fire({
        icon: "question",
        title: <TranslateItem text={titleToShow} />,
        html: <TranslateItem text={messageToShow} />,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((value) => {
        if (value.isConfirmed) {
          props.callback(props.elementID);
        }
      });
    }
    if (props.type == "cancelConfirmation") {
      const titleToShow = props.title ? props.title : "Are you Sure?";
      const messageToShow = props.text ? props.text : "Item will be cancelled";
      MySwal.fire({
        icon: "question",
        title: <TranslateItem text={titleToShow} />,
        html: <TranslateItem text={messageToShow} />,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((value) => {
        if (value.isConfirmed) {
          props.callback(props.elementID);
        }
      });
    }

    if (props.type == "cloneConfirmation") {
      const titleToShow = props.title ? props.title : "Are you Sure?";
      const messageToShow = props.text ? props.text : "Ready to clone";
      MySwal.fire({
        icon: "question",
        title: <TranslateItem text={titleToShow} />,
        html: <TranslateItem text={messageToShow} />,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: true,
        allowEscapeKey: true,
        backdrop: true
      }).then((value) => {
        if (value.isConfirmed) {
          props.callback(props.dataToPass);
        }
      });
    }

    if (props.type == "cloneInvoiceConfirmation") {
      const titleToShow = props.title ? props.title : "Are you Sure?";
      const messageToShow = props.text ? props.text : "Ready to clone an invoice.";
      MySwal.fire({
        icon: "question",
        title: <TranslateItem text={titleToShow} />,
        html: <TranslateItem text={messageToShow} />,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: true,
        allowEscapeKey: true,
        backdrop: true
      }).then((value) => {
        if (value.isConfirmed) {
          props.callback(props.dataToPass);
        }
      });
    }

    if (props.type == "cloneDeliveryRecordConfirmation") {
      const titleToShow = props.title ? props.title : "Are you Sure?";
      const messageToShow = props.text ? props.text : "Ready to clone a delivery record";
      MySwal.fire({
        icon: "question",
        title: <TranslateItem text={titleToShow} />,
        html: <TranslateItem text={messageToShow} />,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: true,
        allowEscapeKey: true,
        backdrop: true
      }).then((value) => {
        if (value.isConfirmed) {
          props.callback(props.dataToPass);
        }
      });
    }


    

    if (props.type == "generalConfirmation") {
      const titleToShow = props.title ? props.title : "OK?";
      const messageToShow = props.message ? props.message : "OK?";
      MySwal.fire({
        icon: "question",
        title: <TranslateItem text={titleToShow} />,
        html: <TranslateItem text={messageToShow} />,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: true,
        allowEscapeKey: true,
        backdrop: true
      }).then((value) => {
        if (value.isConfirmed) {
          props.callback();
        } else {
          props.cancel();
        }
      });
    }

    if (props.type == "generalConfirmationWithID") {
      const titleToShow = props.title ? props.title : "OK?";
      const messageToShow = props.message ? props.message : "OK?";
      MySwal.fire({
        icon: "question",
        title: <TranslateItem text={titleToShow} />,
        html: <TranslateItem text={messageToShow} />,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: true,
        allowEscapeKey: true,
        backdrop: true
      }).then((value) => {
        if (value.isConfirmed) {
          props.callback(props.elementID);
        } else {
          props.cancel();
        }
      });
    }
    if (props.type == "trafficControl") {
      const messageToShow = props.message ? props.message : "Traffic control";
      MySwal.fire({
        icon: "info",
        title: <TranslateItem text={messageToShow} />,
        timer: 2000,
      });
    }
    if (props.type == "dataCheck") {
      const messageToShow = props.message ? props.message : "Cannot perform the action!";
      MySwal.fire({
        icon: "error",
        title: <TranslateItem text={messageToShow} />,
        timer: 2000,
      });
    }

    if (props.type == "generalNotice") {
      const messageToShow = props.message ? props.message : "Notice!";
      MySwal.fire({
        icon: "success",
        title: <TranslateItem text={messageToShow} />,
        timer: 2000,
      });
    }

    if (props.type == "advice") {
      const messageToShow = props.message ? props.message : "Something happened!";
      MySwal.fire({
        icon: "error",
        title: <TranslateItem text={messageToShow} />,
        timer: 2000,
      });
    }

    if (props.type == "store") {
      const messageToShow = props.message ? props.message : "Data Saved Successfuly!";
      MySwal.fire({
        icon: "success",
        title: <TranslateItem text={messageToShow} />,
        timer: 2000,
      });
    }
    if (props.type == "update") {
      const messageToShow = props.message ? props.message : "Data Updated Successfuly!";
      MySwal.fire({
        icon: "success",
        title: <TranslateItem text={messageToShow} />,
        timer: 2000,
      });
    }
    if (props.type == "delete") {
      const messageToShow = props.message ? props.message : "Error!";
      if (props.status === "error") {
        MySwal.fire({
          icon: "error",
          title: <TranslateItem text={messageToShow} />,
          // timer: 2000,
        });
      } else {
        const messageToShow = props.message ? props.message : "Deleted Successfuly!";
        MySwal.fire({
          icon: "success",
          title: <TranslateItem text={messageToShow} />,
          // timer: 2000,
        });

      }

    }
    if (props.type == "error") {
      let code = "[" + (Math.random() + 1).toString(36).substring(7) + "]";
      let title = props.title ? props.title : "Oops...";
      let message = props.message
        ? props.message
        : "Oops... Server Problem, please try again later. Error Code";

      MySwal.fire({
        icon: "error",
        title: <TranslateItem text={title} />,
        html: <TranslateItem text={message} />,
        // timer: 2000,
      });
    }
  } else {
    MySwal.fire({
      icon: "error",
      title: <TranslateItem text={"Oops..."} />,
      html: <TranslateItem text={"Undefined Condition Happening, Please Ask Our Tech Team!"} /> ,
    });
  }
}

export default Notifications;
