import React from 'react';
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router, Redirect, Route, Switch, } from "react-router-dom";

import DeliveryRecord from "views/pages/deliveryRecord/DeliveryRecordScreen.js";
import Car from "views/pages/car/CarScreen.js";
import Invoice from "views/pages/invoice/InvoiceScreen.js";
import LocalQuotation from "views/pages/localQuotation/localQuotationScreen.js";
import Calendar from "views/pages/Calendar.js";
import Payment from "views/pages/payment/PaymentScreen";
import Customer from "views/pages/customer/CustomerScreen.js";
import Driver from "views/pages/driver/DriverScreen.js";
import User from "views/pages/user/UserScreen";
import Good from "views/pages/good/GoodScreen.js";
import Address from "views/pages/address/AddressScreen.js";
import Store from "views/pages/store/StoreScreen.js";
import SalesAccounting from "views/pages/salesAccounting/SalesAccountingScreen.js";
import SignIn from "views/Index.js";
import SystemAccess from "views/pages/systemAccess/SystemAccessScreen";
import UsesrJob from "views/pages/userJob/UserJobScreen";
import MembershipType from "views/pages/membership/MembershipTypeScreen";
import DeliveryTeam from "views/pages/deliveryTeam/DeliveryTeamScreen";



import AppPage from './AppPage';

// import './App.css'
import { configLocalUserList, isLoggedIn } from "./functions/Auth";
import ReportResourceScreen from 'views/pages/reportResource/reportResourceScreen';
// import ErrorScreen from "./screens/ErrorScreen";


function AppRounting() {

    //  Rendering
    return (
        <Switch>
            <Route path={AppPage.RouteListObj.invoice.path}>
                <Invoice accessID={AppPage.RouteListObj.invoice.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.localQuotation.path}>
                <LocalQuotation accessID={AppPage.RouteListObj.localQuotation.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.payment.path}>
                <Payment accessID={AppPage.RouteListObj.payment.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.salesAccounting.path}>
                <SalesAccounting accessID={AppPage.RouteListObj.salesAccounting.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.calendar.path}>
                <Calendar accessID={AppPage.RouteListObj.calendar.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.deliveryRecord.path}>
                <DeliveryRecord accessID={AppPage.RouteListObj.deliveryRecord.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.address.path}>
                <Address accessID={AppPage.RouteListObj.address.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.customer.path}>
                <Customer accessID={AppPage.RouteListObj.customer.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.membershipType.path}>
                <MembershipType accessID={AppPage.RouteListObj.membershipType.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.car.path}>
                <Car accessID={AppPage.RouteListObj.car.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.driver.path}>
                <Driver accessID={AppPage.RouteListObj.driver.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.deliveryTeam.path}>
                <DeliveryTeam accessID={AppPage.RouteListObj.deliveryTeam.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.good.path}>
                <Good accessID={AppPage.RouteListObj.good.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.store.path}>
                <Store accessID={AppPage.RouteListObj.store.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.user.path}>
                <User accessID={AppPage.RouteListObj.user.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.systemAccess.path}>
                <SystemAccess accessID={AppPage.RouteListObj.systemAccess.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.userJob.path}>
                <UsesrJob accessID={AppPage.RouteListObj.userJob.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.reportResource.path}>
                <ReportResourceScreen accessID={AppPage.RouteListObj.reportResource.accessID} />
            </Route>
            <Route path={AppPage.RouteListObj.signIn.path}>
                <SignIn />
            </Route>
            <Route path={AppPage.RouteListObj.home.path}>
                {
                    isLoggedIn() ?
                        <Calendar /> :
                        <SignIn />
                }
            </Route>
            <Route path={AppPage.RouteListObj.root.path}>
                {
                    isLoggedIn() ?
                        <Calendar /> :
                        <SignIn />
                }
            </Route>
            {/* <Redirect to={AppPage.address.path}/> doesnt work coz it doesnt refresh the page */}
        </Switch>
    )
}

export default AppRounting;

