import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
    Label
} from "reactstrap";
import { useTranslation, Trans } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { combineAddress } from "../../../functions/Delivery.js"
import DeliveryRecordNewGoogleMap from "./DeliveryRecordNewGoogleMap.js";
import Autocomplete from "react-google-autocomplete";
import { usePlacesWidget } from "react-google-autocomplete";


// props
// submitAddressData
// deliveryAddressToEdit : optional
// fieldMapSameRow ? same row : two row
// props.readOnly
// props.showMap
// props.readOnlyFullAddress

// props.createMode
function DeliveryAddressInputFieldWithMap(props) {
    const { t } = useTranslation();

    const readOnly = props.readOnly;


    const [createMode, setCreateMode] = React.useState(props.createMode);

    const [useGoogleAutoComplete, setUseGoogleAutoComplete] = React.useState(true);
    const [useDirectInput, setUseDirectInput] = React.useState(false);

    const [showMap, setShowMap] = React.useState(props.showMap);

    const [addressDataChanged, setAddressDataChanged] = React.useState(false);

    const [addressData, setAddressData] = React.useState({
        addressDetailUnitFloor: "",
        addressDetailBuilding: "",
        addressDetailStreet: "",
        addressDetailDistrict: "",
        addressDetailMainDistrict: "",
        addressRemark: "",
        addressGPSLatitude: 0,
        addressGPSLongtitude: 0
    });

    const [fullAddressCombined, setFullAddressCombined] = React.useState("");

    const { addressDetailUnitFloor, addressDetailBuilding, addressDetailStreet, addressDetailDistrict, addressDetailMainDistrict, addressRemark, addressGPSLatitude, addressGPSLongtitude } = addressData;

    const onLocationSelected = async (lat, long) => {
        if (lat === undefined) {
            lat = 0;
        }
        if (long === undefined) {
            long = 0;
        }
        setAddressData({ ...addressData, ["addressGPSLatitude"]: lat, ["addressGPSLongtitude"]: long });
        setAddressDataChanged(true);
    };

    React.useEffect(() => {
        setFullAddressCombined(combineAddress(addressData));
        // submit data to parent
        if (addressDataChanged) {
            props.submitAddressData(addressData);
            setAddressDataChanged(false);
        }
    }, [addressData]);


    React.useEffect(() => {
        if (props.deliveryAddressToEdit && Object.values(props.deliveryAddressToEdit).length >= 1) {
            setAddressDataChanged(false);
            setAddressData(props.deliveryAddressToEdit);

            // also activate the input button
            setUseGoogleAutoComplete(false);
            setUseDirectInput(true);

        } else {
            clearInput();
        }
    }, [props.deliveryAddressToEdit]);

    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        onPlaceSelected: (place) => {

            let addressDetailUnitFloor = "";
            let addressDetailBuilding = "";
            let addressDetailStreet = "";
            let addressDetailDistrict = "";
            let addressDetailMainDistrict = "";

            place.address_components.forEach((item) => {
                if (item.types.includes("premise")) {
                    addressDetailUnitFloor = item.long_name;
                } else if (item.types.includes("street_number")) {
                    addressDetailBuilding = item.long_name;
                } else if (item.types.includes("route")) {
                    addressDetailStreet = item.long_name;
                } else if (item.types.includes("neighborhood")) {
                    addressDetailDistrict = item.long_name;
                } else if (item.types.includes("administrative_area_level_1")) {
                    addressDetailMainDistrict = item.long_name;
                }
            })

            if (addressDetailUnitFloor === "") addressDetailUnitFloor = t("TBC");
            if (addressDetailBuilding === "") addressDetailBuilding = t("TBC");
            if (addressDetailStreet === "") addressDetailStreet = t("TBC");
            if (addressDetailDistrict === "") addressDetailDistrict = t("TBC");
            if (addressDetailMainDistrict === "") addressDetailMainDistrict = t("TBC");

            // fill up the address data
            setAddressData({
                addressDetailUnitFloor: addressDetailUnitFloor,
                addressDetailBuilding: addressDetailBuilding,
                addressDetailStreet: addressDetailStreet,
                addressDetailDistrict: addressDetailDistrict,
                addressDetailMainDistrict: addressDetailMainDistrict,
                addressRemark: "",
                addressGPSLatitude: 0,
                addressGPSLongtitude: 0
            });

            // make sure the data is submtted to the parent
            setAddressDataChanged(true);

            // check to direct input
            setUseDirectInput(true);
            setUseGoogleAutoComplete(false);
        },
        options: {
            types: [],
            componentRestrictions: { country: "HK" }
        },
        defaultValue: "",
        libraries: ["places"],
        language: "zh-HK"
    })

    const clearInput = () => {

        setUseGoogleAutoComplete(true);
        setUseDirectInput(false);

        setAddressDataChanged(false);
        setAddressData({
            addressDetailUnitFloor: "",
            addressDetailBuilding: "",
            addressDetailStreet: "",
            addressDetailDistrict: "",
            addressDetailMainDistrict: "",
            addressRemark: "",
            addressGPSLatitude: 0,
            addressGPSLongtitude: 0
        });
    }


    return (
        <>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {createMode &&
                        <>
                            <Row>
                                <Col>
                                    <Button color={useGoogleAutoComplete ? "success" : "danger"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setUseGoogleAutoComplete(true);
                                            setUseDirectInput(false);
                                        }}
                                    >
                                        {useGoogleAutoComplete ? <i className="fas fa-check" /> : <i className="fas fa-times" />} {" "}<Trans>Use Google Map</Trans>
                                    </Button>
                                </Col>
                            </Row>
                            {useGoogleAutoComplete &&
                                <div >
                                    <Row className="mt-2">
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Label for={""} className="form-control-label">
                                                {t("Search in Google Map")}
                                            </Label>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <input
                                                className="pl-2 pr-2 form-control"
                                                placeholder={t("Write an address")}
                                                ref={ref}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row className="mt-4">
                                <Col>
                                    <Button color={useDirectInput ? "success" : "danger"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setUseGoogleAutoComplete(false);
                                            setUseDirectInput(true);
                                        }}
                                    >

                                        {useDirectInput ? <i className="fas fa-check" /> : <i className="fas fa-times" />} {" "}<Trans>Input an address</Trans>
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    }
                    {(useDirectInput || !createMode) &&
                        <div>
                            <Row className="mt-2">
                                <Col md="12">
                                    <InputTypeTextForm
                                        label={`${t("Full address")}`}
                                        labelFor="fullAddress"
                                        inputName="fullAddress"
                                        inputId="fullAddress"
                                        inputPlaceholder={t("Full address")}
                                        // type="textarea"
                                        value={fullAddressCombined}
                                        handleChange={(e) => {
                                            // setAddressData({ ...addressData, ["addressDetailStreet"]: e.target.value });
                                            // setAddressDataChanged(true);
                                        }}
                                        readOnly={readOnly}
                                        required={false}
                                        showCopyButton={true}
                                    />


                                </Col>
                            </Row>
                            <Row >
                                <Col md="6">
                                    <InputTypeTextForm
                                        label={`${t("Main District")}${"("}${t("Required")}${")"}`}
                                        labelFor="addressDetailMainDistrict"
                                        inputName="addressDetailMainDistrict"
                                        inputId="addressDetailMainDistrict"
                                        inputPlaceholder={t("Main District")}
                                        value={addressDetailMainDistrict}
                                        handleChange={(e) => {
                                            setAddressData({ ...addressData, ["addressDetailMainDistrict"]: e.target.value });
                                            setAddressDataChanged(true);
                                        }}
                                        readOnly={readOnly}
                                        required={true}
                                    />

                                </Col>
                                <Col md="6">
                                    <InputTypeTextForm
                                        label={`${t("District")}${"("}${t("Required")}${")"}`}
                                        labelFor="addressDetailDistrict"
                                        inputName="addressDetailDistrict"
                                        inputId="addressDetailDistrict"
                                        inputPlaceholder={t("District")}
                                        value={addressDetailDistrict}
                                        handleChange={(e) => {
                                            setAddressData({ ...addressData, ["addressDetailDistrict"]: e.target.value });
                                            setAddressDataChanged(true);
                                        }}
                                        readOnly={readOnly}
                                        required={true}
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <InputTypeTextForm
                                        label={`${t("Street")}${"("}${t("Required")}${")"}`}
                                        labelFor="addressDetailStreet"
                                        inputName="addressDetailStreet"
                                        inputId="addressDetailStreet"
                                        inputPlaceholder={t("Street")}
                                        // type="textarea"
                                        value={addressDetailStreet}
                                        handleChange={(e) => {
                                            setAddressData({ ...addressData, ["addressDetailStreet"]: e.target.value });
                                            setAddressDataChanged(true);
                                        }}
                                        readOnly={readOnly}
                                        required={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <InputTypeTextForm
                                        label={`${t("Building")}${"("}${t("Required")}${")"}`}
                                        labelFor="addressDetailBuilding"
                                        inputName="addressDetailBuilding"
                                        inputId="addressDetailBuilding"
                                        inputPlaceholder={t("Building")}
                                        value={addressDetailBuilding}
                                        handleChange={(e) => {
                                            setAddressData({ ...addressData, ["addressDetailBuilding"]: e.target.value });
                                            setAddressDataChanged(true);
                                        }}
                                        readOnly={readOnly}
                                        required={true}
                                    />
                                </Col>
                                <Col md="6">
                                    <InputTypeTextForm
                                        label={`${t("Unit Floor")}${"("}${t("Required")}${")"}`}
                                        labelFor="addressDetailUnitFloor"
                                        inputName="addressDetailUnitFloor"
                                        inputId="addressDetailUnitFloor"
                                        inputPlaceholder={t("Unit Floor")}
                                        value={addressDetailUnitFloor}
                                        handleChange={(e) => {
                                            setAddressData({ ...addressData, ["addressDetailUnitFloor"]: e.target.value });
                                            setAddressDataChanged(true);
                                        }}
                                        readOnly={readOnly}
                                        required={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <InputTypeTextForm
                                        label={t("Remark")}
                                        labelFor="addressRemark"
                                        inputName="addressRemark"
                                        inputId="addressRemark"
                                        inputPlaceholder={t("Remark")}
                                        type="textarea"
                                        value={addressRemark}
                                        handleChange={(e) => {
                                            setAddressData({ ...addressData, ["addressRemark"]: e.target.value });
                                            setAddressDataChanged(true);
                                        }}
                                        readOnly={readOnly}
                                        required={false}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row className="mt-2">
                        <Col>
                            {showMap &&
                                <DeliveryRecordNewGoogleMap
                                    addressData={addressData}
                                    onLocationSelected={(lat, long) => {
                                        onLocationSelected(lat, long);
                                    }}
                                />
                            }

                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    );
}

export default DeliveryAddressInputFieldWithMap;
