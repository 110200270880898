import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { paginationTable } from "functions/PaginationTable";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
// import { getCustomerDatatable, deleteCustomerByID } from "../../../functions/Customer";
import { getDriverDatatable, deleteDriverByID } from "functions/Driver";
import DriverModalCreate from "./DriverModalCreate";
import Notifications from "../components/Notifications";
import DriverModalEdit from "./DriverModalEdit";
import { joinArrayToString, addPhoneLink } from "functions/Utility.js";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { isAccessible } from "functions/SystemUserAccess";
import { lockScrolling } from "functions/Utility";


function DriverScreen(props) {
  const { t } = useTranslation();
  const pagination = paginationTable();

  const { SearchBar } = Search;
  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [driverData, setDriverData] = React.useState([]);
  const [selectedDriverData, setSelectedDriverData] = React.useState([]);

  // GUI
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

  // Modal
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = React.useState(false);


  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    setPullToRefreshDisable(modalIsOpen || modalEditIsOpen);
    lockScrolling(modalIsOpen || modalEditIsOpen);
  }, [modalIsOpen, modalEditIsOpen]);


  // Call API Customer
  React.useEffect(() => {
    getDriverTable();
  }, [refreshTable]);


  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  const getDriverTable = async () => {
    setIsLoading(true);
    let driverTable = await getDriverDatatable();
    setDriverData(driverTable);
    setIsLoading(false);
  };

  // Delete Confirmation
  async function deleteConfirmation(id) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: id,
      callback: deleteDriver,
    };
    Notifications(alert);
  }

  // Delete API Call
  async function deleteDriver(id) {
    let deleteResponse = await deleteDriverByID(id);
    deleteResponse.code == 200 ? getDriverTable() : true;
  }

  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}

      <ScreenPullToRefresh
        onRefresh={async () => {
          await getDriverTable();
        }}
        disabled={pullToRefreshDisable}>
        <SimpleHeader parentName={t("Driver")} icon="fas fa-home" />

      </ScreenPullToRefresh>
      <Container style={{ marginTop: "-4rem" }} fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <ToolkitProvider
                  data={driverData}
                  keyField="driverID"
                  columns={
                    isMobile
                      ? [
                        { dataField: "driverID", text: "", hidden: true },
                        { dataField: "driverRemark", text: "", hidden: true },
                        {
                          dataField: "systemUserJoin", text: "", hidden: true,
                          formatter: (cell, row, rowIndex, extraData) => {
                            return `
                            ${row.SystemUser.email + row.SystemUser.nickName + row.SystemUser.name + row.SystemUser.chineseName + row.SystemUser.phoneNumber + row.SystemUser.HKID}`
                          }
                        },
                        {
                          dataField: "",
                          text: t("Name"),
                          formatter: (cell, row, rowIndex, extraData) => {
                            return (
                              <>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSelectedDriverData(row);
                                    setModalEditIsOpen(true);
                                  }}>
                                  <div className="tableRowTitle">
                                    {row.SystemUser.nickName}
                                  </div>
                                  <div className="tableRowRemark">
                                    {row.driverRemark}
                                  </div>
                                </div>
                                <div className="tableRowSubTitle">
                                  {row.SystemUser.name}: {addPhoneLink(row.SystemUser.phoneNumber)}
                                </div>
                              </>
                            );
                          },
                        },
                        {
                          isDummyField: true,
                          dataField: "Action",
                          text: "",
                          formatter: (cellContent, row) => {
                            return (
                              <>
                               
                               
                               <Badge color={"danger"} className="badge-lg text-lg badge-circle">
                                  <i
                                    className="fas fa-trash"
                                    onClick={() =>
                                      deleteConfirmation(row.driverID)
                                    }
                                  />
                                </Badge>
                              </>
                            );
                          },
                        },
                      ]
                      : [
                        {
                          dataField: "SystemUser.name",
                          text: (
                            <span className="tableTitle">{t("Name")}</span>
                          ),
                          sort: true,
                        },
                        {
                          dataField: "SystemUser.nickName",
                          text: (
                            <span className="tableTitle">
                              {t("Nickname")}
                            </span>
                          ),
                          sort: true,
                        },
                        {
                          dataField: "SystemUser.phoneNumber",
                          text: (
                            <span className="tableTitle">
                              {t("Contact Number")}
                            </span>
                          ),
                          sort: true,
                        },
                        {
                          dataField: "driverRemark",
                          text: (
                            <span className="tableTitle">{t("Remark")}</span>
                          ),
                          // sort: true
                        },
                        {
                          isDummyField: true,
                          dataField: "Action",
                          text: (
                            <span className="tableTitle">{t("Action")}</span>
                          ),
                          formatter: (cellContent, row) => {
                            return (
                              <>
                                <a
                                  className="table-action"
                                  id="tooltip209424781"
                                  onClick={() => {
                                    setSelectedDriverData(row);
                                    setModalEditIsOpen(true);
                                  }}
                                >
                                  <i className="fas fa-edit" />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target="tooltip209424781"
                                >
                                  {t("Edit")}
                                </UncontrolledTooltip>
                                <a
                                  className="table-action table-action-delete"
                                  id="tooltip12475020"
                                  onClick={() =>
                                    deleteConfirmation(row.driverID)
                                  }
                                >
                                  <i
                                    className="fas fa-trash"
                                    data-id={cellContent}
                                  />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target="tooltip12475020"
                                >
                                  {t("Delete")}
                                </UncontrolledTooltip>
                              </>
                            );
                          },
                        },
                      ]
                  }
                  search={{
                    searchFormatted: true
                  }}
                >
                  {(props) => (
                    <>
                      <Row className="pb-2">
                        <Col xs={7} sm={7} md={7} lg={7}>
                          <SearchBar
                            className=""
                            placeholder={t("Search")}
                            {...props.searchProps}
                          />
                        </Col>
                        <Col
                          className={"text-right"}
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                        >
                          <Button
                            color="primary"
                            id="tooltip443412080"
                            onClick={() => setModalIsOpen(!modalIsOpen)}
                            size="md"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">{t("Add")}</span>
                          </Button>
                          <UncontrolledTooltip
                            delay={1}
                            target="tooltip443412080"
                          >
                            {t("Create")}
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                      <div className="table-responsive table-sm">
                        <BootstrapTable
                          headerClasses={isMobile ? "tableHiddenHeader" : ""} // only hide column when mobile
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    </>
                  )}
                </ToolkitProvider>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>

      </Container>
      <DriverModalCreate
        show={modalIsOpen}
        onHide={(e) => {
          setModalIsOpen(false);
        }}
        title={t("Driver Information")}
        button={t("Save")}
        refreshTable={refreshTableData}
      />
      <DriverModalEdit
        show={modalEditIsOpen}
        onHide={() => setModalEditIsOpen(false)}
        title={t("Driver Information")}
        button={t("Update")}
        driverData={selectedDriverData}
        refreshTable={refreshTableData}
      />
    </>
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default DriverScreen;
