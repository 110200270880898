import { getPageMargins } from "functions/Print80mm";
import { Trans, useTranslation } from "react-i18next";
import React, { useCallback, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Row,
    Col,
    Label,
    UncontrolledTooltip,
    Badge,
    Collapse,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import PrintBasicText from "../components/PrintBasicText";
import PrintBreakLine from "../components/PrintBreakLine";
import { wrapText } from "functions/Print80mm";
import { textLength } from "functions/Print80mm";


function Print80mmModal(props) {
    const { t, i18n } = useTranslation();

    const { show, onHide, textToPrint, headerText } = props;

    const componentRefCustom = useRef(null);

    const reactToPrintContent = () => {
        return componentRefCustom.current;
    };

    const printFn = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "80mm note to print",
        // pageStyle: `@media print {
        //     @page {
        //       size: 80mm 10mm;
        //     }
        //   }`
    });

    return (
        <Modal
            isOpen={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={onHide}
            autoFocus={true}
            backdrop={'static'}
            trapFocus={false}
            keyboard={false}
        >
            <ModalHeader className="" toggle={onHide} >
                    <div className="tableRowTitleDouble">
                        <span>{t('Print')}</span>
                    </div>


            </ModalHeader>
            <ModalBody>
                <div ref={componentRefCustom}>
                    <style type="text/css" media="print">
                        {getPageMargins()}
                    </style>
                    <div>
                        <h1>{headerText}</h1>
                    </div>
                    <PrintBasicText
                        textToShow={textToPrint}
                        fontSizeInRem={1.5}
                    />

                </div>
            </ModalBody>

            <ModalFooter>
                <div className="d-flex">
                    <Button
                        color="success"
                        onClick={() => { printFn(); }}>
                        <span className="btn-inner--text">{t("Print")}</span>
                    </Button>
                    <Button
                        color="danger"
                        onClick={() => { onHide(); }}>

                        <span className="btn-inner--text">{t("Cancel")}</span>
                    </Button>
                </div>

            </ModalFooter>


        </Modal>
    );
}

export default Print80mmModal;
