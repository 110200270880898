import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Redirect } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { isLoggedIn } from "functions/Auth.js";
import { getDeliveryRecordsById, updateDeliveryFull } from "functions/Delivery";
import { getStoreSelect2 } from "functions/Store";
import {
  getCustomerByID,
  getPhoneType,
} from "functions/Customer";
import InvoiceAddProductModal from "./InvoiceAddProductModal";
import Notifications from "../components/Notifications.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import moment from "moment";
import { getPaymentByInvoiceID } from "functions/Payment.js";
import QRCode from "react-qr-code";
import md5 from "md5";
import {
  getInvoiceStatusArray,
  updateInvoiceFull,
  updateObjectInvoiceSubitems,
  deleteInvoiceByID
} from "functions/Invoice.js";
import { uuid4 } from "@sentry/utils";
import InvoiceEditSubitemModal from "./InvoiceEditSubitemModal.js";
import InvoiceAddPaymentModal from "./InvoiceAddPaymentModal.js";
import { deletePayment } from "functions/Payment.js";
import DeliveryRecordEditModal from "../deliveryRecord/DeliveryRecordEditModal";
import DeliveryRecordCreateModal from "../deliveryRecord/DeliveryRecordCreateModal";

import { getDeliveryRecordsByInvoice } from "functions/Delivery.js";
import { deleteDeliveryRecord, combineAddress } from "functions/Delivery.js";
import { isMobile } from "react-device-detect";
import DeliveryBadge from "../components/DeliveryBadge";
import InvoiceSubitemTable from "../components/InvoiceSubitemTable";
import CustomerDisplayCard from "../customer/CustomerDisplayCard";
import InvoiceBadge from "../components/InvoiceBadge";
import DeliveryCountBadge from "../components/DeliveryCountBadge";
import PaymentCountBadge from "../components/PaymentCountBadge.js";
import DropdownControl from "../components/DropdownControl";
import { getSingleInvoice } from "functions/Invoice";
import { isAccessible } from "functions/SystemUserAccess";
import { getDateString } from "functions/Utility";
import PaymentEditModal from "../payment/PaymentEditModal";
import SpinnerOverlay from "../components/SpinnerOverlay";
import CustomerSelectInput from "../components/CustomerSelectInput";
import { element, func } from "prop-types";
import InvoiceMessageModal from "./InvoiceMessageModal";
import PaymentMessageModal from "../payment/paymentMessageModal";
import DeliveryRecordMessageModal from "../deliveryRecord/DeliveryRecordMessageModal";
import InvoiceCreateModal from "./InvoiceCreateModal";
import QuotationMessageModal from "../quotation/QuotationMessageModal";
import { openPDFLink } from "functions/Utility";
import { createNoSubstitutionTemplateLiteral } from "typescript";
import SubmitButtonInForm from "../components/SubmitButtonInForm";
import { de } from "date-fns/locale";
import { updateDeliveryRecordSubitem } from "functions/Delivery";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess";
import { swapObjectInArray } from "functions/Utility.js";
import PaymentBadge from "../components/PaymentBadge";
import { getCorrespondingPaidAmountForSingleInvoice } from "functions/Payment";
import { InvoiceBasketListGlobal } from "context/InvoiceBasketContext";

const isDriver = localStorage.getItem("rights") === "driver";

function InvoiceEditModal(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const activeLang = i18n.language;

  // Context
  const { isThisInvoiceExist, addSingleInvoiceToGlobalList, deleteSingleInvoiceToGlobalList } = InvoiceBasketListGlobal();

  // Traffic Control
  const [trafficControlDeliveryIDToProhibit, setTrafficControlDeliveryIDToProhibit] = React.useState("");

  React.useEffect(() => {
    setTrafficControlDeliveryIDToProhibit(props.trafficControlDeliveryIDToProhibit);
  }, [props.trafficControlDeliveryIDToProhibit]);

  const [invoiceData, setInvoiceData] = React.useState([]);

  // Initialize Data
  const [storeDataArray, setStoreDataArray] = React.useState([]);
  const [customerSelectedData, setCustomerSelectedData] = React.useState([]);

  const [invoiceStatusArray, setInvoiceStatusArray] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);

  //  const [invoicesSubitemsTable, setInvoicesSubitemsTable] = React.useState([]);
  // const [deliveryRecordsTable, setDeliveryRecords] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = React.useState(false);
  const [modalAddPaymentIsOpen, setModalAddPaymentIsOpen] = React.useState(false);
  const [modalEditPaymentIsOpen, setModalEditPaymentIsOpen] = React.useState(false);
  const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
  const [modalPaymentMessageIsOpen, setModalPaymentMessageIsOpen] = React.useState(false);
  const [modalDeliveryRecordMessageIsOpen, setModalDeliveryRecordMessageIsOpen] = React.useState(false);
  const [modalDeliveryRecordEditIsOpen, setModalDeliveryRecordEditIsOpen] = React.useState(false);

  const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);
  const [modalQuotationMessageIsOpen, setModalQuotationMessageIsOpen] = React.useState(false);


  const [selectedPaymentData, setSelectedPaymentData] = React.useState(null);

  const [selectedInvoicesSubitemData, setSelectedInvoicesSubitemData] = React.useState({});

  const [deleteSelectedInvoicesSubitems, setDeleteSelectedInvoicesSubitems] = React.useState("");
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [paymentList, setPaymentList] = React.useState([]);

  const [showInvoiceDetail, setShowInvoiceDetail] = React.useState(isMobile ? false : true);
  const [showDO, setShowDO] = React.useState(isMobile ? false : true);
  const [showPayment, setShowPayment] = React.useState(isMobile ? false : true);
  const [showAll, setShowAll] = React.useState(isMobile ? false : true);


  // Control
  const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);
  const [invoiceDetailEdited, setInvoiceDetailEdited] = React.useState(false);

  const [selectedDeliveryRecodData, setSelectedDeliveryRecodData] = React.useState({});

  const [deliveryRecordSelectedDataForClone, setDeliveryRecordSelectedDataForClone] = React.useState({});

  const [modalDeliveryRecordAddIsOpen, setModalDeliveryRecordAddIsOpen] = React.useState(false);

  const [actionCopyDone, setActionCopyDone] = React.useState(false);

  // POST State
  const [invoiceID, setInvoiceID] = React.useState("");
  const [invoiceRecordDateTime, setInvoiceRecordDateTime] = React.useState(moment());
  const [invoiceNetPrice, setInvoiceNetPrice] = React.useState(0);
  const [invoiceStatus, setInvoiceStatus] = React.useState("");
  const [invoiceRemark, setInvoiceRemark] = React.useState("");
  const [invoiceMessage, setinvoiceMessage] = React.useState("");
  const [invoiceCustomerID, setInvoiceCustomerID] = React.useState("");
  const [storeIDSelected, setStoreIDSelected] = React.useState("");

  // 20240110
  // New method to handle the invoice subitem
  // In local environment
  // We must have a localSubitemID
  const [invoicesSubitemList, setInvoicesSubitemList] = React.useState([]);

  const [deliveryRecordList, setDeliveryRecordList] = React.useState([]);

  React.useEffect(() => {
    setShowDO(showAll);
    setShowInvoiceDetail(showAll);
    setShowPayment(showAll);
  }, [showAll]);

  React.useEffect(() => {
    const getStore = async () => {
      let store = await getStoreSelect2();
      setStoreDataArray(store);
    };

    if (props.show) {
      getStore();
      setInvoiceStatusArray(getInvoiceStatusArray());
    }
  }, [props.show]);

  function cloneDeliveryRecordConfirmation(deliveryRecordToClone) {
    let alert = {
      type: "cloneDeliveryRecordConfirmation",
      title: "Are you Sure?",
      text: t("Ready to clone a delivery record"),
      dataToPass: deliveryRecordToClone,
      callback: cloneDeliveryRecord,
    };
    Notifications(alert);
  }

  function cloneDeliveryRecord(deliveryRecordToClone) {
    setDeliveryRecordSelectedDataForClone(deliveryRecordToClone);
    setModalDeliveryRecordAddIsOpen(true);
  }

  async function updateSubitems(
    localSubitemID,
    quantity,
    unitPrice,
    invoiceSubitemRemark,
    good
  ) {
    let updatedList = updateObjectInvoiceSubitems(localSubitemID, invoicesSubitemList, {
      localSubitemID,
      quantity,
      unitPrice,
      invoiceSubitemRemark,
      good: good
    });
    handleInvoiceSubitemList(updatedList);
  }

  async function addNewItemToInvoiceSubitemList(
    quantity,
    unitPrice,
    invoiceSubitemRemark,
    good
  ) {
    const newLocalSubitemID = uuid4();
    handleInvoiceSubitemList([
      ...invoicesSubitemList,
      { localSubitemID: newLocalSubitemID, quantity, unitPrice, invoiceSubitemRemark, good: good },
    ]);
  }

  function moveItemInInvoiceSubitemList(
    localSubitemID,
    upDownBoo
  ) {
    let arrayToWork = [...invoicesSubitemList];


    let foundIndex = arrayToWork.findIndex((item) => { return item.localSubitemID === localSubitemID })

    if (foundIndex != -1) {
      if (upDownBoo) {
        swapObjectInArray(arrayToWork, foundIndex, foundIndex + 1);
      } else {
        swapObjectInArray(arrayToWork, foundIndex, foundIndex - 1);
      }



      handleInvoiceSubitemList([...arrayToWork]);

    }
  }

  function handleOnChangeCustomerID(customerID) {
    setInvoiceCustomerID(customerID);
  }

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (paymentList.length > 0 && invoiceStatus === "Cancel") {
      Notifications({
        type: "error",
        message: "Invoice can't be cancelled when payment exist",
      });
    } else {
      try {
        setIsLoading(true);
        setInvoiceDetailEdited(false);




        // Setup the invoiceSubitems to submit
        try {
          invoicesSubitemList.forEach((element, index) => {
            element.goodID = element.good?.goodID;
            element.Good = element.good;
          });
        } catch (error) {

        }

        let dataInvoice = {
          invoiceID: invoiceID,
          invoiceNetPrice: invoiceNetPrice,
          invoiceStatus: invoiceStatus,
          invoiceRemark: invoiceRemark,
          invoiceMessage: invoiceMessage,
          invoiceCustomerID: invoiceCustomerID,
          storeID: storeIDSelected,
          invoicesSubitems: invoicesSubitemList,
        };

        let updateResponse = await updateInvoiceFull(dataInvoice);


        setIsLoading(false);

        if (updateResponse.code === 200) {
          //    setInvoiceDetailEdited(false);
          let deliverySyncResult = await syncDeliveryRecordSubitem();

          if (deliverySyncResult && deliverySyncResult.code === 200) {

          }
          refreshTableData();
          props.onHide();

        }
      } catch (err) {
        Notifications({ type: "error", message: err });
      }
    }
  };

  // return result
  const syncDeliveryRecordSubitem = async () => {

    // Data check
    if (!deliveryRecordList || !Array.isArray(deliveryRecordList) || deliveryRecordList.length <= 0) {
      return;
    }
    // If there is more than one delivery record
    // Do not sync
    // Only condition is there is only delivery record
    if (deliveryRecordList.length === 1) {
      let singleDeliveryRecord = deliveryRecordList[0];

      let deliveryRecordSubitems = [];

      invoicesSubitemList.forEach((item) => {
        deliveryRecordSubitems.push({
          deliverySubitemQuantity: item.quantity,
          deliverySubitemRemark: item.invoiceSubitemRemark,
          goodID: item.goodID
        })
      });

      let returnResult = await updateDeliveryRecordSubitem(singleDeliveryRecord.deliveryID, deliveryRecordSubitems);

      return returnResult;
    } else {
      // do nothing
      return {};
    }
  }


  const getDeliveryRecords = async (invoiceID) => {
    let delivery = await getDeliveryRecordsByInvoice(invoiceID);
    let deliveryData =
      delivery != null &&
        delivery != undefined
        ? delivery
        : [];
    setDeliveryRecordList(deliveryData);
  };

  const getPaymentDetails = async (idInvoice) => {
    setIsLoading(true);
    let paymentDetail = await getPaymentByInvoiceID(idInvoice);
    setIsLoading(false);

    setPaymentList(paymentDetail);
  };

  // Delete Payment
  async function deleteConfirmationPayment(id) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: id,
      callback: deletePaymentFunc,
    };
    Notifications(alert);
  }

  // Delete Payment API Call
  async function deletePaymentFunc(id) {
    setIsLoading(true);
    let deleteResponse = await deletePayment(id);
    setIsLoading(false);
    deleteResponse.code == 200
      ? refreshTableData()
      : true;
  }

  // Delete DO
  async function deleteConfirmationDO(deliveryID) {
    if (trafficControlDeliveryIDToProhibit && trafficControlDeliveryIDToProhibit === deliveryID) {
      Notifications({
        type: "trafficControl",
        message: t("Cannot delete this delivery record"),
      });
      return;
    }

    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: deliveryID,
      callback: deleteDOFunc,
    };
    Notifications(alert);
  }

  // Delete DO API Call
  async function deleteDOFunc(id) {
    try {
      let returnData = await deleteDeliveryRecord(id);
      getDeliveryRecords(invoiceData?.invoiceID);
      Notifications({ type: "delete", status: returnData.status, message: returnData.message });
    } catch (err) {
      Notifications({ type: "error", message: err });
    }
  }

  async function deleteConfirmation(id) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: id,
      callback: deleteInvoice,
    };
    Notifications(alert);
  }

  // Delete API Call
  async function deleteInvoice(id) {
    setIsLoading(true);
    let deleteResponse = await deleteInvoiceByID(id);
    if (deleteResponse.code == 200) {
      props.onHide();
    }
    setIsLoading(false);
  }

  // Delete this invoice
  async function onDeleteInvoice() {
    deleteConfirmation(invoiceID);
  }

  const totalPayment = () => {
    let total = 0;
    for (let i = 0; i < paymentList.length; i += 1) {
      total = total + paymentList[i]?.PaymentInvoiceMatches[0]?.paidAmount;
    }
    return total;
  };


  function handleInvoiceSubitemList(listToHandle) {
    if (!listToHandle || !Array.isArray(listToHandle) || listToHandle.length <= 0) {
      setInvoicesSubitemList([]);
      return;
    }


    // rearrange all the sortingNumber
    listToHandle.forEach((item, index) => {
      item.sortingNumber = index + 1;
    });

    setInvoicesSubitemList([...listToHandle]);
  };


  // Edit modal has no clear form function
  React.useEffect(() => {
    if (props.show) {
      const invoiceDataFromProps = props.invoiceData;
      if (!invoiceDataFromProps) return;

      setInvoiceData(invoiceDataFromProps);
      if (invoiceDataFromProps !== undefined && invoiceDataFromProps !== null) {
        let customerID =
          invoiceDataFromProps?.Customer != null && invoiceDataFromProps?.Customer != undefined
            ? invoiceDataFromProps?.Customer.customerID
            : [];
        setInvoiceCustomerID(customerID);
        setInvoiceID(invoiceDataFromProps?.invoiceID);
        setStoreIDSelected(invoiceDataFromProps?.storeID);
        let dateCreated = moment(new Date(invoiceDataFromProps?.invoiceRecordDateTime));
        setInvoiceRecordDateTime(dateCreated);
        setInvoiceRemark(invoiceDataFromProps?.invoiceRemark);
        setinvoiceMessage(invoiceDataFromProps?.invoiceMessage);
        setInvoiceNetPrice(invoiceDataFromProps?.invoiceNetPrice);
        getDeliveryRecords(invoiceDataFromProps?.invoiceID);
        getPaymentDetails(invoiceDataFromProps?.invoiceID);
        setInvoiceStatus(invoiceDataFromProps?.invoiceStatus);

        let invoiceSubitems =
          invoiceDataFromProps?.InvoiceSubitems != null &&
            invoiceDataFromProps?.InvoiceSubitems != undefined
            ? invoiceDataFromProps?.InvoiceSubitems
            : [];
        // Setup a localSubItemID 
        // Local 
        invoiceSubitems.forEach((element, index) => {
          element.localSubitemID = uuid4();
          element.good = element.Good;
          delete element.Good;
        });
        // already comes with a sorting Number
        handleInvoiceSubitemList(invoiceSubitems);
      }

      setFinishSubmitBoo(false);
    }
  }, [props.show]);

  React.useEffect(() => {

    const getInvoiceDataFcn = async (invoiceID) => {
      let singleInvoice = await getSingleInvoice(invoiceID);
      if (props.show) {
        if (!invoiceData) return;

        const invoiceDataFromProps = singleInvoice;
        if (!invoiceDataFromProps) return;

        setInvoiceData(invoiceDataFromProps);
        if (invoiceDataFromProps !== undefined && invoiceDataFromProps !== null) {
          let customerID =
            invoiceDataFromProps?.Customer != null && invoiceDataFromProps?.Customer != undefined
              ? invoiceDataFromProps?.Customer.customerID
              : [];
          setInvoiceCustomerID(customerID);
          setInvoiceID(invoiceDataFromProps?.invoiceID);
          setStoreIDSelected(invoiceDataFromProps?.storeID);
          let dateCreated = moment(new Date(invoiceDataFromProps?.invoiceRecordDateTime));
          setInvoiceRecordDateTime(dateCreated);
          setInvoiceRemark(invoiceDataFromProps?.invoiceRemark);
          setinvoiceMessage(invoiceDataFromProps?.invoiceMessage);

          setInvoiceNetPrice(invoiceDataFromProps?.invoiceNetPrice);
          getDeliveryRecords(invoiceDataFromProps?.invoiceID);
          getPaymentDetails(invoiceDataFromProps?.invoiceID);
          setInvoiceStatus(invoiceDataFromProps?.invoiceStatus);

          let invoiceSubitems =
            invoiceDataFromProps?.InvoiceSubitems != null &&
              invoiceDataFromProps?.InvoiceSubitems != undefined
              ? invoiceDataFromProps?.InvoiceSubitems
              : [];
          // Setup a localSubItemID 
          // Local 
          invoiceSubitems.forEach((element, index) => {
            element.localSubitemID = uuid4();
            element.good = element.Good;
            delete element.Good;
          });
          handleInvoiceSubitemList(invoiceSubitems);
        }
      }
    }
    getInvoiceDataFcn(invoiceData.invoiceID);

  }, [refreshTable]);

  React.useEffect(() => {
    const getCustomerDetails = async () => {
      let customer = await getCustomerByID(invoiceCustomerID);
      setCustomerSelectedData(customer);
    };
    if (invoiceCustomerID != "") {
      getCustomerDetails();
    }
  }, [invoiceCustomerID]);

  React.useEffect(() => {
    let totalPrice = invoicesSubitemList.reduce(
      (total, obj) => obj.unitPrice * obj.quantity + total, 0);
    // if (totalPrice > 0) {
    setInvoiceNetPrice(totalPrice);
    // }
  }, [invoicesSubitemList]);

  React.useEffect(() => {
    const deleteInvoicesSubitems = () => {

      handleInvoiceSubitemList(
        invoicesSubitemList.filter(
          (data) => data.localSubitemID !== deleteSelectedInvoicesSubitems
        )
      );
      setDeleteSelectedInvoicesSubitems("");
    };

    if (deleteSelectedInvoicesSubitems !== "") {
      deleteInvoicesSubitems();
    }
  }, [deleteSelectedInvoicesSubitems]);

  const editDO = (deliveryID) => {
    if (trafficControlDeliveryIDToProhibit && trafficControlDeliveryIDToProhibit === deliveryID) {
      Notifications({
        type: "trafficControl",
        message: t("Cannot edit this delivery record"),
      });
      return;
    }
    getDeliveryRecordsById(deliveryID).then((data) => {
      setSelectedDeliveryRecodData(data);
      setModalDeliveryRecordEditIsOpen(true);
    });
  };

  async function cloneInvoiceConfirmation(invoiceToClone) {
    let alert = {
      type: "cloneInvoiceConfirmation",
      title: "Are you Sure?",
      text: t("Ready to clone an invoice."),
      dataToPass: invoiceToClone,
      callback: cloneInvoice,
    };
    Notifications(alert);
  }

  function cloneInvoice(invoiceToClone) {
    setModalInvoiceCreateIsOpen(true);
  }

  return isLoggedIn() ? (
    <Modal
      isOpen={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      centered
      toggle={props.onHide}
      autoFocus={true}
      backdrop={'static'}
      trapFocus={false}
      keyboard={false}>

      {isLoading ? <SpinnerOverlay /> : ""}

      <ModalHeader className="mt-2 ml-2 mr-2" toggle={props.onHide} >
        <div>
          <div>{t("Edit Invoice")}</div>
          <div className="mt-1">
            <Badge color="primary" className="statusBadge ml--1">
              {invoiceID}
            </Badge>

            <InvoiceBadge
              invoiceData={invoiceData}
              badgeType="customerName"
              badgeClassName="statusBadge"
            />
          </div>
          <div className="mt-1">
            <Button
              color="primary"
              size="sm"
              onClick={() => { setShowAll(!showAll) }}>
              <i className={`fa fa-angle-${showAll ? "up" : "down"}`} />
            </Button>
            <Button
              className="ml--1"
              color="primary"
              size="sm"
              onClick={() => { setModalInvoiceMessageIsOpen(true); }}>
              <Trans>Invoice PDF</Trans>
            </Button>
            {!props.disableCloneInvoice &&
              <Button
                className="ml--1"
                color="primary"
                size="sm"
                onClick={() => { cloneInvoiceConfirmation(invoiceData); }}>
                <Trans>Copy invoice</Trans>
              </Button>
            }
            <Button
              className="ml--1"
              color="primary"
              size="sm"
              onClick={() => { setModalQuotationMessageIsOpen(true); }}>
              <Trans>Quotation PDF</Trans>
            </Button>
            <Button
              className="ml--1"
              color={isThisInvoiceExist(invoiceData) ? "success" : "danger"}
              size="sm"
              onClick={() => {
                if (isThisInvoiceExist(invoiceData)) {
                  deleteSingleInvoiceToGlobalList(invoiceData);
                } else {
                  addSingleInvoiceToGlobalList(invoiceData);
                }
              }}>
              {isThisInvoiceExist(invoiceData) ?
                <Trans>In invoice basket</Trans> :
                <Trans>Add to invoice basket</Trans>
              }
            </Button>
          </div>
        </div>
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <Row>
            <Col sm="12" lg="12">
              <div className="card-wrapper">
                <Card>
                  <CardHeader className="d-flex justify-content-between align-items-center">
                    <div>
                      <Row className="ml-1">
                        <h3 className="mb-0">{t("Invoice Detail")}</h3>
                      </Row>
                      <Row className="ml-1">
                        <InvoiceBadge
                          invoiceData={invoiceData}
                          badgeType="invoiceStatus"
                          badgeClassName="statusBadge"
                        />
                        <InvoiceBadge
                          invoiceData={invoiceData}
                          badgeType="invoiceNetPrice"
                          badgeClassName="statusBadge"
                        />
                      </Row>

                      {showInvoiceDetail &&
                        <Row className="ml-1 mt-1">
                          <InvoiceBadge
                            invoiceData={invoiceData}
                            badgeType="invoiceRecordDateTime"
                            badgeClassName="statusBadge"
                            useTranslate={(activeLang === "cn")}
                          />
                        </Row>
                      }
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowInvoiceDetail(!showInvoiceDetail);
                      }}
                      className="icon d-flex justify-content-end"
                    >
                      <i
                        className={`fa fa-caret-${showInvoiceDetail ? "up" : "down"
                          }`}
                      />
                    </div>
                  </CardHeader>


                  {/* Invoice Subitem Table */}
                  {showInvoiceDetail && (
                    <Card className="mb--2">
                      <CardHeader className="mb--2">
                        <div className="d-flex justify-content-between">
                          <div className="ml-2 mt-1">
                            <h1>{`$${invoiceNetPrice}`}{" "}{"("}{`${invoicesSubitemList.length}`}{t("items")}{")"}</h1>
                          </div>
                          <div className="mt-1">
                            {!props.disableAddProduct &&
                              <Button
                                color="primary"
                                onClick={() => {
                                  setModalIsOpen(true);
                                  setInvoiceDetailEdited(true);
                                }}
                                size="sm"
                              >
                                <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-plus" />
                                </span>
                                <span className="btn-inner--text">
                                  {t("Add")}
                                </span>
                              </Button>
                            }

                          </div>
                        </div>

                      </CardHeader>
                      <CardBody className="mt--3 px-0 pb-0">

                        <InvoiceSubitemTable
                          invoiceSubitemsTable={[...invoicesSubitemList]}
                          isDriver={isDriver}
                          selectSubitem={(invoiceSubitemItem) => {
                            setSelectedInvoicesSubitemData(invoiceSubitemItem);
                            setModalEditIsOpen(true);
                            setInvoiceDetailEdited(true);
                          }}
                          deleteSubitem={(subItemID) => {
                            setDeleteSelectedInvoicesSubitems(subItemID);
                            setInvoiceDetailEdited(true);
                          }}
                          disableDeleteGood={props.disableDeleteGood}
                          moveItem={(localSubitemID, upDownBoo) => {


                            moveItemInInvoiceSubitemList(localSubitemID, upDownBoo);
                            setInvoiceDetailEdited(true);
                          }}
                        />
                      </CardBody>

                    </Card>
                  )}

                  {/* Invoice detail */}
                  {showInvoiceDetail && (
                    <CardBody className="">
                      {/* Dropdown group for invoice status etc */}

                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Row className="mb-4">
                          <div className="w-100 d-flex justify-content-between">


                            <div>
                              <DropdownControl
                                disabled={false}
                                defaultColor="primary"
                                dropdownName={t("Invoice Status")}
                                useTranslate={true}
                                arrayIDTextFormat={invoiceStatusArray}
                                valueID={invoiceStatus}
                                onSelectValueID={(valueID) => {
                                  setInvoiceStatus(valueID);
                                  setInvoiceDetailEdited(true);
                                }}
                              />
                              <DropdownControl
                                disabled={false}
                                defaultColor="primary"
                                dropdownName={t("Store")}
                                useTranslate={false}
                                arrayIDTextFormat={storeDataArray}
                                valueID={storeIDSelected}
                                onSelectValueID={(valueID) => {
                                  setStoreIDSelected(valueID);
                                  setInvoiceDetailEdited(true);
                                }}
                              />

                            </div>


                          </div>
                        </Row>
                      </Col>


                      <Row>
                        {/* Basic invoice data */}
                        <Col xs={12} sm={12} md={12} lg={12}>



                          <Row className="">
                            <Col md={4}>
                              <Label className="form-control-label mt-4">
                                {t("Invoice Remark")}
                              </Label>
                            </Col>
                            <Col>
                              <InputTypeTextForm
                                label=""
                                labelFor="invoiceRemark"
                                inputName="invoiceRemark"
                                inputId="invoiceRemark"
                                inputPlaceholder={t("Invoice Remark")}
                                type="textarea"
                                value={invoiceRemark}
                                handleChange={(e) => {
                                  setInvoiceRemark(e.target.value);
                                  setInvoiceDetailEdited(true);
                                }
                                }
                              />
                            </Col>
                          </Row>

                          <Row className="">
                            <Col md={4}>
                              <Label className="form-control-label mt-4">
                                {t("Customer")}
                              </Label>

                            </Col>
                            <Col >
                              <Row className="p-1">
                                <Col md="12">

                                  <CustomerSelectInput
                                    preSelectCustomerID={invoiceCustomerID}
                                    selectCustomerID={(customerID) => {
                                      handleOnChangeCustomerID(customerID);
                                      setInvoiceDetailEdited(true);
                                    }}
                                    showCreateButton={false}
                                    onCreateNewCustomer={() => {

                                    }}
                                    disabled={props.disableChangeCustomer}
                                  />
                                </Col>
                              </Row>
                              {Object.keys(customerSelectedData).length > 0 ? (
                                <>
                                  <Row className="p-1">
                                    <Col >
                                      <CustomerDisplayCard
                                        hideCardHeader={true}
                                        customerData={customerSelectedData} />
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <></>
                              )}

                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={4}>
                              <Label className="form-control-label mt-4">
                                {t("Invoice Message")}
                              </Label>

                            </Col>
                            <Col >
                              <InputTypeTextForm
                                label=""
                                labelFor="invoiceMessage"
                                inputName="invoiceMessage"
                                inputId="invoiceMessage"
                                inputPlaceholder={t("Invoice Message")}
                                type="textarea"
                                value={invoiceMessage}
                                handleChange={(e) => { setinvoiceMessage(e.target.value) }}
                              />
                              <div className="d-flex flex-column align-items-right mt-1" >
                                <Button
                                  color={actionCopyDone ? "success" : "primary"}
                                  onClick={() => {
                                    navigator.clipboard.writeText(invoiceMessage);
                                    setActionCopyDone(true);
                                  }}
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-copy" />
                                  </span>
                                  <span className="btn-inner--text">{actionCopyDone ? t("Copy success") : t("Copy")}</span>
                                </Button>
                              </div>


                            </Col>
                          </Row>

                        </Col>
                        {/* QRcode in antoher column */}
                        <Col
                          className="d-flex flex-column align-items-center"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <QRCode
                            onClick={() =>
                              openPDFLink(invoiceData?.invoiceURL)
                            }
                            style={{ cursor: "pointer" }}
                            className="mt-5"
                            value={invoiceData?.invoiceURL ? invoiceData?.invoiceURL : ""}
                            size={200}
                          />
                          <div
                            onClick={() =>
                              openPDFLink(invoiceData?.invoiceURL)
                            }
                            style={{ cursor: "pointer" }}
                            className="text-sm text-underline mt-2"
                          >
                            {t("Click to view")}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  )}

                </Card>
              </div>
            </Col>
          </Row>

          {!props.disableDelivery &&
            <Row>
              <Col sm="12" lg="12">
                <div className="card-wrapper">
                  <Card >
                    <CardHeader className="d-flex justify-content-between align-items-center">
                      <div>
                        <Row className="ml-1">
                          <h3 className="mb-0">{t("Delivery Order Detail")}</h3>
                        </Row>
                        <Row className="ml-1">
                          <DeliveryCountBadge
                            hideIfZero={true}
                            deliveryRecordListData={deliveryRecordList}
                            deliveryStatus={"pending"}
                          />
                          <DeliveryCountBadge
                            hideIfZero={true}
                            deliveryRecordListData={deliveryRecordList}
                            deliveryStatus={"progress"}
                          />
                          <DeliveryCountBadge
                            hideIfZero={true}
                            deliveryRecordListData={deliveryRecordList}
                            deliveryStatus={"finish"}
                          />
                        </Row>

                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <Button
                          color="primary"
                          id="tooltipadddo"
                          // onClick={() => setModalIsOpenDO(true)}
                          onClick={(e) => {
                            e.preventDefault();
                            setModalDeliveryRecordAddIsOpen(true);
                          }}
                          size="sm"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">{t("Add DO")}</span>
                        </Button>
                        <UncontrolledTooltip delay={1} target="tooltipadddo">
                          {t("Add DO")}
                        </UncontrolledTooltip>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowDO(!showDO)}
                          className="icon d-flex justify-content-end"  >
                          <i className={`fa fa-caret-${showDO ? "up" : "down"}`} />
                        </div>
                      </div>
                    </CardHeader>

                    {showDO && deliveryRecordList && (
                      <CardBody className="ml--3 mr--3 mt--4" >
                        <Row className="justify-content-md-center">
                          <Col>
                            <ToolkitProvider
                              data={deliveryRecordList}
                              keyField="deliveryID"
                              columns={
                                [
                                  {
                                    dataField: "deliveryID",
                                    text: (
                                      t("Items")
                                    ),
                                    sort: true,
                                    formatter: (
                                      cellContent,
                                      row,
                                      rowIndex,
                                      extraData
                                    ) => {
                                      return (

                                        <div
                                          className="ml--2 mr--2"
                                          style={{ cursor: "pointer" }}
                                        >

                                          <div className="d-flex justify-content-between">
                                            <div className="ml--1"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                editDO(row?.deliveryID);

                                              }}>

                                              <DeliveryBadge
                                                deliveryRecordData={row}
                                                badgeType="deliveryStatus"
                                                badgeClassName="statusBadge"
                                              />
                                              <DeliveryBadge
                                                deliveryRecordData={row}
                                                badgeType="carID"
                                                badgeClassName="statusBadge"
                                              />
                                              <DeliveryBadge
                                                deliveryRecordData={row}
                                                badgeType="driverID"
                                                badgeClassName="statusBadge"
                                              />


                                            </div>
                                            <div className="mt--1 cardIconButton">

                                              <Badge color="info" className="statusBadge mr-1" onClick={() => {
                                                cloneDeliveryRecordConfirmation(row);
                                              }}>
                                                <i className="fa fa-copy" />
                                              </Badge>


                                              <Badge color="info" className="statusBadge mr-1" onClick={() => {
                                                setSelectedDeliveryRecodData(row);
                                                setModalDeliveryRecordMessageIsOpen(true);
                                              }}>
                                                <i className="fa fa-file" />
                                              </Badge>

                                              <Badge color="danger" className="statusBadge" onClick={() => {
                                                deleteConfirmationDO(row.deliveryID);
                                              }}>
                                                <i className="fas fa-trash" />
                                              </Badge>



                                            </div>

                                          </div>
                                          <div
                                            onClick={(e) => {
                                              e.preventDefault();
                                              editDO(row?.deliveryID);
                                            }}>

                                            <div className="tableRowTitle">
                                              {combineAddress(row?.DeliveryAddress)}
                                            </div>
                                            <div className="tableRowSubTitle">
                                              {getDateString(row.proposedDateTime, activeLang === "cn")}
                                            </div>
                                            <div className="tableRowRemark">
                                              #{row?.deliveryRemark}
                                            </div>
                                            <div className="tableRowContent">
                                              {row?.DeliveryRecordSubitems &&
                                                row?.DeliveryRecordSubitems?.map(
                                                  (item, i) => (
                                                    <div key={i}>
                                                      <Badge color={"info"} className="statusBadge">{i + 1}{'.'}</Badge>
                                                      <Badge color={"warning"} className="statusBadge">{item.deliverySubitemQuantity}{item?.Good?.goodUnit}</Badge>
                                                      <Badge color={"default"} className="statusBadge">{item?.Good?.goodDescription}</Badge>
                                                    </div>
                                                  )
                                                )}
                                            </div>

                                          </div>
                                        </div>
                                      );
                                    },
                                  }
                                ]
                              }
                              search
                            >
                              {(props) => (
                                <>
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      headerClasses="tableHiddenHeader" // only hide column when mobile
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      noDataIndication={
                                        <>
                                          <center>
                                            <h2>{t("Items Data Empty")}</h2>
                                          </center>
                                        </>
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </ToolkitProvider>
                          </Col>
                        </Row>
                      </CardBody>
                    )}
                  </Card>
                </div>
              </Col>
            </Row>
          }

          <Row>
            <Col sm="12" lg="12">
              <div className="card-wrapper">
                <Card>
                  <CardHeader >
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <Row className="ml-1">
                          <h3 className="mb-0">{t("Payment Detail")}</h3>
                        </Row>
                        <Row className="ml-1">
                          <PaymentCountBadge
                            hideIfZero={true}
                            badgeType="paymentMethod"
                            paymentListData={paymentList}
                            paymentFilterString={"cash"}
                          />
                          <PaymentCountBadge
                            hideIfZero={true}
                            badgeType="paymentMethod"
                            paymentListData={paymentList}
                            paymentFilterString={"cheque"}
                          />
                          <PaymentCountBadge
                            hideIfZero={true}
                            badgeType="paymentMethod"
                            paymentListData={paymentList}
                            paymentFilterString={"transfer"}
                          />
                        </Row>

                      </div>


                      <div className="d-flex justify-content-end align-items-center">
                        <Button
                          color="primary"
                          id="tooltippayment"
                          onClick={() => {
                            setModalAddPaymentIsOpen(true);
                          }}
                          size="sm"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">
                            {t("Add Payment")}
                          </span>
                        </Button>
                        <UncontrolledTooltip delay={1} target="tooltippayment">
                          {t("Add Payment")}
                        </UncontrolledTooltip>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPayment(!showPayment)}
                          className="icon d-flex justify-content-end"
                        >
                          <i
                            className={`fa fa-caret-${showPayment ? "up" : "down"
                              }`}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="mt-2">
                      <div className="mb-1">
                        <Badge color={invoiceData.invoiceStatus === 'Paid' ? 'success' : 'danger'} className="statusBadge">
                          ${invoiceData.paidAmount}/${invoiceData.invoiceNetPrice} ({Math.floor((100 * invoiceData.paidAmount) / invoiceData.invoiceNetPrice)}%)
                        </Badge>
                      </div>
                      <div>
                        <Progress
                          className="customProgressBar"
                          color={invoiceData.invoiceStatus === 'Paid' ? 'success' : 'danger'}
                          value={invoiceData.paidAmount}
                          max={invoiceData.invoiceNetPrice}
                        >
                        </Progress>

                      </div>
                    </div>

                  </CardHeader>
                  {showPayment && (
                    <>
                      <CardBody className="ml--3 mr--3 mt--4" >
                        <Row className="justify-content-md-center">
                          <Col>
                            <ToolkitProvider
                              data={paymentList}
                              keyField="paymentID"
                              columns={
                                [
                                  {
                                    dataField: "paymentRefNumber",
                                    text: (
                                      t("Items")
                                    ),
                                    sort: true,
                                    formatter: (
                                      cell,
                                      item,
                                      rowIndex,
                                      extraData
                                    ) => {
                                      return (
                                        <div
                                          className="ml--2 mr--2"
                                          style={{ cursor: "pointer" }}
                                        >

                                          <div className="d-flex justify-content-between" onClick={() => {
                                            setSelectedPaymentData(item);
                                            setModalEditPaymentIsOpen(true);
                                          }}>
                                            <div>
                                              <PaymentBadge
                                                paymentData={item}
                                                badgeType="paymentMethod"
                                                badgeClassName="statusBadge"
                                              />
                                              <PaymentBadge
                                                paymentData={item}
                                                badgeType="paymentRefNumber"
                                                badgeClassName="statusBadge"
                                              />
                                            </div>
                                            <div>

                                              <Badge color="info" className="statusBadge mr-1" onClick={() => {
                                                setSelectedPaymentData(item);
                                                setModalPaymentMessageIsOpen(true);
                                              }}>
                                                <i className="fa fa-file" />
                                              </Badge>

                                              <Badge color="info" className="statusBadge mr-1" onClick={() => {
                                                setSelectedPaymentData(item);
                                                setModalEditPaymentIsOpen(true);
                                              }}>
                                                <i className="fas fa-edit" />
                                              </Badge>

                                              {isAccessible('invoiceEditShowDeletePaymentButton') &&
                                                <Badge color="danger" className="statusBadge" onClick={() => {
                                                  deleteConfirmationPayment(item.paymentID);
                                                }}>
                                                  <i className="fas fa-trash" />
                                                  <span>{getAccessibleTagForSafeMode('invoiceEditShowDeletePaymentButton')}</span>
                                                </Badge>
                                              }
                                            </div>
                                          </div>
                                          <div onClick={() => {
                                            setSelectedPaymentData(item);
                                            setModalEditPaymentIsOpen(true);
                                          }}>
                                            <div className="tableSmallLabel mt-1 mb-0">
                                              {t("Payable to this invoice")}:
                                            </div>
                                            <div className="tableRowTitle">
                                              ${getCorrespondingPaidAmountForSingleInvoice(invoiceData.invoiceID, item?.PaymentInvoiceMatches)}
                                            </div>
                                            <div className="tableRowSubTitle">
                                              {t("Payment amount")}{": "}${item.paymentPrice}
                                            </div>
                                            <div className="tableRowSubTitle">
                                              {getDateString(item.paymentRecordDateTime, activeLang === "cn")}
                                            </div>
                                            <div className="tableRowSubTitle">
                                              {item?.paymentRecordUser}
                                            </div>
                                            <div className="tableRowRemark">
                                              {item.paymentRemark == "" ? "" : `#${item?.paymentRemark}`}
                                            </div>
                                          </div>

                                        </div>
                                      );
                                    },
                                  },
                                ]
                              }
                              search
                            >
                              {(props) => (
                                <>
                                  <div className="table-responsive pl-1">
                                    <BootstrapTable
                                      headerClasses="tableHiddenHeader" // only hide column when mobile
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      noDataIndication={
                                        <>
                                          <center>
                                            <h2>{t("Items Data Empty")}</h2>
                                          </center>
                                        </>
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </ToolkitProvider>
                          </Col>
                        </Row>
                      </CardBody>
                      <div className="bg-secondary mb-5 p-3 d-flex justify-content-end">
                        <Col md={5} sm={12} xs={12}>

                          <Row>
                            <Col className="d-flex justify-content-end text-right font-weight-bold">
                              {t("InvoiceNetPrice")}:
                            </Col>
                            <Col>$ {invoiceNetPrice}</Col>
                          </Row>
                          <Row>
                            <Col className="d-flex justify-content-end text-right font-weight-bold">
                              {t("Paid Amount")}:
                            </Col>
                            <Col>$ {totalPayment()}</Col>
                          </Row>
                          <Row>
                            <Col className="d-flex justify-content-end text-right font-weight-bold">
                              {t("Balance")}:
                            </Col>
                            <Col>$ {invoiceNetPrice - totalPayment()}</Col>
                          </Row>
                        </Col>
                      </div>
                    </>
                  )}
                </Card>
              </div>
            </Col>
          </Row>


        </ModalBody >
        <ModalFooter>
          <Container className="" fluid>
            <Card>
              <CardBody>
                <Row className={isAccessible('invoiceEditShowDeleteInvoiceButton') ? "d-flex justify-content-between" : "d-flex justify-content-end"}>

                  {isAccessible('invoiceEditShowDeleteInvoiceButton') &&
                    <Button
                      disabled={invoiceStatus.toUpperCase() != "CANCEL"}
                      color="danger"
                      type="button" // type="button" means the form will not submit
                      onClick={(e) => {
                        e.preventDefault();
                        onDeleteInvoice();
                      }}
                    >
                      {t("Delete invoice")}
                      <span>{getAccessibleTagForSafeMode('invoiceEditShowDeleteInvoiceButton')}</span>
                    </Button>

                  }
                  <div>
                    <SubmitButtonInForm
                      finishSubmitBoo={finishSubmitBoo}
                      buttonNormalText={"Save"}
                      buttonDisableFromParent={!invoiceDetailEdited} />


                    <Button
                      color="default"
                      type="button" // type="button" means the form will not submit
                      onClick={(e) => {
                        e.preventDefault();
                        //  history.push("invoice");
                        props.onHide();
                      }}
                    >
                      {t("Back")}
                    </Button>

                  </div>

                </Row>
              </CardBody>
            </Card>
          </Container>

        </ModalFooter>

      </Form>
      <InvoiceAddProductModal
        show={modalIsOpen}
        onHide={() => setModalIsOpen(false)}
        title={t("Add Invoice Items")}
        button={t("Save")}
        addInvoicesSubitems={addNewItemToInvoiceSubitemList}
      />
      <InvoiceEditSubitemModal
        show={modalEditIsOpen}
        onHide={() => setModalEditIsOpen(false)}
        title={t("Edit Invoice Items")}
        button={t("Update")}
        invoicesSubitemsData={selectedInvoicesSubitemData}
        updateInvoicesSubitems={(localSubitemID,
          quantity,
          unitPrice,
          invoiceSubitemRemark,
          good) => {
          updateSubitems(localSubitemID,
            quantity,
            unitPrice,
            invoiceSubitemRemark,
            good);
        }}
        disableGoodSelection={props.disableChangeGood}
      />
      <InvoiceAddPaymentModal
        show={modalAddPaymentIsOpen}
        onHide={() => setModalAddPaymentIsOpen(false)}
        title={t("Add Payment")}
        button={t("Save")}
        data={invoiceData}
        refreshTable={() => {
          refreshTableData();
        }}
      />

      <PaymentEditModal
        show={modalEditPaymentIsOpen}
        onHide={() => setModalEditPaymentIsOpen(false)}
        title={t("Payment Detail")}
        button={t("Save")}
        refresh={() => {
          refreshTableData();
        }}
        selectedData={selectedPaymentData}
      />
      <DeliveryRecordEditModal
        show={modalDeliveryRecordEditIsOpen}
        deliveryData={selectedDeliveryRecodData}
        onHide={() => {
          setModalDeliveryRecordEditIsOpen(false);
          refreshTableData();
        }}
        trafficControlInvoiceIDToProhibit={invoiceID} // traffic control is to prohibit data corruption
      />
      <DeliveryRecordCreateModal
        show={modalDeliveryRecordAddIsOpen}
        invoiceData={invoiceData}
        deliveryRecordToClone={deliveryRecordSelectedDataForClone}
        onHide={() => {
          refreshTableData();
          setModalDeliveryRecordAddIsOpen(false);
        }}
      />

      <InvoiceMessageModal
        show={modalInvoiceMessageIsOpen}
        onHide={() => setModalInvoiceMessageIsOpen(false)}
        title={t("Invoice Message")}
        refreshTable={() => { }}
        invoiceData={invoiceData}
      />

      <PaymentMessageModal
        show={modalPaymentMessageIsOpen}
        onHide={() => setModalPaymentMessageIsOpen(false)}
        title={t("Payment Message")}
        refreshTable={() => { }}
        paymentData={selectedPaymentData}
      />

      <DeliveryRecordMessageModal
        show={modalDeliveryRecordMessageIsOpen}
        onHide={() => setModalDeliveryRecordMessageIsOpen(false)}
        title={t("Delivery Message")}
        refreshTable={() => { }}
        deliveryRecordData={selectedDeliveryRecodData}
      />

      <InvoiceCreateModal
        invoiceToClone={invoiceData}
        show={modalInvoiceCreateIsOpen}
        onHide={(returnCreatedInvoiceID) => {
          // Just jump to this modal
          // When come back
          // also hide me
          setModalInvoiceCreateIsOpen(false);
          props.onHide();
        }}
        allowEmptySubitemList={true}
      />
      <QuotationMessageModal
        show={modalQuotationMessageIsOpen}
        onHide={() => setModalQuotationMessageIsOpen(false)}
        title={t("Quotation Message")}
        refreshTable={() => { }}
        customerData={invoiceData.Customer}
        invoiceRemark={invoiceData.invoiceRemark}
        invoiceSubitemList={invoiceData.InvoiceSubitems}
        deliveryRecordShoppingCartList={deliveryRecordList}
      />
    </Modal >
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default InvoiceEditModal;
