import React from "react";
import { useTranslation } from "react-i18next";
import DropdownControl from "../DropdownControl";
import { getDriverSelect2 } from "functions/Driver";
import { getDeliveryStatusArray } from "functions/Delivery";


function ChooseDeliveryStatusDropdown(props) {
    const { disabled, currentDeliveryStatus, onSelectDeliveryStatus } = props;

    const { t } = useTranslation();
    const [deliveryStatusArray, setDeliveryStatusArray] = React.useState([]);

    // get the data for the choice
    React.useEffect(() => {
        setDeliveryStatusArray(getDeliveryStatusArray());
    }, []);


    return (
        <DropdownControl
            disabled={disabled}
            defaultColor="primary"
            useTranslate={true}
            dropdownName={t("Delivery Status")}
            arrayIDTextFormat={deliveryStatusArray}
            valueID={currentDeliveryStatus}
            onSelectValueID={async (valueID) => {
                onSelectDeliveryStatus(valueID);
            }}
        />

    );
}

export default ChooseDeliveryStatusDropdown;

