import { getDeliveryRecordsById } from "functions/Delivery";
import { moveUpSingleItemInArray } from "functions/Utility";
import { deleteSingleItemInArray } from "functions/Utility";
import { addArrayToArray } from "functions/Utility";
import { refreshAllItemInArray } from "functions/Utility";
import { returnCheckExistArray } from "functions/Utility";
import { refreshSingleItemInArray } from "functions/Utility";
import { addSingleItemToArray } from "functions/Utility";
import { checkItemExistInArray } from "functions/Utility";
import { moveDownSingleItemInArray } from "functions/Utility";
import { createContext, useContext, useState, useEffect } from "react";

const DeliveryBasketContext = createContext();

export default function DeliveryBasketContextProvider({ children }) {
    const [deliveryRecordGlobalList, setDeliveryRecordGlobalList] = useState([]);
    const [deliveryRecordGlobalListCount, setDeliveryRecordGlobalListCount] = useState(deliveryRecordGlobalList.length);

    useEffect(() => {
        setDeliveryRecordGlobalListCount(deliveryRecordGlobalList.length);
    }, [deliveryRecordGlobalList]);

    const isThisDeliveryRecordExist = (deliveryRecordToCheck) => {
        // go through many times 
        // handle later
        return checkItemExistInArray(deliveryRecordGlobalList, deliveryRecordToCheck, 'deliveryID');
    }

    // return the item with a key isExistInGlobalList
    const returnCheckDeliveryRecordExistItem = (deliveryRecordToCheck) => {
        if (checkItemExistInArray(deliveryRecordGlobalList, deliveryRecordToCheck, 'deliveryID')) {
            deliveryRecordToCheck.isExistInGlobalList = true;
        } else {
            deliveryRecordToCheck.isExistInGlobalList = false;
        };
        return deliveryRecordToCheck;
    }

    // return the list with a key isExistInGlobalList
    const returnCheckDeliveryRecordExistList = (deliveryRecordListToCheck) => {
        // return an list of delivery record with 
        // [{other data: XXX, isExistInGlobalList: true/false}, {DR2}, .... , {DRn}]
        let newList = returnCheckExistArray(deliveryRecordGlobalList, deliveryRecordListToCheck, 'deliveryID');
        return newList;
    }

    // refresh single record drom db
    const refreshSingleDeliveryRecordFromDBInList = async (deliveryIDToRefresh) => {
        let refreshedArray = await refreshSingleItemInArray(deliveryRecordGlobalList, 'deliveryID', deliveryIDToRefresh, getDeliveryRecordsById(deliveryIDToRefresh))
        setDeliveryRecordGlobalList([...refreshedArray]);
    }

    // refresh all record drom db
    const refreshAllDeliveryRecordFromDBInList = async () => {
        let newList = await  refreshAllItemInArray(deliveryRecordGlobalList, 'deliveryID', getDeliveryRecordsById);
        setDeliveryRecordGlobalList([...newList]);
    }

    // Append single record into the list
    const addSingleDeliveryRecordToGlobalList = (deliveryRecordToAdd, allowRepeatDeliveryID = false) => {
        let addedResult = addSingleItemToArray(deliveryRecordGlobalList, deliveryRecordToAdd, 'deliveryID', allowRepeatDeliveryID)
        setDeliveryRecordGlobalList(addedResult);
    }

    // Append records into the list
    const addDeliveryRecordListToGlobalList = (deliveryRecordListToAdd, allowRepeatDeliveryID = false) => {
        let addedResult = addArrayToArray(deliveryRecordGlobalList, deliveryRecordListToAdd, 'deliveryID', allowRepeatDeliveryID);
        setDeliveryRecordGlobalList(addedResult);
    }

    // delete single record into the list
    const deleteSingleDeliveryRecordToGlobalList = (deliveryRecordToDelete) => {
        let filteredResult = deleteSingleItemInArray(deliveryRecordGlobalList, deliveryRecordToDelete, 'deliveryID');
        setDeliveryRecordGlobalList([...filteredResult]);
    }

    // delete all records into the list
    const deleteAllDeliveryRecordToGlobalList = () => {
        setDeliveryRecordGlobalList([]);
    }

    // move up the item
    const moveUpSingleDeliveryRecordInGlobalList = (deliveryRecordToMove, toTheFront = false) => {
        let movedArray = moveUpSingleItemInArray(deliveryRecordGlobalList, deliveryRecordToMove, 'deliveryID', toTheFront);
        if (movedArray && Array.isArray(movedArray)) {
            setDeliveryRecordGlobalList([...movedArray]);
        }
    }

    // move down the item
    const moveDownSingleDeliveryRecordInGlobalList = (deliveryRecordToMove, toTheLast = false) => {
        let movedArray = moveDownSingleItemInArray(deliveryRecordGlobalList, deliveryRecordToMove, 'deliveryID', toTheLast);
        if (movedArray && Array.isArray(movedArray)) {
            setDeliveryRecordGlobalList([...movedArray]);
        }
    }


    return (
        <DeliveryBasketContext.Provider
            value={{
                deliveryRecordGlobalList,
                setDeliveryRecordGlobalList,
                deliveryRecordGlobalListCount,
                addSingleDeliveryRecordToGlobalList,
                deleteSingleDeliveryRecordToGlobalList,
                isThisDeliveryRecordExist,
                returnCheckDeliveryRecordExistList,
                refreshSingleDeliveryRecordFromDBInList ,
                refreshAllDeliveryRecordFromDBInList,
                moveUpSingleDeliveryRecordInGlobalList,
                moveDownSingleDeliveryRecordInGlobalList,
                deleteAllDeliveryRecordToGlobalList,
                addDeliveryRecordListToGlobalList
            }}
        >
            {children}
        </DeliveryBasketContext.Provider>
    )

}

export const DeliveryBasketListGlobal = () => {
    return useContext(DeliveryBasketContext);
}
