import React from "react";
import {
    Badge
    , Button
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";



function CardMoveButtonGroup(props) {
    const { showMoveButton = true, showIndex = true, showDeleteButton = true, cardIndex, itemToMove, moveCardUp, moveCardDown, deleteCard } = props;
    const { t } = useTranslation();

    return (
        <>

            {showIndex &&
                <Badge color={"primary"} className="mb-1 mr-1 badge-lg text-lg badge-circle">
                    {cardIndex}
                </Badge>
            }
            {showMoveButton &&
                <>
                    <Button size="sm" color="primary" className="mb-1 mr-1"
                        onClick={() => {
                            moveCardUp(itemToMove, true);
                        }}>
                        <Trans>First</Trans>
                    </Button>
                    <Button size="sm" color="primary" className="mb-1 mr-1"
                        onClick={() => {
                            moveCardUp(itemToMove, false);
                        }}>
                        <i className="ni ni-bold-up" />
                    </Button>
                    <Button size="sm" color="primary" className="mb-1 mr-1"
                        onClick={() => {
                            moveCardDown(itemToMove, false);
                        }}>
                        <i className="ni ni-bold-down" />
                    </Button>
                    <Button size="sm" color="primary" className="mb-1"
                        onClick={() => {
                            moveCardDown(itemToMove, true);
                        }}>
                        <Trans>Last</Trans>
                    </Button>
                    <Button size="sm" color="danger" className="mb-1"
                        onClick={() => {
                            deleteCard(itemToMove);
                        }}>
                        <Trans>Delete</Trans>
                    </Button>
                </>
            }

        </>
    );


};
export default CardMoveButtonGroup;
