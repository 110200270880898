import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    Badge,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    CardTitle
} from "reactstrap";
import QRCode from "react-qr-code";
import { uuid4 } from "@sentry/utils";
import { Trans, useTranslation } from "react-i18next";

// core components
import Dropzone from "dropzone";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal";

import { getDeliveryRecordsByDateRangeAndDeliveryStatus } from "functions/Delivery";
import DeliveryStatCard from "../components/DeliveryStatCard";
import DeliveryRecordCard from "../deliveryRecord/DeliveryRecordCard";
import CustomerActionModal from "../customer/CustomerActionModal";
import DeliveryRecordEditModal from "../deliveryRecord/DeliveryRecordEditModal";
import { getDeliveryStatusColor } from "functions/Delivery";
import { getDateStringWithoutTime } from "functions/Utility";
import DeliveryRecordCreateModal from "../deliveryRecord/DeliveryRecordCreateModal";
import DeliveryRecordMessageModal from "../deliveryRecord/DeliveryRecordMessageModal";
import { DeliveryBasketListGlobal } from "context/DeliveryBasketContext";
Dropzone.autoDiscover = false;


// props.startDate
// props.endDate
// props.deliveryStatus

function SalesAccountingDeliveryRecordListModal(props) {

    const startDate = props.startDate;
    const endDate = props.endDate;
    const deliveryStatus = props.deliveryStatus;

    const { isThisDeliveryRecordExist
        , addSingleDeliveryRecordToGlobalList
        , deleteSingleDeliveryRecordToGlobalList
    } = DeliveryBasketListGlobal();

    const { t } = useTranslation();
    //Initialize Get Set

    const [refreshTable, setRefreshTable] = React.useState(0);

    const [deliveryRecordListData, setDeliveryRecordListData] = React.useState([]);


    const [sortPriceSmall, setSortPriceSmall] = React.useState(true);
    const [sortDateSmall, setSortDateSmall] = React.useState(true);

    // for modal 
    const [modalCustomerActionIsOpen, setModalCustomerActionIsOpen] = React.useState(false);
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [modalDeliveryRecordEditIsOpen, setModalDeliveryRecordEditIsOpen] = React.useState(false);
    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);

    const [invoiceSelectedDataForInvoiceClone, setInvoiceSelectedDataForInvoiceClone] = React.useState({});
    const [deliveryRecordSelectedDataForClone, setDeliveryRecordSelectedDataForClone] = React.useState({});

    const [modalDeliveryRecordAddIsOpen, setModalDeliveryRecordAddIsOpen] = React.useState(false);
    const [modalDeliveryRecordMessageIsOpen, setModalDeliveryRecordMessageIsOpen] = React.useState(false);

    // For deliveryRecordCard
    const [selectedInvoice, setSelectedInvoice] = React.useState(null);
    const [selectedDeliveryRecodData, onSelectedDeliveryRecodData] = React.useState({});
    const [selectedCustomerData, setSelectedCustomerData] = React.useState({});


    // For filter control show
    const [showFullCard, setShowFullCard] = React.useState(false);

    //Initialize Effect
    React.useEffect(() => {
        const getDeliveryRecordListData = async () => {
            let deliveryRecordListData = await getDeliveryRecordsByDateRangeAndDeliveryStatus(startDate, endDate, deliveryStatus);
            setDeliveryRecordListData(deliveryRecordListData);
        };

        if (props.show) {
            getDeliveryRecordListData();
        }
    }, [props.show, refreshTable]);


    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const sortPrice = () => {
        setSortDateSmall(true);
        let res = [];
        if (!sortPriceSmall) {
            res = deliveryRecordListData.sort((a, b) => a.Invoice?.invoiceNetPrice - b.Invoice?.invoiceNetPrice);
        } else {
            res = deliveryRecordListData.sort((a, b) => b.Invoice?.invoiceNetPrice - a.Invoice?.invoiceNetPrice);
        }
        setDeliveryRecordListData(res);
        setSortPriceSmall(!sortPriceSmall);
    };

    const sortDate = () => {
        setSortPriceSmall(true);
        let res = [];
        if (!sortDateSmall) {
            res = deliveryRecordListData.sort(
                (a, b) =>
                    new Date(a.proposedDateTime) - new Date(b.proposedDateTime)
            );
        } else {
            res = deliveryRecordListData.sort(
                (a, b) =>
                    new Date(b.proposedDateTime) - new Date(a.proposedDateTime)
            );
        }
        setDeliveryRecordListData(res);
        setSortDateSmall(!sortDateSmall);
    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader className="mt-2 ml-2 mr-2" toggle={props.onHide} >
                    <div>
                        <span> {t("Delivery Record")}</span>
                    </div>

                    <div className="mt-1">
                        <Badge color={getDeliveryStatusColor(deliveryStatus)} className="statusBadge"><Trans>{deliveryStatus}</Trans></Badge>
                    </div>
                    <div className="mt-1">
                        <Badge color="primary" className="statusBadge"><Trans>Start</Trans>:{getDateStringWithoutTime(startDate, true)}</Badge>
                    </div>
                    <div className="mt-1">
                        <Badge color="primary" className="statusBadge"><Trans>End</Trans>:{getDateStringWithoutTime(endDate, true)}</Badge>
                    </div>
                </ModalHeader>
                <ModalBody>



                    {/* Stat */}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <DeliveryStatCard
                                deliveryListData={deliveryRecordListData}
                            />
                        </Col>

                    </Row>
                    {/* filter Card */}
                    <Row>
                        <Col>
                            <Card className="card-stats">
                                <CardHeader
                                    role="tab">
                                    <Row>
                                        <Col className="col-auto pr-0">
                                            <Button
                                                onClick={() => { setShowFullCard(!showFullCard) }}
                                                className="mt-1"
                                                color="primary">
                                                <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
                                            </Button>

                                            <Button onClick={sortPrice} className="mt-1" color="primary">
                                                {t("Price")}{" "}
                                                <i
                                                    className={`fas fa-sort-numeric-${sortPriceSmall ? "up" : "down"
                                                        }`}
                                                />
                                            </Button>

                                            <Button onClick={sortDate} className="mt-1" color="primary">
                                                {t("Date")}{" "}
                                                <i
                                                    className={`fas fa-sort-numeric-${sortDateSmall ? "up" : "down"
                                                        }`}
                                                />
                                            </Button>
                                        </Col>

                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>

                    {/* Invoice list */}
                    <Row>
                        {deliveryRecordListData && deliveryRecordListData.length != 0 && deliveryRecordListData.map((deliveryRecordItem, index) => (
                            <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                                data-id={deliveryRecordItem.deliveryID}
                                key={deliveryRecordItem.deliveryID}
                            >
                                <DeliveryRecordCard
                                    showFullCard={showFullCard}
                                    deliveryRecordData={deliveryRecordItem}
                                    setClickViewInvoiceButton={(invoice) => {
                                        setSelectedInvoice(invoice);
                                        setModalInvoiceMessageIsOpen(true);
                                    }}
                                    onClickCard={(deliveryRecord) => {
                                        onSelectedDeliveryRecodData(deliveryRecord);
                                        setModalDeliveryRecordEditIsOpen(true);
                                    }}
                                    isSelectedCard={false}
                                    cloneInvoice={(invoice) => {
                                        setInvoiceSelectedDataForInvoiceClone(invoice);
                                        setModalInvoiceCreateIsOpen(true);
                                    }}
                                    customerAction={(customer) => {
                                        setSelectedCustomerData(customer);
                                        setModalCustomerActionIsOpen(true);
                                    }}
                                    cloneDeliveryRecord={(deliveryRecord) => {
                                        setDeliveryRecordSelectedDataForClone(deliveryRecord);
                                        setModalDeliveryRecordAddIsOpen(true);
                                    }}
                                    showDeliveryMessage={(deliveryRecord) => {
                                        onSelectedDeliveryRecodData(deliveryRecord);
                                        setModalDeliveryRecordMessageIsOpen(true);
                                    }}
                                    addOrDeleteDeliveryRecordToGlobalList={(deliveryRecordToAddOrDelete) => {
                                        if (isThisDeliveryRecordExist(deliveryRecordToAddOrDelete)) {
                                            deleteSingleDeliveryRecordToGlobalList(deliveryRecordToAddOrDelete);
                                        } else {
                                            addSingleDeliveryRecordToGlobalList(deliveryRecordToAddOrDelete);
                                        }
                                    }}
                                />


                            </Col>
                        ))}
                        {deliveryRecordListData && deliveryRecordListData.length === 0 && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <strong className="text-info h1">
                                            {t("No Delivery Record Found!")}
                                        </strong>
                                        <h3 className="text-info">
                                            {t("Please Check Your Filter")} </h3>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    {/* End of Invoice list */}

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="default"
                        type="button"
                        onClick={() => {
                            props.onHide();
                        }}
                    >
                        {t("Back")}
                    </Button>
                </ModalFooter>
            </Modal>

            <CustomerActionModal
                show={modalCustomerActionIsOpen}
                onHide={() => setModalCustomerActionIsOpen(false)}
                title={t("Choose Action")}
                button={t("Update")}
                customerData={selectedCustomerData}
                refreshTable={refreshTableData}
            />
            <InvoiceMessageModal
                show={modalInvoiceMessageIsOpen}
                onHide={() => setModalInvoiceMessageIsOpen(false)}
                title={t("Invoice Message")}
                refreshTable={() => { }}
                invoiceData={selectedInvoice}
            />

            <DeliveryRecordEditModal
                show={modalDeliveryRecordEditIsOpen}
                deliveryData={selectedDeliveryRecodData}
                onHide={() => {
                    refreshTableData();
                    setModalDeliveryRecordEditIsOpen(false);
                }}
            />

            <InvoiceCreateModal
                invoiceToClone={invoiceSelectedDataForInvoiceClone}
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                    refreshTableData();
                }}
                allowEmptySubitemList={true}
            />

            <DeliveryRecordCreateModal
                show={modalDeliveryRecordAddIsOpen}
                invoiceData={deliveryRecordSelectedDataForClone.Invoice}
                deliveryRecordToClone={deliveryRecordSelectedDataForClone}
                onHide={() => {
                    refreshTableData();
                    setModalDeliveryRecordAddIsOpen(false);
                }}
            />
            <DeliveryRecordMessageModal
                show={modalDeliveryRecordMessageIsOpen}
                onHide={() => setModalDeliveryRecordMessageIsOpen(false)}
                title={t("Delivery Message")}
                refreshTable={() => { }}
                deliveryRecordData={selectedDeliveryRecodData}
            />
        </>
    );
}

export default SalesAccountingDeliveryRecordListModal;
