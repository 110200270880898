
import React, { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import moment from "moment";

import { isMobile } from "react-device-detect";

const marginTop = '5mm';
const marginRight = '5mm';
const marginLeft = '5mm';
const marginBottom = '5mm';

const pixelTomm = 0.2645833333;

export const getPageMargins = () => {
  return `@media print { 
                @page { 
                  margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;
                  size: auto;
                  }
                }
     `;
  // overflow: hidden !important;
  // text-overflow: ellipsis !important;
  // white-space: nowrap !important;

  // white-space: pre-line;
};

export function textLengthInmm(text, { size = 1 } = {}) {
  return textLength(text, size) * pixelTomm;
  ;
}
export function textLength(text, { size = 1 } = {}) {
  return text.split("").map(charLength).map((x) => x * size).reduce((partialSum, a) => partialSum + a, 0);
  ;
}

export function charLength(char) {
  const code = char.charCodeAt(0);
  return code > 127 && code <= 65535 ? 2 : 1;
}

export function wrapText(text, options) {
  const { size, width, newLineToBreak = true } = options;
  const lines = [];
  const chars = text.split("");
  let line = "";
  chars.forEach((char, i) => {
    // append the char to the line
    line = `${line}${char}`;


    if (char === '\n' && newLineToBreak) {
      // just opne a new line
      lines.push(adjustLine(line, size, width));
      line = '';
    } else {
      // calculate the length
      const lengthOfLine = textLength(line, { size });
      // Compare the length with width
      if (lengthOfLine > width) {
        // longer than 1 char
        // Cut off the char
        line = line.slice(0, line.length - 1);
        // push the cutted line into the lines array 
        lines.push(adjustLine(line, size, width));
        // the new line is starting from the single char 
        line = char;
      }

      // Below just handle when the char is the last char
      const isLast = i === chars.length - 1;
      if (isLast && line.length > 0) {
        lines.push(adjustLine(line, size, width));
      }

      // If the line isn't pushed into the line array
      // the for loop keeps going to append a new char into the line
    }


  });
  return lines;
}

export function adjustLine(line, size, length) {
  const spaceCount = calcSpaceCount(line, size, length);
  const safeCount = Math.max(spaceCount, 0);
  return `${line}${" ".repeat(safeCount)}`;
}

export function calcSpaceCount(line, size, length) {
  let count = 0;
  while (true) {
    const lineWithSpace = `${line}${" ".repeat(count)}`;
    const lineLength = textLength(lineWithSpace, { size });
    if (lineLength >= length) {
      return lineLength === length ? count : count - 1;
    }
    count += 1;
  }
}