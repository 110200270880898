import React from "react";
import { getCarSelect2 } from "functions/Car";
import {  useTranslation } from "react-i18next";
import DropdownControl from "../DropdownControl";


function ChooseCarDropdown(props) {
    const { disabled, currentCarID, onSelectCarID } = props;

    const { t } = useTranslation();
    const [carChooseData, setCarChooseData] = React.useState([]);

    // get the data for the choice
    React.useEffect(() => {
        const getCar = async () => {
            let car = await getCarSelect2();
            setCarChooseData(car);
        };
        getCar();
    }, []);


    return (
        <DropdownControl
            disabled={disabled}
            defaultColor={"primary"}
            dropdownName={t("Car")}
            useTranslate={false}
            arrayIDTextFormat={carChooseData}
            valueID={currentCarID}
            onSelectValueID={async (valueID) => {
                onSelectCarID(valueID);
            }}
        />

    );
}

export default ChooseCarDropdown;

