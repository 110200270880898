import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { addDeliveryAddress } from "functions/DeliveryAddress";
import DeliveryAddressInputFieldWithMap from "../components/DeliveryAddressInputFieldWithMap.js";
import Notifications from "../components/Notifications";
import SpinnerOverlay from "../components/SpinnerOverlay";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";


// props
/* 
show={modalCreateDeliveryAddressIsOpen}
onHide={(e) => {
  setModalCreateDeliveryAddressIsOpen(false);
}}
title={t("Create new address")}
button={t("Add")}
refreshTable={refreshTableData}
deliveryAddressToClone={selectedDeliveryAddressToCloneData}
 */


function AddressModalCreate(props) {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    const [addressData, setAddressData] = React.useState({
        addressDetailUnitFloor: "",
        addressDetailBuilding: "",
        addressDetailStreet: "",
        addressDetailDistrict: "",
        addressDetailMainDistrict: "",
        addressRemark: "",
        addressGPSLatitude: 0,
        addressGPSLongtitude: 0
    });

    const [deliveryAddressToClone, setDeliveryAddressToClone] = React.useState({
        addressDetailUnitFloor: "",
        addressDetailBuilding: "",
        addressDetailStreet: "",
        addressDetailDistrict: "",
        addressDetailMainDistrict: "",
        addressRemark: "",
        addressGPSLatitude: 0,
        addressGPSLongtitude: 0
    });

    const { addressDetailUnitFloor, addressDetailBuilding, addressDetailStreet, addressDetailDistrict, addressDetailMainDistrict, addressRemark, addressGPSLatitude, addressGPSLongtitude } = addressData;




    const handleSubmit = async (e) => {
        e.preventDefault();

        // data check
        if (addressDetailMainDistrict === "" || addressDetailDistrict === "" || addressDetailStreet === "" || addressDetailBuilding === "" || addressDetailUnitFloor === "") {
            Notifications({
                type: "dataCheck",
                message: t("Please input an address")
            });
            return;
        }


        let dataSubmit = {
            addressDetailUnitFloor: addressDetailUnitFloor,
            addressDetailBuilding: addressDetailBuilding,
            addressDetailStreet: addressDetailStreet,
            addressDetailDistrict: addressDetailDistrict,
            addressDetailMainDistrict: addressDetailMainDistrict,
            addressDetailCity: "香港",
            addressRemark: addressRemark,
            addressGPSLatitude: addressGPSLatitude,
            addressGPSLongtitude: addressGPSLongtitude,
        };
        setIsLoading(true);

        let resultJson = await addDeliveryAddress(dataSubmit);

        let addressID = resultJson.deliveryAddressID;
        if (addressID && addressID !== "") {
            try {
                props.getNewAddressData(resultJson);
            } catch (error) {
            }
            clearForm();
            props.refreshTable();
            props.onHide();
        }

        setIsLoading(false);
    };

    const clearForm = () => {
        setAddressData("", "", "", "", "", "", 0, 0)

        setDeliveryAddressToClone({
            addressDetailUnitFloor: "",
            addressDetailBuilding: "",
            addressDetailStreet: "",
            addressDetailDistrict: "",
            addressDetailMainDistrict: "",
            addressRemark: "",
            addressGPSLatitude: 0,
            addressGPSLongtitude: 0
        });

        setFinishSubmitBoo(false);
    };

    React.useEffect(() => {
        if (props.deliveryAddressToClone && Object.values(props.deliveryAddressToClone).length >= 1) {
            setDeliveryAddressToClone(props.deliveryAddressToClone);
            // also activate the "input button"
        } else {
            setDeliveryAddressToClone(addressData); // if the addressData is not empty, use it // This is for the case that the user closed the form 
        }
    }, [props.show, props.deliveryAddressToClone]);


    return (
        <>
            <Modal
            className="modal-dialog"
                isOpen={props.show}
                size="lg"
                //   aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                {isLoading ? <SpinnerOverlay /> : ""}
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={props.onHide}>
                        <div>{props.title}</div>
                        {props.specialMessage &&
                            <div>{props.specialMessage}</div>
                        }
                    </ModalHeader>
                    <ModalBody className="mt--4">
                        <DeliveryAddressInputFieldWithMap
                            createMode={true}
                            showMap={false}
                            readOnly={false}
                            readOnlyFullAddress={true}
                            submitAddressData={(addressData) => { setAddressData(addressData); }}
                            deliveryAddressToEdit={deliveryAddressToClone}
                            fieldMapSameRow={true} />
                    </ModalBody>
                    <ModalFooter className="mt--4">

                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                <Button
                                    color="danger"
                                    type="button" // type="button" means the form will not submit
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearForm();
                                    }}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>

                            <div>
                                <SubmitButtonInForm
                                    finishSubmitBoo={finishSubmitBoo}
                                    buttonNormalText={"Add"} />

                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </Row>


                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default AddressModalCreate;
