import React from "react";
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import CustomerActionModal from "../customer/CustomerActionModal";

import { Trans, useTranslation } from "react-i18next";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal"
import DeliveryRecordCard from "../../../views/pages/deliveryRecord/DeliveryRecordCard";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";
import DeliveryRecordEditModal from "../deliveryRecord/DeliveryRecordEditModal";

import { getDateString, removeDuplicateItemInStringArray } from "../../../functions/Utility";

import DeliveryRecordCreateModal from "../deliveryRecord/DeliveryRecordCreateModal";
import DeliveryRecordMessageModal from "../deliveryRecord/DeliveryRecordMessageModal.js";
import { DeliveryBasketListGlobal } from "context/DeliveryBasketContext";
import SpinnerOverlay from "../components/SpinnerOverlay";
import InputTypeTextForm from "../components/InputTypeTextForm";
import { combineAddress } from "functions/Delivery";
import Print80mmModal from "views/80mmPrinter/page/Print80mmModal";
import CardMoveButtonGroup from "../components/cardRelated/CardMoveButtonGroup";
import ChooseCarDropdown from "../components/deliveryRelated/ChooseCarDropdown";
import ChooseDriverDropdown from "../components/deliveryRelated/ChooseDriverDropdown";
import ChooseDeliveryStatusDropdown from "../components/deliveryRelated/ChooseDeliveryStatusDropdown";
import { updateDeliveryRecordCarID } from "functions/Delivery";
import Notifications from "../components/Notifications";
import { updateDeliveryRecordDriverID } from "functions/Delivery";
import { updateDeliveryRecordDeliveryStatus } from "functions/Delivery";
import { isAccessible, getAccessibleTagForSafeMode } from "functions/SystemUserAccess";
import DeliveryTeamChooseModal from "../deliveryTeam/DeliveryTeamChooseModal";
import DeliveryTruckWorkerListButtonDropdownGroup from "../components/DeliveryTruckWorkerListButtonDropdownGroup";
import { updateDeliveryRecordTruckWorkers } from "functions/Delivery";


function DeliveryBasketModal(props) {
    const { show, onHide } = props;
    const { t, i18n } = useTranslation();
    const activeLang = i18n.language;

    // Context
    const { deliveryRecordGlobalList
        , isThisDeliveryRecordExist
        , addSingleDeliveryRecordToGlobalList
        , deleteSingleDeliveryRecordToGlobalList
        , returnCheckDeliveryRecordExistList
        , refreshSingleDeliveryRecordFromDBInList
        , refreshAllDeliveryRecordFromDBInList
        , moveUpSingleDeliveryRecordInGlobalList
        , moveDownSingleDeliveryRecordInGlobalList
        , deleteAllDeliveryRecordToGlobalList } = DeliveryBasketListGlobal();

    // data
    const [displayDeliveryRecordData, setDisplayDeliveryRecordData] = React.useState([]);

    const [refreshTable, setRefreshTable] = React.useState(0);

    // For deliveryRecordCard
    const [selectedInvoice, setSelectedInvoice] = React.useState(null);
    const [selectedDeliveryRecodData, onSelectedDeliveryRecodData] = React.useState({});
    const [selectedCustomerData, setSelectedCustomerData] = React.useState({});

    // Data Transfer
    const [invoiceSelectedDataForInvoiceClone, setInvoiceSelectedDataForInvoiceClone] = React.useState({});
    const [deliveryRecordSelectedDataForClone, setDeliveryRecordSelectedDataForClone] = React.useState({});

    // GUI
    const [isLoading, setIsLoading] = React.useState(false);
    const [showFullCard, setShowFullCard] = React.useState(false);
    const [actionCopyDone, setActionCopyDone] = React.useState(false);
    const [deliveryIDJustMoved, setDeliveryIDJustMoved] = React.useState('');
    const [isDeliveryRecordDataValid, setIsDeliveryRecordDataValid] = React.useState(false);


    // data control
    const [deliveryBasketMessage, setDeliveryBasketMessage] = React.useState('');

    // modal
    const [modalCustomerActionIsOpen, setModalCustomerActionIsOpen] = React.useState(false);
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [modalDeliveryRecordEditIsOpen, setModalDeliveryRecordEditIsOpen] = React.useState(false);
    const [modalDeliveryRecordAddIsOpen, setModalDeliveryRecordAddIsOpen] = React.useState(false);
    const [modalDeliveryRecordMessageIsOpen, setModalDeliveryRecordMessageIsOpen] = React.useState(false);
    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);
    const [modalPrint80mmIsOpen, setModalPrint80mmIsOpen] = React.useState(false);
    const [modalDeliveryTeamChooseModalIsOpen, setModalDeliveryTeamChooseModalIsOpen] = React.useState(false);


    // Update delivery carID, driverID, deliveryStatus
    const [carID, setCarID] = React.useState('');
    const [driverID, setDriverID] = React.useState('');
    const [deliveryStatus, setDeliveryStatus] = React.useState('');
    const [deliveryRecordTruckWorkerMatches, setDeliveryRecordTruckWorkerMatches] = React.useState([]);




    React.useEffect(() => {
        if (refreshTable > 0) {
            // After calling this 
            refreshSingleDeliveryRecordFromDBInList(selectedDeliveryRecodData.deliveryID);
        }
    }, [refreshTable]);


    React.useEffect(() => {

        // Set the message
        let messageArrayToGet = [];
        let messageToGet = '';

        messageArrayToGet = displayDeliveryRecordData.map((item, index) => {
            let subitemText = item.DeliveryRecordSubitems.map((deliveryRecordSubitem) => {
                return `${deliveryRecordSubitem.deliverySubitemQuantity}${deliveryRecordSubitem?.Good?.goodUnit}${deliveryRecordSubitem?.Good?.goodNameShortForm}`
            }).join(', ');

            let deliveryMessageCustomContent = t('Address') + ': ' + combineAddress(item.DeliveryAddress)
                + '\n' + t('Date') + ': ' + getDateString(item.proposedDateTime, activeLang === "cn", false)
                + '\n' + t('Customer') + ': ' + item.Invoice.Customer.customerName
                + '\n' + t('Good') + ': ' + subitemText;
            return t('No.') + " " + (index + 1).toString() + " " + t('place') + '\n' + deliveryMessageCustomContent;
        });

        messageToGet = messageArrayToGet.join('\n\n');

        setDeliveryBasketMessage(messageToGet);
    }, [displayDeliveryRecordData]);


    React.useEffect(() => {
        // Reset the copy button
        setActionCopyDone(false);
        if (displayDeliveryRecordData && Array.isArray(displayDeliveryRecordData) && Object.values(displayDeliveryRecordData).length >= 1) {
            setIsDeliveryRecordDataValid(true);
        } else {
            setIsDeliveryRecordDataValid(false);
        }
    }, [displayDeliveryRecordData]);



    React.useEffect(() => {
        // Bypass the initial record
        // Use display data to do the work
        handleDisplayDeliveryRecordData(deliveryRecordGlobalList);
    }, [deliveryRecordGlobalList]);

    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const handleDisplayDeliveryRecordData = (filteredSortedDeliveryRecordData) => {
        let listWithExistGlobalList = returnCheckDeliveryRecordExistList(filteredSortedDeliveryRecordData);
        setDisplayDeliveryRecordData(listWithExistGlobalList);
    }


    function isJustMoved(deliveryIDToCheck) {
        if (deliveryIDJustMoved === '') {
            return false;
        } else if (deliveryIDJustMoved === deliveryIDToCheck) {
            return true;
        } else {
            return false;
        }
    }

    const confirmUpdateCarID = async (carIDToUpdate) => {
        // confirm
        Notifications({
            type: "generalConfirmationWithID",
            title: t("Are you sure?"),
            message: t('Update car'),
            elementID: carIDToUpdate,
            callback: updateCarIDToServer,
            cancel: () => { }
        })
    }

    const confirmUpdateDriverID = async (driverIDToUpdate) => {
        // confirm
        Notifications({
            type: "generalConfirmationWithID",
            title: t("Are you sure?"),
            message: t('Update driver'),
            elementID: driverIDToUpdate,
            callback: updateDriverIDToServer,
            cancel: () => { }
        })
    }


    const confirmUpdateDeliveryStatus = async (deliveryStatusToUpdate) => {
        // confirm
        Notifications({
            type: "generalConfirmationWithID",
            title: t("Are you sure?"),
            message: t('Update delivery status'),
            elementID: deliveryStatusToUpdate,
            callback: updateDeliveryStatusToServer,
            cancel: () => { }
        })
    }

    const confirmUpdateTruckWorkerList = async (truckWorkerIDs) => {
        // confirm
        Notifications({
            type: "generalConfirmationWithID",
            title: t("Are you sure?"),
            message: t('Update delivery status'),
            elementID: truckWorkerIDs, // is an array
            callback: updateTruckWorkerListToServer,
            cancel: () => { }
        })
    }

    // Update carID to server directly
    const updateCarIDToServer = async (carIDToUpdate) => {
        try {
            // check the data before upload
            //  check car
            if (carIDToUpdate === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose a car')
                });
                return;
            }
            setIsLoading(true);
            let deliveryRecordListToSubmit = displayDeliveryRecordData;
            let responseArray = [];
            if (deliveryRecordListToSubmit && Array.isArray(deliveryRecordListToSubmit) && deliveryRecordListToSubmit.length >= 1) {
                for (let i = 0; i < deliveryRecordListToSubmit.length; i += 1) {
                    // check deliveryID
                    if (deliveryRecordListToSubmit[i].deliveryID !== "") {
                        let response = await updateDeliveryRecordCarID(deliveryRecordListToSubmit[i].deliveryID, carIDToUpdate, false);
                        responseArray.push(t(`${response.message}`));
                    }
                }
                await refreshAllDeliveryRecordFromDBInList();
                try {
                    let responseMessage = removeDuplicateItemInStringArray(responseArray).join(', ');
                    Notifications({
                        type: "store",
                        message: responseMessage,
                    });
                } catch (error) {

                }
            }

            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })

        }
    };

    // Update driverID to server directly
    const updateDriverIDToServer = async (driverIDToUpdate) => {
        try {
            // check the data before upload
            //  check car
            if (driverIDToUpdate === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose a driver')
                });
                return;
            }
            setIsLoading(true);
            let deliveryRecordListToSubmit = displayDeliveryRecordData;
            let responseArray = [];
            if (deliveryRecordListToSubmit && Array.isArray(deliveryRecordListToSubmit) && deliveryRecordListToSubmit.length >= 1) {
                for (let i = 0; i < deliveryRecordListToSubmit.length; i += 1) {
                    // check deliveryID
                    if (deliveryRecordListToSubmit[i].deliveryID !== "") {
                        let response = await updateDeliveryRecordDriverID(deliveryRecordListToSubmit[i].deliveryID, driverIDToUpdate, false);
                        responseArray.push(t(`${response.message}`));
                    }
                }

                await refreshAllDeliveryRecordFromDBInList();

                try {
                    let responseMessage = removeDuplicateItemInStringArray(responseArray).join(', ');
                    Notifications({
                        type: "store",
                        message: responseMessage,
                    });
                } catch (error) {

                }
            }
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })

        }
    };


    // Update deliveryStatus to server directly
    const updateDeliveryStatusToServer = async (deliveryStatusToUpdate) => {
        try {
            // check the data before upload
            //  check car
            if (deliveryStatusToUpdate === "") {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose a delivery status')
                });
                return;
            }
            setIsLoading(true);
            let deliveryRecordListToSubmit = displayDeliveryRecordData;
            let responseArray = [];
            if (deliveryRecordListToSubmit && Array.isArray(deliveryRecordListToSubmit) && deliveryRecordListToSubmit.length >= 1) {
                for (let i = 0; i < deliveryRecordListToSubmit.length; i += 1) {
                    // check deliveryID
                    if (deliveryRecordListToSubmit[i].deliveryID !== "") {
                        let response = await updateDeliveryRecordDeliveryStatus(deliveryRecordListToSubmit[i].deliveryID, deliveryStatusToUpdate, false);
                        responseArray.push(t(`${response.message}`));
                    }
                }
                await refreshAllDeliveryRecordFromDBInList();
                try {
                    let responseMessage = removeDuplicateItemInStringArray(responseArray).join(', ');
                    Notifications({
                        type: "store",
                        message: responseMessage,
                    });
                } catch (error) {

                }
            }
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })

        }
    };

    // Update truckworker to server directly
    const updateTruckWorkerListToServer = async (truckWorkerIDs) => {

        try {
            // check the data before upload
            if (!truckWorkerIDs || !Array.isArray(truckWorkerIDs) || truckWorkerIDs.length < 0) {
                Notifications({
                    type: "dataCheck",
                    message: t('Please choose truck worker')
                });
                return;
            }
            setIsLoading(true);
            let deliveryRecordListToSubmit = displayDeliveryRecordData;
            let responseArray = [];
            if (deliveryRecordListToSubmit && Array.isArray(deliveryRecordListToSubmit) && deliveryRecordListToSubmit.length >= 1) {
                for (let i = 0; i < deliveryRecordListToSubmit.length; i += 1) {
                    // check deliveryID
                    if (deliveryRecordListToSubmit[i].deliveryID !== "") {
                        let response = await updateDeliveryRecordTruckWorkers(deliveryRecordListToSubmit[i].deliveryID, truckWorkerIDs, false);
                        responseArray.push(t(`${response.message}`));
                    }
                }
                await refreshAllDeliveryRecordFromDBInList();
                try {
                    let responseMessage = removeDuplicateItemInStringArray(responseArray).join(', ');
                    Notifications({
                        type: "store",
                        message: responseMessage,
                    });
                } catch (error) {

                }
            }
            setIsLoading(false);
        } catch (e) {
            Notifications({ type: "error", message: e.message })

        }
    };

    return (
        <Modal
            isOpen={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={props.onHide}
            autoFocus={true}
            backdrop={'static'}
            trapFocus={false}
            keyboard={false}
        >
            {isLoading ? <SpinnerOverlay /> : ""}
            <ModalHeader className="" toggle={onHide} >
                <Row className="mt-1">
                    <Col>
                        <Trans>
                            Delivery basket
                        </Trans>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Button className="mr-1" color="primary" size="sm" onClick={async () => {
                            setIsLoading(true);
                            await refreshAllDeliveryRecordFromDBInList();
                            setIsLoading(false);
                        }}>
                            <Trans>
                                Refresh
                            </Trans>
                        </Button>
                        <Button className="mr-1" color="danger" size="sm" onClick={async () => {
                            setIsLoading(true);
                            deleteAllDeliveryRecordToGlobalList();
                            setIsLoading(false);

                        }}>
                            <Trans>
                                Delete all
                            </Trans>
                        </Button>
                    </Col>

                </Row>

            </ModalHeader>
            <ModalBody>

                {isDeliveryRecordDataValid &&
                    <Row>
                        <Col>
                            <InputTypeTextForm
                                label=""
                                labelFor="deliveryBasketMessage"
                                inputName="deliveryBasketMessage"
                                inputId="deliveryBasketMessage"
                                inputPlaceholder={t("Multiple delivery message")}
                                type="textarea"
                                value={deliveryBasketMessage}
                                handleChange={(e) => { setDeliveryBasketMessage(e.target.value) }}
                            />
                            <div className="d-flex mt-1" >
                                <Button
                                    className="flex-grow-1"
                                    color={actionCopyDone ? "success" : "primary"}
                                    onClick={() => {
                                        navigator.clipboard.writeText(deliveryBasketMessage);
                                        setActionCopyDone(true);
                                    }}
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-copy" />
                                    </span>
                                    <span className="btn-inner--text">{actionCopyDone ? t("Copy success") : t("Copy text")}</span>
                                </Button>
                                <Button
                                    className="flex-grow-1"
                                    color={"warning"}
                                    onClick={() => { setModalPrint80mmIsOpen(true); }}
                                >
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-print" />
                                    </span>
                                    <span className="btn-inner--text">{"80mm"}</span>
                                </Button>
                            </div>
                            <div className="mt-2 vw-100w">
                                <span>{getAccessibleTagForSafeMode('deliveryEditChooseDeliveryStatus')}</span>
                                <span>
                                    <ChooseDeliveryStatusDropdown
                                        disabled={!isAccessible('deliveryEditChooseDeliveryStatus')}
                                        currentDeliveryStatus={deliveryStatus}
                                        onSelectDeliveryStatus={async (valueID) => {
                                            setDeliveryStatus(valueID);
                                            confirmUpdateDeliveryStatus(valueID);
                                        }}
                                    /></span>
                                <span>{getAccessibleTagForSafeMode('deliveryEditChooseDeliveryTeam')}</span>
                                <span className="mt-1 ml-1">
                                    <Button
                                        disabled={!isAccessible('deliveryEditChooseDeliveryTeam')}
                                        color="warning"
                                        onClick={() => {
                                            setModalDeliveryTeamChooseModalIsOpen(true);
                                        }} >
                                        <span className="btn-inner--icon mr-1">
                                            <i className="fas fa-plus" />
                                        </span>
                                        <span className="btn-inner--text">
                                            {t("Team")}
                                        </span>
                                    </Button>
                                </span>

                            </div>
                            <div className="mt-2 vw-100w" >

                                <span>{getAccessibleTagForSafeMode('deliveryEditChooseCar')}</span>
                                <span>
                                    <ChooseCarDropdown
                                        disabled={!isAccessible('deliveryEditChooseCar')}
                                        currentCarID={carID}
                                        onSelectCarID={async (valueID) => {
                                            setCarID(valueID);
                                            confirmUpdateCarID(valueID);
                                        }}
                                    />
                                </span>

                                <span>{getAccessibleTagForSafeMode('deliveryEditChooseDriver')}</span>
                                <span className="ml-1">
                                    <ChooseDriverDropdown
                                        disabled={!isAccessible('deliveryEditChooseDriver')}
                                        currentDriverID={driverID}
                                        onSelectDriverID={async (valueID) => {
                                            setDriverID(valueID);
                                            confirmUpdateDriverID(valueID);
                                        }}
                                    />
                                </span>
                                <span>{getAccessibleTagForSafeMode('deliveryEditChooseTruckWorker')}</span>
                                <span className="ml-1">
                                    <DeliveryTruckWorkerListButtonDropdownGroup
                                        disabled={!isAccessible('deliveryEditChooseTruckWorker')}
                                        DeliveryRecordTruckWorkerMatches={deliveryRecordTruckWorkerMatches}
                                        getChosenTruckWorkerList={async (chosenTruckWorkerList) => {
                                            // chosenTruckWorkerList = [{id: 'asd', text: 'sffe', other: }, {id: 'asd', text: 'sffe', other: }, ...]
                                            if (chosenTruckWorkerList && Array.isArray(chosenTruckWorkerList) && chosenTruckWorkerList.length >= 0) {
                                                // truckWorkerIDs = [{truckWorkerID: 'aSDF'}, {truckWorkerID: 'ffddf'}, ...]
                                                let truckWorkerIDs = chosenTruckWorkerList.map((item) => {
                                                    return { truckWorkerID: item.id };
                                                });


                                                // For this button to update the text (value)
                                                //   setDeliveryRecordTruckWorkerIDsToSubmit(truckWorkerIDs);

                                                // Approach changed
                                                // Fast mode 
                                                // Just directly update the DB
                                                confirmUpdateTruckWorkerList(truckWorkerIDs);

                                                // Get the data from DB
                                                // Refresh the whole form
                                                // getDeliveryRecordFromDB();
                                            }
                                        }}
                                    />
                                </span>


                            </div>
                        </Col>
                    </Row>
                }
                {!isDeliveryRecordDataValid &&
                    <Row className="mt-1">
                        <Col>
                            <Button color="danger">
                                <>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-times" />
                                    </span>
                                    <span className="btn-inner--text">
                                        {t("No delivery record")}
                                    </span>
                                </>
                            </Button>
                        </Col>
                    </Row>
                }

                <Row className="mt-3">
                    {displayDeliveryRecordData && Object.values(displayDeliveryRecordData).length >= 1 && displayDeliveryRecordData.map((deliveryItem, index) => (
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            data-id={deliveryItem.deliveryID}
                            key={deliveryItem.deliveryID}
                        >

                            <div className="ml-1">
                                <CardMoveButtonGroup
                                    cardIndex={index + 1}
                                    itemToMove={deliveryItem}
                                    moveCardUp={(itemToMove, toTheFront) => {
                                        moveUpSingleDeliveryRecordInGlobalList(itemToMove, toTheFront);
                                        setDeliveryIDJustMoved(itemToMove.deliveryID);
                                    }}
                                    moveCardDown={(itemToMove, toTheLast) => {
                                        moveDownSingleDeliveryRecordInGlobalList(itemToMove, toTheLast);
                                        setDeliveryIDJustMoved(itemToMove.deliveryID);
                                    }}
                                    showDeleteButton={true}
                                    deleteCard={(itemToDelete) => {
                                        deleteSingleDeliveryRecordToGlobalList(itemToDelete);
                                    }}
                                />
                            </div>

                            <DeliveryRecordCard
                                showFullCard={showFullCard}
                                cardBgColor={""}
                                enableCardBgColor={false}
                                isSelectedCard={isJustMoved(deliveryItem.deliveryID)}
                                deliveryRecordData={deliveryItem}
                                setClickViewInvoiceButton={(invoice) => {
                                    setSelectedInvoice(invoice);
                                    setModalInvoiceMessageIsOpen(true);
                                }}
                                onClickCard={(deliveryRecord) => {
                                    onSelectedDeliveryRecodData(deliveryRecord);
                                    setModalDeliveryRecordEditIsOpen(true);
                                    setDeliveryIDJustMoved(deliveryItem.deliveryID);
                                }}
                                cloneInvoice={(invoice) => {
                                    setInvoiceSelectedDataForInvoiceClone(invoice);
                                    setModalInvoiceCreateIsOpen(true);
                                }}
                                customerAction={(customer) => {
                                    setSelectedCustomerData(customer);
                                    setModalCustomerActionIsOpen(true);
                                }}
                                cloneDeliveryRecord={(deliveryRecord) => {
                                    setDeliveryRecordSelectedDataForClone(deliveryRecord);
                                    setModalDeliveryRecordAddIsOpen(true);
                                }}
                                showDeliveryMessage={(deliveryRecord) => {
                                    onSelectedDeliveryRecodData(deliveryRecord);
                                    setModalDeliveryRecordMessageIsOpen(true);
                                }}
                                addOrDeleteDeliveryRecordToGlobalList={(deliveryRecordToAddOrDelete) => {

                                    if (isThisDeliveryRecordExist(deliveryRecordToAddOrDelete)) {
                                        deleteSingleDeliveryRecordToGlobalList(deliveryRecordToAddOrDelete);
                                    } else {
                                        addSingleDeliveryRecordToGlobalList(deliveryRecordToAddOrDelete);
                                    }
                                }}
                            />

                        </Col>
                    ))}
                    {displayDeliveryRecordData.length === 0 && (
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <strong className="text-info h1">
                                        {t("No Delivery Record Found!")}
                                    </strong>
                                    <h3 className="text-info">{t("Please Check Your Filter")}</h3>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
            </ModalBody>

            <ModalFooter>

            </ModalFooter>

            <CustomerActionModal
                show={modalCustomerActionIsOpen}
                onHide={() => setModalCustomerActionIsOpen(false)}
                title={t("Choose Action")}
                button={t("Update")}
                customerData={selectedCustomerData}
                refreshTable={refreshTableData}
            />
            <InvoiceMessageModal
                show={modalInvoiceMessageIsOpen}
                onHide={() => setModalInvoiceMessageIsOpen(false)}
                title={t("Invoice Message")}
                refreshTable={() => { }}
                invoiceData={selectedInvoice}
            />
            <DeliveryRecordEditModal
                show={modalDeliveryRecordEditIsOpen}
                deliveryData={selectedDeliveryRecodData}
                onHide={() => {
                    refreshTableData();
                    setModalDeliveryRecordEditIsOpen(false);
                }}
            />
            <InvoiceCreateModal
                invoiceToClone={invoiceSelectedDataForInvoiceClone}
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                    refreshTableData();
                }}
                allowEmptySubitemList={true}
            />

            <DeliveryRecordCreateModal
                show={modalDeliveryRecordAddIsOpen}
                invoiceData={deliveryRecordSelectedDataForClone.Invoice}
                deliveryRecordToClone={deliveryRecordSelectedDataForClone}
                onHide={() => {
                    refreshTableData();
                    setModalDeliveryRecordAddIsOpen(false);
                }}
            />

            <DeliveryRecordMessageModal
                show={modalDeliveryRecordMessageIsOpen}
                onHide={() => setModalDeliveryRecordMessageIsOpen(false)}
                title={t("Delivery Message")}
                refreshTable={() => { }}
                deliveryRecordData={selectedDeliveryRecodData}
            />

            <Print80mmModal
                show={modalPrint80mmIsOpen}
                onHide={() => setModalPrint80mmIsOpen(false)}
                textToPrint={deliveryBasketMessage}
                headerText={t('Multiple delivery')}
            />

            <DeliveryTeamChooseModal
                show={modalDeliveryTeamChooseModalIsOpen}
                onHide={() => setModalDeliveryTeamChooseModalIsOpen(false)}
                title={t("Choose a delivery team")}
                button={t("Confirm")}
                returnSingleDeliveryTeam={async (deliveryTeamToReturn) => {
                    setCarID(deliveryTeamToReturn.carID);
                    setDriverID(deliveryTeamToReturn.driverID);
                    await updateCarIDToServer(deliveryTeamToReturn.carID);
                    await updateDriverIDToServer(deliveryTeamToReturn.driverID);
                }}
            />
        </Modal>
    );
}

export default DeliveryBasketModal;
