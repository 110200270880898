import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Col,
  Label,
  Input,
  Row,
  FormGroup,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";

import { useTranslation } from "react-i18next";
import { useState } from "react";
import InputTypeSelect2 from "../components/InputTypeSelect2Form";
import { storePayment } from "functions/Payment.js";
import Notifications from "../components/Notifications.js";
import { convertFileToBase64 } from "functions/Utility";
import { addPaymentPhoto } from "functions/Payment";
import PhotoUploadArea from "../components/PhotoUploadArea.js";
import { getBase64PhotoHeadRemoved } from "functions/Utility";
import InvoiceBadge from "../components/InvoiceBadge.js";
import InvoiceListToPayMessageArea from "../components/InvoiceListToPayMessageArea.js";
import DropdownControl from "../components/DropdownControl.js";

import { getInvoiceByCustomerID } from "functions/Invoice";
import { getPaymentMethodArray } from "functions/Payment";
import { getCustomerDatatable } from "functions/Customer";

import SpinnerOverlay from "../components/SpinnerOverlay";
import CustomerSelectInput from "../components/CustomerSelectInput";
import SubmitButton from "../components/SubmitButton";

function PaymentCreateModal(props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);

  // for selection
  const [paymentMethodArray, setPaymentMethodArray] = useState([]);

  const [selectedCustomerID, setSelectedCustomerID] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [customerData, setCustomerData] = useState([]);

  const [invoiceToPayInitialList, setInvoiceToPayInitialList] = useState([]);
  const [invoiceUnpaidPartialSortedList, setInvoiceUnpaidPartialSortedList] = useState([]);


  const [rerender, setRerender] = useState(false);
  const [paymentRefNumber, setPaymentRefNumber] = useState("");
  const [paymentRemark, setPaymentRemark] = useState("");

  const [paymentPhoto, setPaymentPhoto] = React.useState({
    paymentImageID: "",
    paymentImageBase64Content: ""
  });

  const { paymentImageID, paymentImageBase64Content } = paymentPhoto;


  const [sortPriceSmall, setSortPriceSmall] = React.useState(true);
  const [sortDateSmall, setSortDateSmall] = React.useState(true);

  const [isInvoiceCustomerDisabled, setIsInvoiceCustomerDisabled] = React.useState(false);


  React.useEffect(() => {
    const getPaymentArrayData = () => {
      let paymentMethodData = getPaymentMethodArray();
      setPaymentMethodArray(paymentMethodData);
      setPaymentMethod("Cash");
    };
    if (props.show) {
      getPaymentArrayData();
    }
  }, [props.show]);


  React.useEffect(() => {

    if (props.show) {
      getCustomer();
    }

    // From other page or component which carries a state with a preSelectedCustomer
    if (props.show && props.preSelectedCustomer && props.preSelectedCustomer !== undefined && props.preSelectedCustomer !== null) {
      // Set Customer
      setSelectedCustomerID(props.preSelectedCustomer?.customerID);

      setIsInvoiceCustomerDisabled(true);

      searchCustomer(props.preSelectedCustomer?.customerID);
    }
  }, [props.show]);
  const onChangePaymentPhoto = (photoIDToUpdate, photoBase64ContentToUpdate) => {
    setPaymentPhoto({
      paymentImageID: photoIDToUpdate,
      paymentImageBase64Content: photoBase64ContentToUpdate
    });
  };

  const getCustomer = async () => {
    setIsLoading(true);
    let customer = await getCustomerDatatable();
    setIsLoading(false);
    setCustomerData(customer);
  };



  const clearForm = () => {
    setPaymentRefNumber("");
    setPaymentRemark("");
    setInvoiceToPayInitialList([]);
    setInvoiceUnpaidPartialSortedList([]);
    setSelectedCustomerID("");
    setPaymentPhoto("", "");
    setPaymentMethod("Cash");
  };

  const searchCustomer = async (customerID) => {
    const getInvoiceData = async () => {
      if (customerID && customerID !== "") {
        let invoiceData = await getInvoiceByCustomerID(customerID, ["Unpaid", "Partial"]); // add invoice Status, so no need to supply invoice status
        return invoiceData;
      } else {
        return [];
      }
    };

    if (customerID && customerID !== "") {
      setSelectedCustomerID(customerID);

      let invoiceData = await getInvoiceData();
      if (invoiceData && Object.values(invoiceData).length >= 1) {
        // Default added invoicePrice and pay into list for calcuation
        invoiceData.forEach(function (element) {
          //element.invoicePrice = element.invoiceNetPrice;
          element.invoicePrice = element.unPaidAmount;
          element.amountToPay = element.unPaidAmount;
          element.amountToPayForMessage = element.unPaidAmount;
          element.unPaidAmountForMessage = element.unPaidAmount;
        });

        // also set the invoiceToPayList
        // Let all the list be selected 
        setInvoiceUnpaidPartialSortedList(invoiceData);
        setInvoiceToPayInitialList(invoiceData);
      }
    }
  };

  const calculatePriceToPay = (data) => {
    const net = data.invoiceNetPrice;
    const arrPayment = data.PaymentInvoiceMatches;
    let payment = net;
    for (let i = 0; i < arrPayment.length; i += 1) {
      payment = payment - arrPayment[i].paidAmount;
    }
    return payment;
  };

  const checkIsSelectedList = (invoiceID) => {
    return invoiceUnpaidPartialSortedList.some((val) => val.invoiceID === invoiceID);
  };

  const onChangePartialInvoiceList = (invoiceItem) => {
    if (!invoiceUnpaidPartialSortedList.some((val) => val.invoiceID === invoiceItem.invoiceID)) {
      // Cannot find this invoice in the local list, add to the list
      // Fix the data
      invoiceItem.invoicePrice = invoiceItem.unPaidAmount;
      invoiceItem.amountToPay = invoiceItem.unPaidAmount;
      invoiceItem.amountToPayForMessage = invoiceItem.unPaidAmount;
      invoiceItem.unPaidAmountForMessage = invoiceItem.unPaidAmount;

      setInvoiceUnpaidPartialSortedList([...invoiceUnpaidPartialSortedList, invoiceItem]);
    } else {
      // Found this invoice, delete
      const newList = invoiceUnpaidPartialSortedList.filter((val) => val.invoiceID !== invoiceItem.invoiceID);
      setInvoiceUnpaidPartialSortedList(newList);
    }
    // setRerender(true);
    // setTimeout(() => {
    //   setRerender(false);
    // }, 10);
  };


  const countTotalInitialList = () => {
    try {
      return invoiceToPayInitialList.length;
    } catch (error) {
      return 0;
    }
  };

  const sumTotalInitialList = () => {

    let total = 0;
    for (let i = 0; i < invoiceToPayInitialList.length; i += 1) {
      total = total + invoiceToPayInitialList[i].invoicePrice;
    }
    return total;
  };

  const countTotalUnpaidPartialSortedList = () => {
    try {
      return invoiceUnpaidPartialSortedList.length;
    } catch (error) {
      return 0;
    }
  };

  const sumTotalUnpaidPartialSortedList = () => {

    let total = 0;
    for (let i = 0; i < invoiceUnpaidPartialSortedList.length; i += 1) {
      total = total + invoiceUnpaidPartialSortedList[i].invoicePrice;
    }
    return total;
  };

  const sumPay = () => {
    let amountToPay = 0;
    for (let i = 0; i < invoiceUnpaidPartialSortedList.length; i += 1) {
      amountToPay = Number(amountToPay) + Number(invoiceUnpaidPartialSortedList[i].amountToPay ? invoiceUnpaidPartialSortedList[i].amountToPay : 0);
    }
    return amountToPay;
  };

  const onChangePay = (e, invoiceID) => {
    let amountToPayToSet;

    // Check the e.target.value
    const textInput = e.target.value;
    if (isNaN(parseFloat(textInput))) {
      // That is not a number 
      amountToPayToSet = 0;
    } else {
      amountToPayToSet = Number(textInput);
    }

    let arrInvoiceList = invoiceUnpaidPartialSortedList;
    const objIndex = arrInvoiceList.findIndex((obj) => obj.invoiceID == invoiceID);
    arrInvoiceList[objIndex].amountToPay = amountToPayToSet;

    setInvoiceUnpaidPartialSortedList([...arrInvoiceList]);
    // setRerender(true);
    // setTimeout(() => {
    //   setRerender(false);
    // }, 10);
  };

  const getPayValue = (invoiceID) => {
    let amountToPay = 0;
    const data = invoiceUnpaidPartialSortedList.filter((val) => val.invoiceID === invoiceID);
    amountToPay = data[0]?.amountToPay;

    return amountToPay;
  };

  const uploadImg = async (paymentID) => {
    setIsLoading(true);
    await addPaymentPhoto(paymentID, paymentImageBase64Content);
    setIsLoading(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // check the data before upload

    // didn't select a customer
    if (selectedCustomerID === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please choose a customer")
      });
      return false;
    }
    // At least has one invoice
    if (!invoiceUnpaidPartialSortedList || invoiceUnpaidPartialSortedList.length <= 0) {
      Notifications({
        type: "dataCheck",
        message: t('Please choose some invoice')
      });
      return false;
    }

    // confirm
    Notifications({
      type: "generalConfirmation",
      title: t("Are you sure?"),
      message: t('Add new payment'),
      callback: submitData
    })

    return true;
  };

  const submitData = async () => {

    let paymentSend = [];
    for (let i = 0; i < invoiceUnpaidPartialSortedList.length; i += 1) {
      const obj = {
        invoiceID: invoiceUnpaidPartialSortedList[i].invoiceID,
        paidAmount: invoiceUnpaidPartialSortedList[i].amountToPay,
      };
      paymentSend.push(obj);
    }
    const submittedData = {
      customerID: selectedCustomerID,
      paymentPrice: sumPay(),
      paymentStatus: "Paid",
      paymentMethod: paymentMethod,
      paymentRemark: "-",
      paymentRefNumber: paymentRefNumber,
      invoiceIDs: paymentSend,
    };

    try {
      setIsLoading(true);
      const res = await storePayment(submittedData);
      const res2 = await uploadImg(res[0]?.paymentID);

      setIsLoading(false);

      clearForm();
      props.refresh();
      props.onHide();
    } catch (err) {
      Notifications();
    }
  }

  const sortPrice = () => {
    setSortDateSmall(true);
    let res = [];
    if (!sortPriceSmall) {
      res = invoiceUnpaidPartialSortedList.sort((a, b) => a.invoiceNetPrice - b.invoiceNetPrice);
    } else {
      res = invoiceUnpaidPartialSortedList.sort((a, b) => b.invoiceNetPrice - a.invoiceNetPrice);
    }
    setInvoiceUnpaidPartialSortedList(res);
    setSortPriceSmall(!sortPriceSmall);
  };

  const sortDate = () => {
    setSortPriceSmall(true);
    let res = [];
    if (!sortDateSmall) {
      res = invoiceUnpaidPartialSortedList.sort(
        (a, b) =>
          new Date(a.invoiceRecordDateTime) - new Date(b.invoiceRecordDateTime)
      );
    } else {
      res = invoiceUnpaidPartialSortedList.sort(
        (a, b) =>
          new Date(b.invoiceRecordDateTime) - new Date(a.invoiceRecordDateTime)
      );
    }

    setInvoiceUnpaidPartialSortedList(res);
    setSortDateSmall(!sortDateSmall);
  };



  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >

        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader
          toggle={props.onHide}>{props.title}</ModalHeader>
        <ModalBody className="mt--4">
          <Row md={12}>
            <Col md={2}>
              <Label className="pr-1 mt-2 h4">{t("Customer")}</Label>
            </Col>
            <Col md={10}>

              <CustomerSelectInput
                preSelectCustomerID={selectedCustomerID}
                selectCustomerID={(customerID) => {
                  searchCustomer(customerID);
                }}
                disabled={isInvoiceCustomerDisabled}
                showCreateButton={false}
                onCreateNewCustomer={() => {

                }} />


            </Col>
          </Row>
          <Row md={12} className="mb-4">
            <Col md={2}>
              <Label className="pr-1 mt-2 h4">{t("Payment Ref No")}</Label>
            </Col>
            <Col className="d-flex justify-content-start" md={10}>
              <Input
                type="text"
                onChange={(e) => setPaymentRefNumber(e.target.value)}
                value={paymentRefNumber}
              />
            </Col>
          </Row>
          <Row md={12} className="mb-4">
            <Col md={2}>
              <Label className="pr-1 mt-2 h4">{t("Payment Remark")}</Label>
            </Col>
            <Col className="d-flex justify-content-start" md={10}>
              <Input
                type="textarea"
                onChange={(e) => setPaymentRemark(e.target.value)}
                value={paymentRemark}
              />
            </Col>
          </Row>


          <Row className="mt-2">
            <Col>
              <DropdownControl
                disabled={false}
                defaultColor="primary"
                dropdownName={t("Payment method")}
                useTranslate={true}
                // arrayIDTextFormat={[{id: "xxxx", text: "yyy"}]}
                arrayIDTextFormat={paymentMethodArray}
                valueID={paymentMethod}
                onSelectValueID={(valueID) => {
                  setPaymentMethod(valueID);
                }}
              />
            </Col>
          </Row>


          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <PhotoUploadArea
                title={t("Photo Upload")}
                isShow={true}
                photoID={paymentImageID}
                photoBase64Data={paymentImageBase64Content}
                onUploadPhoto={async (photoDataToUpload) => {
                  onChangePaymentPhoto(
                    "",
                    await getBase64PhotoHeadRemoved(photoDataToUpload)
                  )
                }}
                onDownloadPhoto={(paymentImageID, paymentImageBase64Content) => {

                }}
                onDeletePhoto={(paymentImageID) => {
                  onChangePaymentPhoto("", "")
                }}
              />
            </Col>
          </Row>

          {/* filter Card */}
          <Row>
            <Col>
              <Card className="card-stats mb-3">
                <CardHeader
                  role="tab">
                  <Row>
                    <Col className="col-auto pr-0">
                      <Button onClick={sortPrice} className="mt-1" color="primary">
                        {t("Price")}{" "}
                        <i
                          className={`fas fa-sort-numeric-${sortPriceSmall ? "up" : "down"
                            }`}
                        />
                      </Button>

                      <Button onClick={sortDate} className="mt-1" color="primary">
                        {t("Date")}{" "}
                        <i
                          className={`fas fa-sort-numeric-${sortDateSmall ? "up" : "down"
                            }`}
                        />
                      </Button>
                    </Col>

                  </Row>
                </CardHeader>

              </Card>
            </Col>
          </Row>


          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>

              <InvoiceListToPayMessageArea
                isSameCustomer={true}
                customerDetail={customerData.find(element => element.customerID === selectedCustomerID)}
                showReceiptURL={false}
                receiptURL={""}
                invoiceList={invoiceUnpaidPartialSortedList}
                showAddAllToInvoiceBasket={true}
              >
              </InvoiceListToPayMessageArea>
            </Col>
          </Row>

          {/* Invoice List Below */}
          <Row>
            <Col md={12} className="pr-0">
              <div className="mt-4">
                {selectedCustomerID !== "" && invoiceToPayInitialList && Object.values(invoiceToPayInitialList).length >= 1 ? (
                  <>
                    {invoiceToPayInitialList.map((invoiceItem, i) => (
                      <div key={i} className={checkIsSelectedList(invoiceItem.invoiceID) ? "bg-gradient-success" : ""}>
                        <Row className="d-flex px-3 justify-content-between">
                          <Col xs={8}
                            onClick={() => {
                              // check/uncheck will trigger
                              //      if (calculatePriceToPay(invoiceItem) > 0) {
                              onChangePartialInvoiceList(
                                invoiceItem
                              );
                              //    }
                            }}>
                            {/* <Input
                              style={{
                                cursor:
                                  calculatePriceToPay(invoiceItem) > 0
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              className="mt-2"
                              type="checkbox"
                              onChange={() => { }}
                              checked={checkIsSelectedList(invoiceItem.invoiceID)}
                            /> */}

                            {/* 1st row */}
                            <div className="d-flex flex-row">
                              {/* This is the big tick */}
                              <div className="tableRowTitleDouble">
                                {checkIsSelectedList(invoiceItem.invoiceID) &&
                                  (<Badge color="primary"><i className="fas fa-check" /></Badge>)
                                }
                              </div>
                              <div>&nbsp;&nbsp;</div>
                              <div className="mt-1">
                                <div className="tableRowTitle">
                                  {invoiceItem.invoiceID}
                                </div>

                                <div>
                                  <InvoiceBadge
                                    invoiceData={invoiceItem}
                                    badgeType="paymentCount"
                                    badgeClassName="statusBadgeSmall"
                                  />
                                  <InvoiceBadge
                                    invoiceData={invoiceItem}
                                    badgeType="invoiceStatus"
                                    badgeClassName="statusBadgeSmall"
                                  />
                                  <InvoiceBadge
                                    invoiceData={invoiceItem}
                                    badgeType="deliveryCount"
                                    badgeClassName="statusBadgeSmall"
                                  />
                                </div>
                              </div>

                            </div>
                            {/* 2nd row */}
                            <div className="h4 mt-2">
                              {invoiceItem.InvoiceSubitems.map((val_, i) => (
                                <span key={i}>
                                  {val_.Good?.goodDescription} (
                                  {val_.quantity}
                                  {val_.Good?.goodUnit})
                                  {i + 1 < invoiceItem.InvoiceSubitems.length
                                    ? ", "
                                    : ""}
                                </span>
                              ))}
                            </div>
                            {/* 3rd row */}
                            <div className="h4 mt-2">
                              {invoiceItem.invoiceDeliveryAddressText}
                            </div>
                          </Col>
                          <Col className="d-flex justify-content-end" xs={4}>
                            <div>
                              <div
                                style={{ fontSize: "12px" }}
                                className="text-muted mt-2"
                              >
                                {t("Unpaid amount")}$:
                              </div>
                              <div className="h2">
                                {calculatePriceToPay(invoiceItem) < 0
                                  ? 0
                                  : calculatePriceToPay(invoiceItem)}
                              </div>
                              <div
                                style={{ fontSize: "12px" }}
                                className="text-muted mt-2"
                              >
                                {t("Pay")}$:
                              </div>

                              <div className="tableRowTitle">
                                <Input
                                  // type="number"
                                  style={{ width: "100px" }}
                                  className="form-control-lg"
                                  placeholder=""
                                  onChange={(e) => onChangePay(e, invoiceItem.invoiceID)}
                                  name="amount"
                                  value={
                                    checkIsSelectedList(invoiceItem.invoiceID)
                                      ? getPayValue(invoiceItem.invoiceID)
                                      : 0
                                  }
                                  disabled={!checkIsSelectedList(invoiceItem.invoiceID)}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <hr className="m-2 p-0" />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-center my-5">
                    {t("No invoice selected")}
                  </div>
                )}
                <Row className="mt-4 d-flex px-3 justify-content-between">
                  {/* <Col /> */}
                  <Col className="d-flex justify-content-end">
                    <div className="text-right">
                      <div className="h4">
                        {'('}{countTotalInitialList()}{') '}{t("Total invoice amount")} $
                      </div>
                      <div className="h4 text-red">
                        {t("Total unpaid amount")} $
                      </div>
                      <div className="h4">
                      {'('}{countTotalUnpaidPartialSortedList()}{') '}{t("Selected invoice amount")} $
                      </div>
                      <div className="h4 text-red">
                      {t("Selected unpaid amount")} $
                      </div>
                      <div className="h1 text-green">
                        {t("Ready to Pay")} $
                      </div>
                    </div>
                    <div>
                      <div className="h4">{sumTotalInitialList()}</div>
                      <div className="h4 text-red">{sumTotalInitialList() - sumPay()}</div>
                      <div className="h4">{sumTotalUnpaidPartialSortedList()}</div>
                      <div className="h4 text-red">{sumTotalUnpaidPartialSortedList() - sumPay()}</div>
                      <div className="h1  text-green">{sumPay()}</div>
                    </div>
                  </Col>
                </Row>


              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Row className="w-100 d-flex justify-content-between">
            <div>
              <Button
                color="danger"
                type="button" // type="button" means the form will not submit
                onClick={(e) => {
                  e.preventDefault();
                  clearForm();
                }}
              >
                {t("Reset")}
              </Button>
            </div>
            <div>


              <SubmitButton
                handleSubmit={handleSubmit}
                buttonNormalText={"Create"}
              />



              <Button color="default" type="button" onClick={props.onHide}>
                {t("Cancel")}
              </Button>
            </div>
          </Row>



        </ModalFooter>
      </Modal>
    </>
  );
}

export default PaymentCreateModal;
