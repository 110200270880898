import { wrapText } from "functions/Print80mm";
import { textLengthInmm } from "functions/Print80mm";
import { textLength } from "functions/Print80mm";
import React from "react";
import { Button } from "reactstrap";

// Note
// For 80mm paper
// margin is 5mm left 5mm right
// net space is 70mm

// Already tested
// for 1rem(fontSzie in HTML), 52 char length
// for 2rem(fontSize in HTML). 26 char length
// etc. 
// The ratio is 1 rem : 1/52 1/char
// Start change to 50


// FYI,
// english char and numbers char is 1 char long
// Chinese char is 2 char long
function PrintBasicText(props) {
    const { textToShow, fontSizeInRem = 1, paperWidthInChar = 50 } = props;

    const [textToShowLines, setTextToShowLines] = React.useState([]);


    React.useEffect(() => {

        setTextToShowLines(wrapText(textToShow, { size: 1, width: (paperWidthInChar / fontSizeInRem) }));
    }, [textToShow]);

    return (
        <>  
            {textToShowLines && Array.isArray(textToShowLines) && textToShowLines.length >= 1 &&
                textToShowLines.map((textLine, index) => {
                    // style={{whiteSpace: 'pre-line'}}
                    return <div style={{whiteSpace: 'pre-line', fontWeight: 'bold', fontSize: `${fontSizeInRem}rem`}}> 
                        <span >{textLine}</span>
                    </div>
                })
            }
        </>
    );


}

export default PrintBasicText;
