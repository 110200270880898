
import { getSingleInvoice } from "functions/Invoice";
import { moveUpSingleItemInArray } from "functions/Utility";
import { deleteSingleItemInArray } from "functions/Utility";
import { addArrayToArray } from "functions/Utility";
import { refreshAllItemInArray } from "functions/Utility";
import { returnCheckExistArray } from "functions/Utility";
import { refreshSingleItemInArray } from "functions/Utility";
import { addSingleItemToArray } from "functions/Utility";
import { checkItemExistInArray } from "functions/Utility";
import { moveDownSingleItemInArray } from "functions/Utility";
import { createContext, useContext, useState, useEffect } from "react";

const InvoiceBasketContext = createContext();

export default function InvoiceBasketContextProvider({ children }) {


    const [invoiceGlobalList, setInvoiceGlobalList] = useState([]);
    const [invoiceGlobalListCount, setInvoiceGlobalListCount] = useState(invoiceGlobalList.length);

    useEffect(() => {
        setInvoiceGlobalListCount(invoiceGlobalList.length);
    }, [invoiceGlobalList]);

    const isThisInvoiceExist = (invoiceToCheck) => {
        // go through many times 
        // handle later
        return checkItemExistInArray(invoiceGlobalList, invoiceToCheck, 'invoiceID');
    }

    // return the item with a key isExistInGlobalList
    const returnCheckInvoiceExistItem = (invoiceToCheck) => {
        if (checkItemExistInArray(invoiceGlobalList, invoiceToCheck, 'invoiceID')) {
            invoiceToCheck.isExistInGlobalList = true;
        } else {
            invoiceToCheck.isExistInGlobalList = false;
        };
        return invoiceToCheck;
    }

    // return the list with a key isExistInGlobalList
    const returnCheckInvoiceExistList = (invoiceListToCheck) => {
        // return an list with 
        // [{other data: XXX, isExistInGlobalList: true/false}, {DR2}, .... , {DRn}]
        let newList = returnCheckExistArray(invoiceGlobalList, invoiceListToCheck, 'invoiceID');
        return newList;
    }

    // refresh single record drom db
    const refreshSingleInvoiceFromDBInList = async (invoiceIDToRefresh) => {
        let refreshedArray = await refreshSingleItemInArray(invoiceGlobalList, 'invoiceID', invoiceIDToRefresh, getSingleInvoice(invoiceIDToRefresh))
        setInvoiceGlobalList([...refreshedArray]);
    }

    // refresh all record drom db
    const refreshAllInvoiceFromDBInList = async () => {
        let newList = await refreshAllItemInArray(invoiceGlobalList, 'invoiceID', getSingleInvoice);
        setInvoiceGlobalList([...newList]);
    }

    // Append single record into the list
    const addSingleInvoiceToGlobalList = (invoiceToAdd, allowRepeatInvoiceID = false) => {
        let addedResult = addSingleItemToArray(invoiceGlobalList, invoiceToAdd, 'invoiceID', allowRepeatInvoiceID)
        setInvoiceGlobalList(addedResult);
    }

    // Append records into the list
    const addInvoiceListToGlobalList = (invoiceListToAdd, allowRepeatInvoiceID = false) => {
        let addedResult = addArrayToArray(invoiceGlobalList, invoiceListToAdd, 'invoiceID', allowRepeatInvoiceID);
        setInvoiceGlobalList(addedResult);
    }

    // delete single record into the list
    const deleteSingleInvoiceToGlobalList = (invoiceToDelete) => {
        let filteredResult = deleteSingleItemInArray(invoiceGlobalList, invoiceToDelete, 'invoiceID');
        setInvoiceGlobalList([...filteredResult]);
    }

    // delete all records into the list
    const deleteAllInvoiceToGlobalList = () => {
        setInvoiceGlobalList([]);
    }
    
    // delete all paid invoice in the list
    const deleteAllPaidInvoiceToGlobalList = () => {
        let arrayToWork = [...invoiceGlobalList];
        arrayToWork =  arrayToWork.filter((item) => item.invoiceStatus.toLowerCase() !== "paid");
        setInvoiceGlobalList(arrayToWork);
    }

    // move up the item
    const moveUpSingleInvoiceInGlobalList = (invoiceToMove, toTheFront = false) => {
        let movedArray = moveUpSingleItemInArray(invoiceGlobalList, invoiceToMove, 'invoiceID', toTheFront);
        if (movedArray && Array.isArray(movedArray)) {
            setInvoiceGlobalList([...movedArray]);
        }
    }

    // move down the item
    const moveDownSingleInvoiceInGlobalList = (invoiceToMove, toTheLast = false) => {
        let movedArray = moveDownSingleItemInArray(invoiceGlobalList, invoiceToMove, 'invoiceID', toTheLast);
        if (movedArray && Array.isArray(movedArray)) {
            setInvoiceGlobalList([...movedArray]);
        }
    }


    return (
        <InvoiceBasketContext.Provider
            value={{
                invoiceGlobalList,
                setInvoiceGlobalList,
                invoiceGlobalListCount,
                addSingleInvoiceToGlobalList,
                deleteSingleInvoiceToGlobalList,
                isThisInvoiceExist,
                returnCheckInvoiceExistItem,
                returnCheckInvoiceExistList,
                refreshSingleInvoiceFromDBInList,
                refreshAllInvoiceFromDBInList,
                moveUpSingleInvoiceInGlobalList,
                moveDownSingleInvoiceInGlobalList,
                deleteAllInvoiceToGlobalList,
                addInvoiceListToGlobalList,
                deleteAllPaidInvoiceToGlobalList
            }}
        >
            {children}
        </InvoiceBasketContext.Provider>
    )

}

export const InvoiceBasketListGlobal = () => {
    return useContext(InvoiceBasketContext);
}
