import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge
} from "reactstrap";
import SpinnerOverlay from "../components/SpinnerOverlay";
import CardMoveButtonGroup from "../components/cardRelated/CardMoveButtonGroup";

import { Trans, useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm";
// core components
import Dropzone from "dropzone";
import InvoiceEditModal from "../invoice/InvoiceEditModal";
import InvoiceCard from "../invoice/InvoiceCard";
import { getInvoiceByKeyword } from "functions/Invoice";

Dropzone.autoDiscover = false;


function SearchModal(props) {
    const { show, onHide } = props;
    const { t, i18n } = useTranslation();

    // GUI
    const [showFullCard, setShowFullCard] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [enableCardBgColor, setEnableCardBgColor] = React.useState(false);

    // Data
    const [searchString, setSearchString] = React.useState('');
    const [invoiceSelectedDataForInvoiceEdit, setInvoiceSelectedDataForInvoiceEdit] = React.useState({});

    // Search data
    const [searchInvoiceList, setSearchInvoiceList] = React.useState([]);

    // Modal 
    const [modalInvoiceEditIsOpen, setModalInvoiceEditIsOpen] = React.useState(false);

    React.useEffect(() => {

    }, []);
    const getSearchData = async () => {


        if (!searchString || searchString == '' || searchString.trim() == '' || searchString.replace(' ', '') == '') {
            clearAllSearchResult();
            return;
        }

        let searchStringLocal = searchString;
        searchStringLocal = searchStringLocal.replace(/\s/g, '');

        setIsLoading(true);
        await getSearchInvoiceData(searchStringLocal);
        setIsLoading(false);
    };


    const getSearchInvoiceDataLength = () => {
        return searchInvoiceList.length;
    };

    const getSearchInvoiceData = async (searchStringLocal) => {

        let resultArray = await getInvoiceByKeyword(searchStringLocal);
        if (resultArray && Array.isArray(resultArray) && resultArray.length >= 0) {
            setSearchInvoiceList(resultArray);
        } else {
            setSearchInvoiceList([]);
        }
    };

    return (
        <Modal
            isOpen={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={props.onHide}
            autoFocus={true}
            backdrop={'static'}
            trapFocus={true}
            keyboard={false}
        >
            {isLoading ? <SpinnerOverlay /> : ""}
            <ModalHeader className="" toggle={onHide} >
                <Row className="mt-1">
                    <Col>
                        <Trans>
                            Search
                        </Trans>
                    </Col>
                </Row>



            </ModalHeader>
            <ModalBody>
                <Row className="mt-0">
                    <Col className="mt-1"       >

                        <InputTypeTextForm
                            label={''}
                            labelFor="searchText"
                            inputName="searchText"
                            inputId="searchText"
                            inputPlaceholder={t("Search")}
                            value={searchString}
                            handleChange={(e) => {
                                e.preventDefault();
                                setSearchString(e?.target?.value);
                            }}
                            readOnly={false}
                        />

                    </Col>
                </Row>
                <Row color="red"
                    className="mt-1">
                    <Col className="">
                        <div className="text-danger d-flex justify-content-start">
                            <span>#<Trans>Support search invoice only</Trans></span>
                            &nbsp;&nbsp;
                            <span>#<Trans>Max. 20 records</Trans></span>
                        </div>

                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col >
                        {/* align things in center */}
                        <div className="d-flex justify-content-center">

                            <Button color="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    getSearchData();
                                }}>
                                <Trans>Search</Trans>
                            </Button>
                            {false && <Button color="primary">
                                <Trans>Setting</Trans>
                            </Button>}

                        </div>
                        <div className="d-flex justify-content-start mt-2">
                            <Badge color='primary' className="statusBadge">{t('Search result')}{':'}</Badge>
                            <Badge color='primary' className="statusBadge">{getSearchInvoiceDataLength()}{" "}{t('invoice')}</Badge>

                        </div>

                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col className="mt-1">
                        {searchInvoiceList && Array.isArray(searchInvoiceList) && searchInvoiceList.length >= 0 &&
                            searchInvoiceList.map((invoiceItem, index) => {
                                return <>
                                    <CardMoveButtonGroup
                                        showMoveButton={false}
                                        cardIndex={index + 1}
                                        itemToMove={invoiceItem}
                                        moveCardUp={(itemToMove, toTheFront) => {
                                            // do nothing
                                        }}
                                        moveCardDown={(itemToMove, toTheLast) => {
                                            // do nothing
                                        }}
                                        showDeleteButton={false}
                                        deleteCard={(itemToDelete) => {
                                            // not suppose to delete card here
                                        }}
                                    />
                                    <InvoiceCard
                                        showFullCard={showFullCard}
                                        enableCardBgColor={enableCardBgColor}
                                        cardBgColor={""}
                                        invoiceData={invoiceItem}
                                        setClickViewInvoiceButton={(invoice) => {
                                            // setInvoiceSelectedDataForInvoiceMessage(invoice);
                                            // setModalInvoiceMessageIsOpen(true);
                                        }}
                                        onClickCard={(invoice) => {
                                            setInvoiceSelectedDataForInvoiceEdit(invoice);
                                            setModalInvoiceEditIsOpen(true);
                                        }}
                                        isSelectedCard={false}
                                        cloneInvoice={(invoice) => {
                                            // setInvoiceSelectedDataForCloneInvoice(invoice);
                                            // setModalInvoiceCreateIsOpen(true);
                                        }}
                                        accessCustomerAction={false}
                                        customerAction={(customer) => {
                                            //  setSelectedCustomerData(customer);
                                            // setModalCustomerActionIsOpen(true);
                                        }}
                                        accessCloneInvoice={false}
                                    />
                                </>


                            })

                        }


                    </Col>
                </Row>
            </ModalBody>

            <ModalFooter>

            </ModalFooter>

            <InvoiceEditModal
                show={modalInvoiceEditIsOpen}
                onHide={() => {
                    setModalInvoiceEditIsOpen(false);
                    // refreshTableData();
                }}
                invoiceData={invoiceSelectedDataForInvoiceEdit}
            />

        </Modal>
    );
}

export default SearchModal;
