import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications";
import moment from "moment";
import { getMultipleInvoiceURL } from "./Invoice";
import TranslateItem from "views/pages/components/TranslateItem";
import { values } from "pdf-lib";
const md5 = require('md5');


export const setUpInvoiceListToPayMessage = (translateFCN, isSameCustomer, customerDetail, showReceiptURL, receiptURL, invoiceList, msgShowDeliveryAddressText, msgShowInvoiceStatus, msgShowInvoiceAmount, msgShowListPage, msgShowInvoicePage, msgShowCompanyChop) => {
  if (isSameCustomer) {
    if (!customerDetail) return "";
    if (Object.values(customerDetail).length <= 0) return "";
  }

  if (!invoiceList || !Array.isArray(invoiceList) || invoiceList.length < 0) {
    return "";
  }

  try {
    let messageToGet = "";
    if (invoiceList.length <= 0) {
      messageToGet = "No invoice selected";
    } else {
      let messageHead = "";
      let messageBody = "";
      let messageFoot = "";

      // Calculate the total unpaidAmount
      // Calculate the total AmountToPay
      let totalUnpaidAmount = invoiceList.reduce((total, currentItem) => { return total + currentItem.unPaidAmountForMessage; }, 0);
      let totalAmountToPay = invoiceList.reduce((total, currentItem) => { return total + currentItem.amountToPayForMessage; }, 0);
      let totalInvoicePrice = invoiceList.reduce((total, currentItem) => { return total + currentItem.invoicePrice; }, 0);

      if (isSameCustomer) {
        messageHead = `客戶：${customerDetail.customerName}` + '\n';
      }

      if (totalUnpaidAmount == totalAmountToPay) {
        messageHead = messageHead
          + `共有 ${invoiceList.length} 張單，總值 $${invoiceList.reduce((total, currentItem) => { return total + currentItem.amountToPayForMessage; }, 0)}`
      } else {
        messageHead = messageHead
          + `共有 ${invoiceList.length} 張單，已付/未付/總值：$${totalAmountToPay}/$${totalUnpaidAmount}/${totalInvoicePrice}`
      }

      if (showReceiptURL) {
        messageHead = messageHead + '\n\n'
          + '按此獲得正式收據：' + `${receiptURL}`
      }

      let invoiceAmountDetailArray = invoiceList.map(function (item, index) {
        let rowMessageToGet = '';
        // if (item.amountToPayForMessage == item.unPaidAmountForMessage) {
        //   rowMessageToGet = '第' + (index + 1).toString() + '張' + '：' + item.invoiceID;
        //   if (msgShowInvoiceAmount) {
        //     rowMessageToGet = rowMessageToGet + '（' + (msgShowInvoiceStatus ? translateFCN(item.invoiceStatus) + '，' : '') + '總數：' + '$' + item.unPaidAmountForMessage.toString() + '）';
        //   }
        // } else {
        rowMessageToGet = '第' + (index + 1).toString() + '張' + '：' + item.invoiceID;
        if (msgShowInvoiceAmount) {
          rowMessageToGet = rowMessageToGet + '（' + (msgShowInvoiceStatus ? translateFCN(item.invoiceStatus) + '，' : '') + '已付/未付/總值：' + '$' + item.amountToPayForMessage.toString() + '/' + '$' + item.unPaidAmountForMessage.toString() + '/' + '$' + item.invoicePrice.toString() + '）';
        }
        // }

        if (!isSameCustomer) {
          // consist of many customer
          // Need to show customer name under the invoice
          rowMessageToGet = rowMessageToGet + '\n' + '客戶：' + (item.Customer ? item.Customer?.customerName : '');
        }

        if (msgShowDeliveryAddressText) {
          rowMessageToGet = rowMessageToGet + '\n' + ' - ' + (item.invoiceDeliveryAddressText ? item.invoiceDeliveryAddressText : '') + '\n';
        }

        return rowMessageToGet;

      });

      messageBody = invoiceAmountDetailArray.join('\n');

      messageBody = messageBody + '\n\n' + `按此連結：` + '\n' + `${getMultipleInvoiceURL(invoiceList, msgShowListPage, msgShowInvoicePage, msgShowCompanyChop)}`

      messageFoot = '如單據有更改, 連結會自動更新' + '\n' + '有問題請 Whatsapp 查詢, 謝謝'

      messageToGet = messageHead + '\n\n' + messageBody + '\n\n' + messageFoot;
    }

    return messageToGet;
  } catch (error) {
    return ""
  }
};

export const getAllPayment = async () => {
  try {
    let response = await apiGet({
      url: "payment/getAllPayment",
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }

};

export const getPaymentByID = async (id) => {
  try {
    let response = await apiGet({ url: "payment/" + id, responseType: "" });
    return response.status == 200 ? response.data.data : [];
  } catch (error) {
    return [];
  }
};


export const getPaymentByCustomerID = async (customerID) => {
  try {
    let response = await apiGet({ url: "payment/byCustomerID/" + customerID, responseType: "" });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const getPaymentByInvoiceID = async (id) => {
  try {
    let response = await apiGet({
      url: "payment/byInvoiceID/" + id,
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (e) {
    return [];
  }
};


export const getPaymentByDateRangeAndPaymentMethod = async (startDate, endDate, paymentMethod = '') => {

  try {
    let paramClause = '';
    const startDateFormat = moment(startDate).format("yyyy-MM-DD");
    const endDateFormat = moment(endDate).format("yyyy-MM-DD");

    if (paymentMethod) {
      paramClause = paramClause + 'paymentMethod' + '=' + paymentMethod;
    }

    let response = await apiGet({
      url: `payment/searchByDateRange/${startDateFormat}/${endDateFormat}?${paramClause}`,
      responseType: "",
    });

    return response.status == 200 ? response.data.data.rows : [];
  } catch (e) {
    return [];
  }
};


export const storePayment = async (data) => {
  return api.post("payment/addPayment", data).then((response) => {
    try {
      if (response.data.code == 200) {
        Notifications({ type: "store", message: response.data.message });
        return response.data.data.result;
      } else {
        return Notifications({ type: "error", message: response.data.message });
      }
    } catch (error) {
      return Notifications({ type: "error" });
    }
  });
};

export const editPayment = async (data) => {
  return api.put("payment/updatePayment", data).then((response) => {
    try {
      if (response.data.code == 200) {
        return Notifications({ type: "store", message: response.data.message });
      } else {
        return Notifications({ type: "error", message: response.data.message });
      }
    } catch (error) {
      return Notifications({ type: "error" });
    }
  });
};

// This kind of update function should return true or false to indicate the success or fail 
export const editPaymentWithMatch = async (data) => {
  let response = await apiPut({
    url: "payment/updatePaymentWithMatch",
    data: data,
    responseType: "",
  });
  try {
    return response.status == 200
      ? true
      : false;

  } catch (error) {
    return false;
  }

};

export const deletePayment = async (id) => {
  let response = await apiDelete({
    url: "payment/deletePayment/" + id,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const addPaymentPhoto = async (paymentID, imageBase64) => {
  const response = await api.post(`/payment/uploadPaymentImage`, {
    paymentID: paymentID,
    imageBase64Content: imageBase64,
  });
  return response.data;
};

export const getPaymentPhoto = async (paymentID) => {
  try {
    const response = await api.get(`/payment/getPaymentImage/${paymentID}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const deletePaymentPhoto = async (imageID) => {
  try {
    const response = await api.delete(`/payment/deletePaymentImage/${imageID}`);
    return response.data;
  } catch (error) {
    return null;

  }
};


export const getPaymentStatusArray = () => {
  return [
    // { id: "Progress", text: "Progress", color: "danger" },
    { id: "Paid", text: "Paid", color: "success" },
    { id: "Cancel", text: "Cancel", color: "warning" },
  ];
};

export const getPaymentStatusColor = (valueIDToCheck) => {
  const defaultColor = "default"
  const arrayIDTextFormat = getPaymentStatusArray();
  try {
    if (!valueIDToCheck) return defaultColor;
    if (!arrayIDTextFormat) return defaultColor;
    if (Object.values(arrayIDTextFormat).length <= 0) return defaultColor;
    const colorToFind = arrayIDTextFormat.find(item => item.id === valueIDToCheck);
    return (colorToFind.color ? colorToFind.color : defaultColor);
  } catch {
    return defaultColor;
  }
};

export const getPaymentMethodArray = () => {
  return [
    { id: "Cash", text: "Cash", color: "danger" },
    { id: "Cheque", text: "Cheque", color: "success" },
    { id: "Transfer", text: "Transfer", color: "warning" },
  ];
};

export const getPaymentMethodColor = (valueIDToCheck) => {
  const defaultColor = "default"
  const arrayIDTextFormat = getPaymentMethodArray();
  try {
    if (!valueIDToCheck) return defaultColor;
    if (!arrayIDTextFormat) return defaultColor;
    if (Object.values(arrayIDTextFormat).length <= 0) return defaultColor;
    const colorToFind = arrayIDTextFormat.find(item => item.id.toLowerCase() === valueIDToCheck.toLowerCase());
    return (colorToFind.color ? colorToFind.color : defaultColor);
  } catch {
    return defaultColor;
  }
};

// return decimal
export const getCorrespondingPaidAmountForSingleInvoice = (invoiceIDToCheck, paymentInvoiceMatchesArray) => {
  if (invoiceIDToCheck === '') return 0;

  if (!paymentInvoiceMatchesArray || !Array.isArray(paymentInvoiceMatchesArray) || paymentInvoiceMatchesArray.length <= 0) return 0;

  let filteredArray = paymentInvoiceMatchesArray.filter((item) => {return item?.invoiceID === invoiceIDToCheck });

  if (!filteredArray || !Array.isArray(filteredArray) || filteredArray.length <= 0) return 0;

  let resultDecimal = filteredArray.reduce((total, currentItem) => { return total + currentItem.paidAmount; }, 0);

  return resultDecimal;

};