import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { paginationTable } from "functions/PaginationTable";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { getCarDatatable, deleteCarByID, getCarTypeByID } from "functions/Car";
import CarModalCreate from "./CarModalCreate";
import CarModalEdit from "./CarModalEdit";
import Notifications from "../components/Notifications";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { isAccessible } from "functions/SystemUserAccess";
import { lockScrolling } from "functions/Utility";




function CarScreen(props) {
  const { t } = useTranslation();
  const pagination = paginationTable();

  const { SearchBar } = Search;

  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);

  const [carData, setCarData] = React.useState([]);
  const [selectedCarData, setSelectedCarData] = React.useState([]);


  // GUI
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

  //  modal
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = React.useState(false);

  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    setPullToRefreshDisable(modalIsOpen || modalEditIsOpen);
    lockScrolling(modalIsOpen || modalEditIsOpen);
  }, [modalIsOpen, modalEditIsOpen]);

  // Call API Customer
  React.useEffect(() => {
    getCarTable();
  }, [refreshTable]);

  // Delete Confirmation
  async function deleteConfirmation(id) {
    let alert = {
      type: "deleteConfirmation",
      elementID: id,
      callback: deleteCar,
    };
    Notifications(alert);
  }

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  const getCarTable = async () => {
    setIsLoading(true);
    let carTable = await getCarDatatable();
    setCarData(carTable);
    setIsLoading(false);
  };

  // Delete API Call
  async function deleteCar(id) {
    let deleteResponse = await deleteCarByID(id);
    deleteResponse.code == 200 ? refreshTableData() : true;
  }

  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}

      <ScreenPullToRefresh
        onRefresh={async () => {
          await getCarTable();
        }}
        disabled={pullToRefreshDisable}>
        <SimpleHeader parentName={t("Car")} icon="fas fa-home" />
      </ScreenPullToRefresh>
      <Container style={{ marginTop: "-4rem" }} fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <ToolkitProvider
                  data={carData}
                  keyField="carID"
                  columns={
                    isMobile
                      ? [
                        { dataField: "carID", text: "carID", hidden: true },
                        { dataField: "carIDRemark", text: "carIDRemark", hidden: true },
                        { dataField: "carType", text: "carType", hidden: true },
                        {
                          dataField: "display",
                          text: t("Car"),
                          formatter: (cell, row, rowIndex, extraData) => {
                            return (
                              <div
                                className="ml--3"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedCarData(row);
                                  setModalEditIsOpen(true);
                                }}>
                                <div className="tableRowTitle">
                                  {row.carID} ({t(row.carType)})
                                </div>
                                <div className="tableRowSubTitle">
                                  {t("maxLoad")}: {row.maxLoad}kg / {t(row.status)}
                                </div>
                                <div>
                                  {t("Insurance Date")}: {row.insuranceDate}
                                </div>
                                <div>
                                  {t("License Date")}: {row.licenseDate}
                                </div>
                                <div className="tableRowRemark">
                                  #{row.carIDRemark}
                                </div>
                              </div>
                            );
                          },
                        },
                        {
                          isDummyField: true,
                          dataField: "Action",
                          text: "",
                          formatter: (cellContent, row) => {
                            return (
                              <>

                                <Badge color={"danger"} className="badge-lg text-lg badge-circle">
                                  <i
                                    className="fas fa-trash"
                                    onClick={() =>
                                      deleteConfirmation(row.carID)
                                    }
                                  />
                                </Badge>

                              </>
                            );
                          },
                        },
                      ]
                      : [
                        {
                          dataField: "carID",
                          text: t("Car ID"),
                          sort: true,
                        },
                        {
                          dataField: "status",
                          text: t("Car Status"),
                          sort: true,
                        },
                        {
                          dataField: "carType",
                          text: t("Car Type"),
                          formatter: (cell, row, rowIndex, extraData) => {
                            return getCarTypeByID(row.carType)?.text
                          },
                          sort: true,
                        },
                        {
                          dataField: "insuranceDate",
                          text: t("Insurance Date"),
                          sort: true,
                        },
                        {
                          dataField: "licenseDate",
                          text: t("License Date"),
                          sort: true,
                        },
                        {
                          dataField: "maxLoad",
                          text: t("Max Load"),
                          sort: true,
                          formatter: (cellContent, row) => {
                            return <>{cellContent} Kg</>;
                          },
                        },
                        // {
                        //   dataField: "status",
                        //   text: "Status",
                        //   // style: { whiteSpace: 'normal', wordWrap: 'break-word' },
                        //   sort: true
                        // },
                        {
                          dataField: "carIDRemark",
                          text: t("Car Remark"),
                          // sort: true
                        },
                        {
                          isDummyField: true,
                          dataField: "Action",
                          text: t("Action"),
                          formatter: (cellContent, row) => {
                            return (
                              <>
                                <a
                                  style={{ cursor: "pointer" }}
                                  className="table-action"
                                  id="tooltip209424781"
                                  onClick={() => {
                                    setSelectedCarData(row);
                                    setModalEditIsOpen(true);
                                  }}
                                >
                                  <i className="fas fa-edit" />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target="tooltip209424781"
                                >
                                  {t("Edit")}
                                </UncontrolledTooltip>
                                <a
                                  style={{ cursor: "pointer" }}
                                  className="table-action table-action-delete"
                                  id="tooltip12475020"
                                  onClick={() =>
                                    deleteConfirmation(row.carID)
                                  }
                                >
                                  <i
                                    className="fas fa-trash"
                                    data-id={cellContent}
                                  />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target="tooltip12475020"
                                >
                                  {t("Delete")}
                                </UncontrolledTooltip>
                              </>
                            );
                          },
                        },
                      ]
                  }
                  search={{
                    searchFormatted: false
                  }}
                >
                  {(props) => (
                    <>
                      <Row className="pb-2">
                        <Col xs={7} sm={7} md={7} lg={7}>
                          <SearchBar
                            className="form-control"
                            placeholder={t("Search")}
                            {...props.searchProps}
                          />
                        </Col>
                        <Col
                          className={"text-right"}
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                        >
                          <Button
                            color="primary"
                            id="tooltip443412080"
                            onClick={() => setModalIsOpen(!modalIsOpen)}
                            size="md"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">{t("Add")}</span>
                          </Button>
                          <UncontrolledTooltip
                            delay={1}
                            target="tooltip443412080"
                          >
                            {t("Create")}
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                      <div className="py-2 table-responsive table-sm">
                        <BootstrapTable
                          headerClasses={isMobile ? "tableHiddenHeader" : ""} // only hide column when mobile
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    </>
                  )}
                </ToolkitProvider>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>

      </Container>
      <CarModalCreate
        show={modalIsOpen}
        onHide={(e) => {
          setModalIsOpen(false);
        }}
        title={t("Add Car")}
        button={t("Save")}
        refreshTable={refreshTableData}
      />
      <CarModalEdit
        show={modalEditIsOpen}
        onHide={() => setModalEditIsOpen(false)}
        title={t("Edit Car")}
        button={t("Update")}
        carData={selectedCarData}
        refreshTable={refreshTableData}
      />
    </>
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default CarScreen;
