import React, { useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge
} from "reactstrap";
import { uuid4 } from "@sentry/utils";
import { useTranslation } from "react-i18next";
import Notifications from "./Notifications";
import { isConstructorDeclaration } from "typescript";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from "classnames";



//props
// [{localDeliverySubitemID: uuid(), good, ...}]

function DeliverySubitemTable(props) {

    const { deliverySubitemsTable, deleteSubitem, allowEditProduct, selectSubitem, allowDeleteProduct, moveItem } = props;

    const { t } = useTranslation();

    const [deliverySubitemList, setDeliverySubitemList] = React.useState([]);

    React.useEffect(() => {
        if (deliverySubitemsTable && Array.isArray(deliverySubitemsTable)) {
            let arrayToAdd = deliverySubitemsTable;
            let resultArray = arrayToAdd.map((element) => {
                return Object.assign({}, {
                    localDeliverySubitemID: element.localDeliverySubitemID,
                    good: element.good,
                    // goodID: element.goodID,
                    deliverySubitemRemark: element.deliverySubitemRemark,
                    deliverySubitemQuantity: element.deliverySubitemQuantity,
                    sortingNumber: element.sortingNumber
                });
            })
            resultArray.sort((a, b) => a.sortingNumber - b.sortingNumber);
            console.log('*************************');
            console.log(resultArray);
            console.log('###########################');
            setDeliverySubitemList(resultArray);
        }
    }, [deliverySubitemsTable]);

    async function deleteSubitemConfirmation(id) {
        let alert = {
            type: "deleteConfirmation",
            elementID: id,
            callback: confirmedDeleteSubitem,
        };
        Notifications(alert);
    }

    const confirmedDeleteSubitem = (localDeliverySubitemID) => {
        deleteSubitem(localDeliverySubitemID);
    }
    const [localSubitemIDJustMoved, setLocalSubitemIDJustMoved] = React.useState('');


    function isJustMoved(localSubitemIDToCheck) {
        if (localSubitemIDJustMoved === '') {
            return false;
        } else if (localSubitemIDJustMoved === localSubitemIDToCheck) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            {deliverySubitemList && Array.isArray(deliverySubitemList) &&
                deliverySubitemList.map((item, rowIndex) => {
                    return (
                        <Card className={classnames(`${isJustMoved(item.localDeliverySubitemID) ? "bg-gradient-success" : ""}`, 'w-100 mb-0')}>
                            <CardBody>
                                <div key={rowIndex} style={{ cursor: "pointer" }}>
                                    {/* First row */}
                                    <div className="d-flex">
                                        <div className="flex-grow-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                selectSubitem(item);
                                                setLocalSubitemIDJustMoved(item.localDeliverySubitemID);
                                            }}>
                                            <Badge color="primary" className="statusBadge">{(rowIndex + 1).toString()}{"."}</Badge>
                                            <Badge color="default" className="statusBadge">{item?.good?.goodDefaultWeight}{"kg/"}{item?.good?.goodUnit}</Badge>
                                        </div>

                                        <div style={{ cursor: "pointer" }}>

                                            <Badge color="info" className="statusBadge mr-1" onClick={() => {
                                                moveItem(item.localDeliverySubitemID, false);
                                                setLocalSubitemIDJustMoved(item.localDeliverySubitemID);
                                            }}>
                                                <i className="fa fa-arrow-up" />
                                            </Badge>
                                            <Badge color="info" className="statusBadge mr-1" onClick={() => {
                                                moveItem(item.localDeliverySubitemID, true);
                                                setLocalSubitemIDJustMoved(item.localDeliverySubitemID);
                                            }}>
                                                <i className="fa fa-arrow-down" />
                                            </Badge>

                                            {/* edit button */}
                                            {false && allowEditProduct &&
                                                <Badge color="danger" className="statusBadge" onClick={(e) => {
                                                    e.preventDefault();
                                                    selectSubitem(item);
                                                    setLocalSubitemIDJustMoved(item.localDeliverySubitemID);
                                                }}>
                                                    <i className="fas fa-edit" />
                                                    <span>{getAccessibleTagForSafeMode('deliveryEditEditProduct')}</span>

                                                </Badge>
                                            }

                                            {/* DELETE button */}
                                            {allowDeleteProduct &&
                                                <Badge color="danger" className="statusBadge" onClick={(e) => {
                                                    e.preventDefault();
                                                    deleteSubitemConfirmation(item.localDeliverySubitemID);
                                                }}>
                                                    <i className="fas fa-trash" />
                                                    <span>{getAccessibleTagForSafeMode('deliveryEditDeleteProduct')}</span>
                                                </Badge>
                                            }
                                        </div>
                                    </div>
                                    {/* Second row */}
                                    <div className="tableRowTitle mt-2"
                                        onClick={() => {
                                            selectSubitem(item);
                                            setLocalSubitemIDJustMoved(item.localDeliverySubitemID);
                                        }}  >
                                        {item.deliverySubitemQuantity}
                                        {item.good?.goodUnit}{""}
                                        {item?.good?.goodDescription}
                                        {"("}{item?.deliverySubitemQuantity * item?.good?.goodDefaultWeight}{"kg)"}
                                    </div>
                                    {/* Third row */}
                                    <div className="tableRowRemark"
                                        onClick={() => {
                                            selectSubitem(item);
                                            setLocalSubitemIDJustMoved(item.localDeliverySubitemID);
                                        }}>
                                        {(item.deliverySubitemRemark && item.deliverySubitemRemark !== '') ? `#${item.deliverySubitemRemark}` : ''}
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    )
                })
            }
        </>






    );
}

export default DeliverySubitemTable;
