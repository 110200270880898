//  Function
import { matchPath } from "react-router-dom";

// function getCurrentPage(history) {
//   for (const entries of Object.entries(AppPage)) {
//     const page = entries[1];
//     if (typeof page === "function") continue;
//     if (matchPath(history.location.pathname, page.route)) {
//       return page;
//     }
//   }
// }

// function getRouteObjByName(name) {
//   for (const AppPageEntry of Object.entries(AppPage)) {
//     const AppPageEntryObj = AppPageEntry[1];
//     if (AppPageEntryObj && AppPageEntryObj.views && AppPageEntryObj.views.length >= 1) {
//       if (typeof AppPageEntryObj === "function") continue; // Next

//       for (const AppPageEntryObjViewsEntry of Object.entries(AppPageEntryObj.views)) {
//         const AppPageEntryObjViewsEntryObj = AppPageEntryObjViewsEntry[1];
//         if (AppPageEntryObjViewsEntryObj.name === name) {
//           // Really found the obj
//           return AppPageEntryObjViewsEntryObj;
//         }
//       }
//     }
//   }
//   // At the end
//   // Error happened
//   // Cannot find the obj
//   // redirect to calendar page
//   return {
//     path: `${process.env.PUBLIC_URL}/work/calendar",
//     accessID: "accessCanlendarScreen"
//   };
// }

const RouteListObj = {
  invoice: {
    path: `${process.env.PUBLIC_URL}/work/invoice`,
    name: "Invoice",
    icon: "fas fa-file-invoice-dollar text-blue",
    layout: "/admin",
    accessID: "accessInvoiceScreen",
    sidebarGroup: "invoice"
  },
  localQuotation: {
    path: `${process.env.PUBLIC_URL}/work/localQuotation`,
    name: "Local Quotation",
    icon: "fas fa-file-invoice-dollar text-blue",
    layout: "/admin",
    accessID: "accessInvoiceScreen",
    sidebarGroup: "invoice"
  },
  payment: {
    path: `${process.env.PUBLIC_URL}/work/payment`,
    name: "Payment",
    icon: "fas fa-dollar-sign text-yellow",
    layout: "/admin",
    accessID: "accessPaymentScreen",
    sidebarGroup: "invoice"
  },
  salesAccounting: {
    path: `${process.env.PUBLIC_URL}/work/salesAccounting`,
    name: "SalesAccounting",
    icon: "fas fa-dollar-sign text-yellow",
    layout: "/admin",
    accessID: "accessAddressScreen"
  },
  calendar: {
    path: `${process.env.PUBLIC_URL}/work/calendar`,
    name: "Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    layout: "/admin",
    accessID: "accessCanlendarScreen"
  },
  deliveryRecord: {
    path: `${process.env.PUBLIC_URL}/work/deliveryRecord`,
    name: "Delivery Record",
    icon: "ni ni-delivery-fast lg text-green",
    layout: "/admin",
    accessID: "accessDeliveryScreen",
  },
  address: {
    path: `${process.env.PUBLIC_URL}/work/address`,
    name: "Address",
    icon: "fas fa-map text-maroon",
    layout: "/admin",
    accessID: "accessAddressScreen"
  },
  customer: {
    path: `${process.env.PUBLIC_URL}/work/customer`,
    name: "Customer",
    icon: "fas fa-users text-maroon",
    layout: "/admin",
    accessID: "accessCustomerScreen"
  },
  membershipType: {
    path: `${process.env.PUBLIC_URL}/work/membershipType`,
    name: "Membership Type",
    icon: "ni ni-badge text-orange", 
    layout: "/admin",
    accessID: "accessMembershipTypeScreen"
  },
  car: {
    path: `${process.env.PUBLIC_URL}/work/car`,
    name: "Car",
    icon: "fas fa-truck-moving text-orange",
    layout: "/admin",
    accessID: "accessCarScreen"
  },
  driver: {
    path: `${process.env.PUBLIC_URL}/work/driver`,
    name: "Driver",
    icon: "far fa-id-card text-teal",
    layout: "/admin",
    accessID: "accessDriverScreen"
  },
  deliveryTeam: {
    path: `${process.env.PUBLIC_URL}/work/deliveryTeam`,
    name: "Delivery Team",
    icon: "fas fa-users text-maroon",
    layout: "/admin",
    accessID: "accessDeliveryTeamScreen"
  },
  good: {
    path: `${process.env.PUBLIC_URL}/work/good`,
    name: "Good",
    icon: "fas fa-box-open text-indigo",
    layout: "/admin",
    accessID: "accessGoodScreen"
  },
  user: {
    path: `${process.env.PUBLIC_URL}/work/user`,
    name: "User",
    icon: "fas fa-user-circle text-black",
    layout: "/admin",
    accessID: "accessUserScreen"
  },
  store: {
    path: `${process.env.PUBLIC_URL}/work/store`,
    name: "Store",
    icon: "ni ni-map-big text-green",
    layout: "/admin",
    accessID: "accessStoreScreen"

  },
  systemAccess: {
    path: `${process.env.PUBLIC_URL}/work/systemAccess`,
    name: "System Access",
    icon: "ni ni-app text-red",
    layout: "/admin",
     accessID: "accessSystemAccessScreen"
  },
  userJob: {
    path: `${process.env.PUBLIC_URL}/work/userJob`,
    name: "User Job",
    icon: "ni ni-collection text-blue",
    layout: "/admin",
    accessID: "accessUserJobScreen"
  },
  reportResource: {
    path: `${process.env.PUBLIC_URL}/work/reportResource`,
    name: "Report Resource",
    icon: "fas fa-file-invoice-dollar text-blue",
    layout: "/admin",
    accessID: "accessReportResourceScreen"
  },
  signIn: {
    path: `${process.env.PUBLIC_URL}/work/singIn`,
    icon: "ni ni-calendar-grid-58 text-red",
    title: "Login",
  },
  root: {
    path: `${process.env.PUBLIC_URL}/`,
    name: "Home",
    icon: "ni ni-calendar-grid-58 text-red",
  },
  home: {
    path: `${process.env.PUBLIC_URL}/work`,
    name: "Home",
    icon: "ni ni-calendar-grid-58 text-red",
  }
}

const GroupList = [
  {
    collapse: true,
    icon: "far fa-id-card text-teal",
    name: "Invoice",
    state: "stringInvoice",
    views: [
      RouteListObj['invoice'],
      RouteListObj['localQuotation'],
      RouteListObj['payment'],
      RouteListObj['salesAccounting'],
    ]
  },
  {
    collapse: true,
    icon: "ni ni-delivery-fast lg text-green",
    name: "Delivery",
    state: "stringDelivery",
    views: [
      RouteListObj['calendar'],
      RouteListObj['deliveryRecord'],
      RouteListObj['address'],
      RouteListObj['deliveryTeam'],
    ]
  },
  {
    collapse: true,
    icon: "fas fa-users text-maroon",
    name: "Customer",
    state: "stringCustomer",
    views: [
      RouteListObj['customer'],
      RouteListObj['membershipType']
    ]
  },
  {
    collapse: true,
    icon: "fas fa-box-open text-indigo",
    name: "Info",
    state: "stringInfo",
    views: [
      RouteListObj['good'],
      RouteListObj['car'],
      RouteListObj['driver'],
      RouteListObj['user']
    ]
  },
  {
    collapse: true,
    icon: "fas fa-box-open text-red",
    name: "System Admin",
    state: "stringSystemAdmin",
    views: [
      RouteListObj['store'],
      RouteListObj['systemAccess'],
      RouteListObj['userJob'],
      RouteListObj['reportResource']
    ]
  }
]

//  Route
const AppPage = {
  GroupList: GroupList,
  RouteListObj: RouteListObj
};

export default AppPage;



