import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge
} from "reactstrap";
import { useTranslation, Trans } from "react-i18next";
import Notifications from "./Notifications";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from "classnames";



function InvoiceSubitemTable(props) {

    const { invoiceSubitemsTable, selectSubitem, isDriver, disableDeleteGood, deleteSubitem, moveItem } = props;

    const { t } = useTranslation();

    const [invoiceSubitemsArray, setInvoiceSubitemsArray] = React.useState([]);

    React.useEffect(() => {
        let invoiceSubitemsArraySorted = invoiceSubitemsTable.sort((a, b) => a.sortingNumber - b.sortingNumber);
        setInvoiceSubitemsArray([...invoiceSubitemsArraySorted]);
    }, [invoiceSubitemsTable]);

    const [localSubitemIDJustMoved, setLocalSubitemIDJustMoved] = React.useState('');


    function isJustMoved(localSubitemIDToCheck) {
        if (localSubitemIDJustMoved === '') {
            return false;
        } else if (localSubitemIDJustMoved === localSubitemIDToCheck) {
            return true;
        } else {
            return false;
        }
    }

    async function deleteSubitemConfirmation(id) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: id,
            callback: deleteSubitem,
        };
        Notifications(alert);
    }



    return (
        <>
            {invoiceSubitemsArray && Array.isArray(invoiceSubitemsArray) &&
                invoiceSubitemsArray.map((item, rowIndex) => {
                    return (
                        <Card className={classnames(`${isJustMoved(item.localSubitemID) ? "bg-gradient-success" : ""}`, 'w-100 mb-0')}>
                            <CardBody>
                                <div key={rowIndex} style={{ cursor: "pointer" }}>
                                    {/* First row */}
                                    <div className="d-flex">
                                        <div className="flex-grow-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                selectSubitem(item);
                                                setLocalSubitemIDJustMoved(item.localSubitemID);
                                            }}>
                                            <Badge color="primary" className="statusBadge">{(rowIndex + 1).toString()}{"."}</Badge>
                                            <Badge color="default" className="statusBadge">${item?.unitPrice}/{item?.good?.goodUnit}</Badge>

                                        </div>

                                        <div style={{ cursor: "pointer" }}>
                                            <Badge color="info" className="statusBadge mr-1" onClick={() => {
                                                moveItem(item.localSubitemID, false);
                                                setLocalSubitemIDJustMoved(item.localSubitemID);
                                            }}>
                                                <i className="fa fa-arrow-up" />
                                            </Badge>
                                            <Badge color="info" className="statusBadge mr-1" onClick={() => {
                                                moveItem(item.localSubitemID, true);
                                                setLocalSubitemIDJustMoved(item.localSubitemID);
                                            }}>
                                                <i className="fa fa-arrow-down" />
                                            </Badge>

                                            {!disableDeleteGood &&

                                                <Badge color="danger" className="statusBadge" onClick={(e) => {
                                                    e.preventDefault();
                                                    deleteSubitemConfirmation(item.localSubitemID);
                                                }}> <i className="fas fa-trash" /></Badge>

                                            }
                                        </div>
                                    </div>
                                    {/* Second row */}
                                    <div className="tableRowTitle"
                                        onClick={() => {
                                            selectSubitem(item);
                                            setLocalSubitemIDJustMoved(item.localSubitemID);
                                        }}  >
                                        {item?.quantity}{item?.good?.goodUnit}-{item?.good?.goodDescription}
                                    </div>
                                    {/* Third row */}
                                    <div className="tableRowTitleBigger"
                                        onClick={() => {
                                            selectSubitem(item);
                                            setLocalSubitemIDJustMoved(item.localSubitemID);
                                        }}  >
                                        ${item?.quantity * item?.unitPrice}
                                    </div>

                                    {/* Forth row */}
                                    {item?.invoiceSubitemRemark && item?.invoiceSubitemRemark != '' &&
                                        <div className="tableRowRemark"
                                            onClick={() => {
                                                selectSubitem(item);
                                                setLocalSubitemIDJustMoved(item.localSubitemID);
                                            }}   >
                                            #{item?.invoiceSubitemRemark}
                                        </div>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    )
                })
            }
        </>
    );
}

export default InvoiceSubitemTable;
