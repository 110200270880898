import React from "react";
import {
    Button,
    Card,
    CardBody,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import CustomerActionModal from "../customer/CustomerActionModal";

import { Trans, useTranslation } from "react-i18next";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal"
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";

import SpinnerOverlay from "../components/SpinnerOverlay";
import CardMoveButtonGroup from "../components/cardRelated/CardMoveButtonGroup";
import { InvoiceBasketListGlobal } from "context/InvoiceBasketContext";
import InvoiceCard from "../invoice/InvoiceCard";
import InvoiceEditModal from "../invoice/InvoiceEditModal";
import InvoiceListToPayMessageArea from "../components/InvoiceListToPayMessageArea";


function InvoiceBasketModal(props) {
    const { show, onHide } = props;
    const { t, i18n } = useTranslation();

    // Context
    const { invoiceGlobalList
        , isThisInvoiceExist
        , addSingleInvoiceToGlobalList
        , deleteSingleInvoiceToGlobalList
        , returnCheckInvoiceExistList
        , refreshSingleInvoiceFromDBInList
        , refreshAllInvoiceFromDBInList
        , moveUpSingleInvoiceInGlobalList
        , moveDownSingleInvoiceInGlobalList
        , deleteAllInvoiceToGlobalList
        , deleteAllPaidInvoiceToGlobalList } = InvoiceBasketListGlobal();


    // data
    const [displayInvoiceData, setDisplayInvoiceData] = React.useState([]);

    const [refreshTable, setRefreshTable] = React.useState(0);

    // For invoiceCard
    const [selectedInvoice, setSelectedInvoice] = React.useState(null);
    const [selectedCustomerData, setSelectedCustomerData] = React.useState({});

    // Data Transfer
    const [invoiceSelectedDataForInvoiceClone, setInvoiceSelectedDataForInvoiceClone] = React.useState({});

    // GUI
    const [isLoading, setIsLoading] = React.useState(false);
    const [showFullCard, setShowFullCard] = React.useState(false);
    const [invoiceIDJustMoved, setInvoiceIDJustMoved] = React.useState('');


    // modal
    const [modalCustomerActionIsOpen, setModalCustomerActionIsOpen] = React.useState(false);
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);
    const [modalInvoiceEditIsOpen, setModalInvoiceEditIsOpen] = React.useState(false);





    React.useEffect(() => {
        if (refreshTable > 0) {
            // After calling this 
            refreshSingleInvoiceFromDBInList(selectedInvoice.invoiceID);
        }
    }, [refreshTable]);



    React.useEffect(() => {
        // Bypass the initial record
        // Use display data to do the work
        handleDisplayInvoiceData(invoiceGlobalList);
    }, [invoiceGlobalList]);

    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const handleDisplayInvoiceData = (filteredSortedInvoiceData) => {
        let listWithExistGlobalList = returnCheckInvoiceExistList(filteredSortedInvoiceData);
        setDisplayInvoiceData(listWithExistGlobalList);
    }


    function isJustMoved(invoiceIDToCheck) {
        if (invoiceIDJustMoved === '') {
            return false;
        } else if (invoiceIDJustMoved === invoiceIDToCheck) {
            return true;
        } else {
            return false;
        }
    }



    return (
        <Modal
            isOpen={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            toggle={props.onHide}
            autoFocus={true}
            backdrop={'static'}
            trapFocus={false}
            keyboard={false}
        >
            {isLoading ? <SpinnerOverlay /> : ""}
            <ModalHeader className="" toggle={onHide} >
                <Row className="mt-1">
                    <Col>
                        <Trans>
                            Invoice basket
                        </Trans>
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Button className="mr-1" color="primary" size="sm" onClick={async () => {
                            setIsLoading(true);
                            await refreshAllInvoiceFromDBInList();
                            setIsLoading(false);
                        }}>
                            <Trans>
                                Refresh
                            </Trans>
                        </Button>
                        <Button className="mr-1" color="danger" size="sm" onClick={async () => {
                            setIsLoading(true);
                            deleteAllPaidInvoiceToGlobalList();
                            setIsLoading(false);

                        }}>
                            <Trans>
                                Delete paid
                            </Trans>
                        </Button>
                        <Button className="mr-1" color="danger" size="sm" onClick={async () => {
                            setIsLoading(true);
                            deleteAllInvoiceToGlobalList();
                            setIsLoading(false);

                        }}>
                            <Trans>
                                Delete all
                            </Trans>
                        </Button>
                    </Col>

                </Row>

            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <InvoiceListToPayMessageArea
                            isSameCustomer={false}
                            customerDetail={{}}
                            showReceiptURL={false}
                            receiptURL={""}
                            invoiceList={displayInvoiceData}
                            showAddAllToInvoiceBasket={false}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    {displayInvoiceData && Object.values(displayInvoiceData).length >= 1 && displayInvoiceData.map((invoiceItem, index) => (
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            data-id={invoiceItem.invoiceID}
                            key={invoiceItem.invoiceID}
                        >

                            <div className="ml-1">
                                <CardMoveButtonGroup
                                    cardIndex={index + 1}
                                    itemToMove={invoiceItem}
                                    moveCardUp={(itemToMove, toTheFront) => {
                                        moveUpSingleInvoiceInGlobalList(itemToMove, toTheFront);
                                        setInvoiceIDJustMoved(itemToMove.invoiceID);
                                    }}
                                    moveCardDown={(itemToMove, toTheLast) => {
                                        moveDownSingleInvoiceInGlobalList(itemToMove, toTheLast);
                                        setInvoiceIDJustMoved(itemToMove.invoiceID);
                                    }}
                                    showDeleteButton={true}
                                    deleteCard={(itemToDelete) => {
                                        deleteSingleInvoiceToGlobalList(itemToDelete);
                                    }}
                                />
                            </div>


                            <InvoiceCard
                                showFullCard={showFullCard}
                                enableCardBgColor={false}
                                cardBgColor={''}
                                invoiceData={invoiceItem}
                                setClickViewInvoiceButton={(invoice) => {
                                    setSelectedInvoice(invoice);
                                    setModalInvoiceMessageIsOpen(true);
                                }}
                                onClickCard={(invoice) => {
                                    setSelectedInvoice(invoice);
                                    setModalInvoiceEditIsOpen(true);
                                }}
                                isSelectedCard={isJustMoved(invoiceItem.invoiceID)}
                                cloneInvoice={(invoice) => {
                                    setInvoiceSelectedDataForInvoiceClone(invoice);
                                    setModalInvoiceCreateIsOpen(true);
                                }}
                                accessCustomerAction={true}
                                customerAction={(customer) => {
                                    setSelectedCustomerData(customer);
                                    setModalCustomerActionIsOpen(true);
                                }}
                                accessCloneInvoice={true}
                            />


                        </Col>
                    ))}
                    {displayInvoiceData.length === 0 && (
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Card>
                                <CardBody>
                                    <strong className="text-info h1">
                                        {t("No Invoice Found!")}
                                    </strong>
                                    <h3 className="text-info">{t("Please Check Your Filter")}</h3>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
            </ModalBody>

            <ModalFooter>

            </ModalFooter>

            <InvoiceMessageModal
                show={modalInvoiceMessageIsOpen}
                onHide={() => setModalInvoiceMessageIsOpen(false)}
                title={t("Invoice Message")}
                refreshTable={() => { }}
                invoiceData={selectedInvoice}
            />
            <InvoiceEditModal
                show={modalInvoiceEditIsOpen}
                onHide={() => {
                    setModalInvoiceEditIsOpen(false);
                    refreshTableData();
                }}
                invoiceData={selectedInvoice}
            />

            <InvoiceEditModal
                show={modalInvoiceEditIsOpen}
                onHide={() => {
                    setModalInvoiceEditIsOpen(false);
                }}
                invoiceData={selectedInvoice}
            />

            <InvoiceCreateModal
                invoiceToClone={invoiceSelectedDataForInvoiceClone}
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                }}
                allowEmptySubitemList={true}
            />
            <CustomerActionModal
                show={modalCustomerActionIsOpen}
                onHide={() => setModalCustomerActionIsOpen(false)}
                title={t("Choose Action")}
                button={t("Update")}
                customerData={selectedCustomerData}
                refreshTable={() => {
                    refreshTableData();
                }}
            />

        </Modal>
    );
}

export default InvoiceBasketModal;
