import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { getPaymentStatusColor, getPaymentMethodColor } from "functions/Payment"
//props
//paymentData
//badgeType :string
//badgeClassName : "statusBadge"/ "statusBadgeSmall"
function PaymentBadge(props) {

    const { t } = useTranslation();
    const payment = props.paymentData;
    const badgeType = props.badgeType;
    const badgeClassName = props.badgeClassName;
    const badgeClassNameDefault = "statusBadge";

    function textToShow() {
        switch (badgeType) {
            case 'paymentStatus':
                return `${t(payment.paymentStatus.toUpperCase())}`;
            case 'paymentMethod':
                return `${t(payment.paymentMethod.toUpperCase())}`;
            case 'paymentRefNumber':
                return `${payment.paymentRefNumber}`;
            default:
                return 'N/A';
        }
    };

    function badgeColor() {
        switch (badgeType) {
            case 'paymentStatus':
                return getPaymentStatusColor(payment.paymentStatus);
            case 'paymentMethod':
                return getPaymentMethodColor(payment.paymentMethod);
            default:
                return "primary";
        }
    };

    return (
        <Badge
            color={badgeColor()}
            className={badgeClassName ? badgeClassName : badgeClassNameDefault}
        >
            {textToShow()}
        </Badge>
    );
}

export default PaymentBadge;
